<template>
  <div>
    <div class="statusIcon">
      <i v-if="status === 'InProgress'" class="fa fa-spinner"></i>
      <i v-else-if="status === 'Failed'" class="fa fa-exclamation-circle"></i>
      <i v-else-if="status === 'Ready'" class="fa fa-check-circle-o"></i>
    </div>
  </div>
</template>
<script>
export default {
  name: "AppStatusIcon",
  props: {
    status: { type: String, default: String.default },
  },
};
</script>
<style scoped>
.statusIcon {
  margin-top: 14px;
}
</style>
