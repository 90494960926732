<template id="appModality">
  <div
    class="modality"
    :class="{
      MR: 'MR' === modality,
      CT: 'CT' === modality,
      PET: 'PET' === modality,
      Sound: 'Sound' === modality,
    }"
  >
    {{ modality }}
  </div>
</template>
<script>
export default {
  name: "AppModality",
  props: {
    modality: { type: String, default: String.default },
  },
};
</script>
<style scoped>
.modality {
  display: flex;
  border-radius: 10px;
  padding-left: 5px;
  padding-right: 5px;
}
.MR {
  background-color: rgb(57, 132, 108);
}
.CT {
  background-color: rgb(26, 24, 147);
}
.PET {
  background-color: rgb(15, 215, 248);
}
.Sound {
  background-color: rgb(245, 87, 10);
}
</style>
