<template id="appModalities">
  <div style="display: flex">
    <app-modality
      v-for="modality in modalities.split('/')"
      :key="modality"
      :modality="modality"
    ></app-modality>
  </div>
</template>
<script>
import appModality from "./appModality";

export default {
  name: "AppModalities",
  components: {
    "app-modality": appModality,
  },
  props: {
    modalities: {
      type: String,
      default: String.default,
    },
  },
  methods: {},
};
</script>
<style scoped></style>
