<template>
  <div class="filterButtonContainer">
    <div class="typeName">My Patients</div>
    <label class="filterButton" :class="{ pressedIcon: localSearchMyPatients }">
      <input
        v-model="localSearchMyPatients"
        type="checkbox"
        value="filterEnabled"
      />
      <div
        class="filterIcon"
        :style="{
          backgroundImage: 'url(/assets/icon/myassets.svg)',
        }"
      >
        &nbsp;
      </div></label
    >
  </div>
</template>
<script>
import { mapMutations, mapState } from "vuex";
export default {
  name: "AppMyAssetFilter",

  computed: {
    ...mapState(["searchMyPatients"]),
    localSearchMyPatients: {
      set(searchMyPatients) {
        this.setSearchMyPatients(searchMyPatients);
      },
      get() {
        return this.searchMyPatients;
      },
    },
  },
  methods: {
    ...mapMutations(["setSearchMyPatients"]),
  },
};
</script>
<style scoped>
.filterButtonContainer {
  width: 170px;
  height: 64px;
  margin: 2px;
  position: relative;
  text-align: center;
}
.filterButton {
  position: absolute;
  right: 0;
  top: 0;
  min-width: 1%;
  height: 64px;
  width: 64px;
  margin: 0px;
  background-blend-mode: difference;
  background-color: #5194a7;
  border-radius: 50% !important;
  color: white !important;
  transition: 0.2s;
  cursor: pointer;
}
.filterButton input {
  opacity: 0;
  width: 0;
  height: 0;
}

.filterIcon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: auto;
  width: 32px;
  height: 32px;
  background-size: 32px 32px;
  background-repeat: no-repeat;
  background-blend-mode: difference;
}

.pressedIcon {
  background-color: #487e92;
  box-shadow: inset 0px 0px 10px black;
}

.typeName {
  display: table-cell;
  min-width: 170px;
  height: 64px !important;
  vertical-align: middle;
  color: white;
  font-size: 18px;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  text-align: start;
}
.md-button.md-theme-default.md-raised:not([disabled]) {
  background: #5e9fb0;
}
.md-button {
  border-radius: 50% !important;
  color: white !important;
}
</style>
