<template id="appAdminFeatureToggles">
  <div id="editPage">
    <div id="featureTogglePage" class="column">
      <div>Feature Toggles</div>
      <div id="featureToggleList">
        <md-table>
          <md-table-row>
            <md-table-head>Id</md-table-head>
            <md-table-head>Name</md-table-head>
            <md-table-head>Type</md-table-head>
          </md-table-row>
          <md-table-row
            v-for="featureToggle in featureToggles"
            :key="featureToggle.id"
            :class="{
              'md-raised': selectedToggleId === featureToggle.id,
              isAllowedForSelectedAdUser:
                isToggleAllowedForSelectedAdUser(featureToggle),
            }"
            @click="
              selectedToggleId === featureToggle.id
                ? (selectedToggleId = null)
                : (selectedToggleId = featureToggle.id)
            "
          >
            <md-table-cell>{{ featureToggle.id }}</md-table-cell>
            <md-table-cell>{{
              featureToggle.metadata.displayName
            }}</md-table-cell>
            <md-table-cell>{{
              featureToggle.metadata.featureType
            }}</md-table-cell>
            <md-table-cell
              ><div
                class="fa fa-trash"
                @click="deleteFeatureToggle(featureToggle.id)"
              ></div
            ></md-table-cell>
          </md-table-row>
        </md-table>
      </div>
      <md-button
        class="md-primary md-raised"
        @click="showCreateFeatureToggle = true"
      >
        Add Feature Toggle
      </md-button>
    </div>
    <div class="column">
      <div v-if="selectedToggleId">
        <div>Drag Groups Below</div>
        <md-table class="dragArea">
          <draggable
            v-model="toggleGroups"
            draggable=".nothing"
            :group="{ name: 'groups', pull: false }"
            @add="linkToggleAndGroup"
          >
            <md-table-row v-for="group in toggleGroups" :key="group.id">
              <md-table-cell>{{ group.displayName }}</md-table-cell>
              <md-table-cell
                ><div
                  class="fa fa-trash"
                  @click="unlinkToggleAndGroup(group.id)"
                ></div
              ></md-table-cell>
            </md-table-row>
          </draggable>
        </md-table>
        <div>Drag Users Below</div>
        <md-table class="dragArea">
          <draggable
            v-model="toggleUsers"
            draggable=".nothing"
            :group="{ name: 'users', pull: false }"
            @add="linkToggleAndUser"
          >
            <md-table-row v-for="user in toggleUsers" :key="user.id">
              <md-table-cell>{{ user.displayName }}</md-table-cell>
              <md-table-cell
                ><div
                  class="fa fa-trash"
                  @click="unlinkToggleAndUser(user.id)"
                ></div
              ></md-table-cell>
            </md-table-row>
          </draggable>
        </md-table>
      </div>
      <div v-else>Select a feature toggle to Activate</div>
    </div>
    <div class="column">
      <div>Azure AD User Groups</div>
      <div class="adRow">
        <multiselect
          key="id"
          v-model="selectedAdGroup"
          :allow-empty="false"
          class="dropDown"
          label="displayName"
          :options="adGroups"
          placeholder="Type to search ..."
          :searchable="true"
        ></multiselect>
        <md-button
          class="md-primary md-raised"
          :disabled="!selectedAdGroup"
          @click="createGroup()"
        >
          Add to Toggle Groups
        </md-button>
      </div>
      <div id="groupList">
        <div>Toggle Groups</div>
        <md-table>
          <draggable
            v-model="groups"
            :group="{ name: 'groups', pull: 'clone' }"
            :move="checkMoveGroup"
          >
            <md-table-row
              v-for="group in groups"
              :key="group.id"
              :class="{
                'md-raised': selectedGroup === group,
                linkedToSelectedFeature: groupLinkedToSelectedFeature(group),
              }"
              @click="selectedGroup = group"
            >
              <md-table-cell
                >{{ group.displayName }}
                {{
                  group.featureTypes.length > 0 ? group.featureTypes : ""
                }}</md-table-cell
              >
              <md-table-cell
                ><div
                  class="fa fa-edit"
                  @click="editGroupFeatureTypes(group)"
                ></div>
                <div class="fa fa-trash" @click="deleteGroup(group.id)"></div
              ></md-table-cell>
            </md-table-row>
          </draggable>
        </md-table>
      </div>
    </div>
    <div class="column">
      <div>Azure AD Users</div>
      <div class="adRow">
        <multiselect
          key="id"
          v-model="selectedAdUser"
          :allow-empty="false"
          class="dropDown"
          label="displayName"
          :options="adUsers"
          placeholder="Type at least 2 characters ..."
          :searchable="true"
          @search-change="loadAdUsers"
        ></multiselect>
        <md-button
          class="md-primary md-raised"
          :disabled="!selectedAdUser"
          @click="createUser()"
        >
          Add to Toggle Users
        </md-button>
      </div>
      <div id="userList">
        <div>Toggle Users</div>
        <md-table>
          <draggable
            v-model="users"
            :group="{ name: 'users', pull: 'clone' }"
            :move="checkMoveUser"
          >
            <md-table-row
              v-for="user in users"
              :key="user.id"
              :class="{
                'md-raised': selectedUser === user,
                linkedToSelectedFeature: userLinkedToSelectedFeature(user),
              }"
              @click="selectedUser = user"
            >
              <md-table-cell
                >{{ user.displayName }}
                {{
                  user.featureTypes.length > 0 ? user.featureTypes : ""
                }}</md-table-cell
              >

              <md-table-cell
                ><div
                  class="fa fa-edit"
                  @click="editUserFeatureTypes(user)"
                ></div>
                <div class="fa fa-trash" @click="deleteUser(user.id)"></div
              ></md-table-cell>
            </md-table-row>
          </draggable>
        </md-table>
      </div>
    </div>
    <md-dialog :md-active.sync="showCreateFeatureToggle">
      <md-dialog-title>Create Feature Toggle</md-dialog-title>
      <md-dialog-content id="createFeatureToggle">
        <div class="inputRow">
          <div>Feature Type</div>
          <multiselect
            v-model="featureToggleType"
            :allow-empty="false"
            class="dropDown"
            deselect-label=""
            label="label"
            :options="featureTypes"
            :preselect-first="true"
            :searchable="false"
            select-label=""
            selected-label=""
          ></multiselect>
        </div>
        <div class="inputRow">
          <div>Id</div>
          <md-field style="padding: 0px; margin: 0px">
            <md-input v-model="featureToggleId" style="width: 80px"
          /></md-field>
        </div>
        <div class="inputRow">
          <div>Name</div>
          <md-field style="padding: 0px; margin: 0px">
            <md-input v-model="featureToggleDisplayName" style="width: 80px"
          /></md-field>
        </div>
      </md-dialog-content>
      <md-dialog-actions>
        <md-button
          class="md-primary md-raised"
          :disabled="!isValidToggleInput"
          @click="createFeatureToggle()"
          >Create</md-button
        >
        <md-button class="md-primary" @click="showCreateFeatureToggle = false"
          >Cancel</md-button
        >
      </md-dialog-actions>
    </md-dialog>
    <md-dialog :md-active.sync="showEditFeatureTypes">
      <md-dialog-title
        >Edit Feature Types for {{ filterTypesDisplayName }}</md-dialog-title
      >
      <md-dialog-content id="editFeatureTypes">
        <div id="selectedFilterTypes">
          <md-table class="dragArea">
            <draggable
              v-model="selectedFeatureTypesForEdit"
              draggable=".nothing"
              :group="{ name: 'featureTypes', pull: false }"
            >
              <md-table-row
                v-for="featureType in selectedFeatureTypesForEdit"
                :key="featureType"
              >
                <md-table-cell>{{ featureType }}</md-table-cell>
                <md-table-cell
                  ><div
                    class="fa fa-trash"
                    @click="removeFeatureType(featureType)"
                  ></div
                ></md-table-cell>
              </md-table-row>
            </draggable>
          </md-table>
        </div>
        <div>
          <div>Drag values to left:</div>
          <draggable
            v-model="featureTypeValues"
            :group="{ name: 'featureTypes', pull: 'clone' }"
            :move="checkMoveFeatureType"
          >
            <div v-for="featureType in featureTypeValues" :key="featureType">
              <div>{{ featureType }}</div>
            </div>
          </draggable>
        </div>
      </md-dialog-content>
      <md-dialog-actions>
        <md-button class="md-primary md-raised" @click="updateFeatureTypes()"
          >Update</md-button
        >
        <md-button class="md-primary" @click="stopEditingFeatureTypes()"
          >Cancel</md-button
        >
      </md-dialog-actions>
    </md-dialog>
  </div>
</template>
<script>
import appSettings from "./appSettings";
import { mapActions, mapMutations } from "vuex";
import multiselect from "vue-multiselect";
import draggable from "vuedraggable";

export default {
  name: "AppAdminFeatures",
  components: {
    multiselect,
    draggable,
  },
  data: () => ({
    showCreateFeatureToggle: false,

    selectedToggleId: null,
    featureToggles: [],
    featureToggleId: "",
    featureToggleDisplayName: "",
    featureToggleType: { value: "Any", label: "(Select Type)" },

    toggleGroups: [],
    toggleUsers: [],

    selectedAdUser: null,
    adUsers: [],
    selectedUser: null,
    users: [],

    selectedAdGroup: null,
    adGroups: [],
    selectedGroup: null,
    groups: [],

    showEditFeatureTypes: false,
    selectedUserForEdit: null,
    selectedGroupForEdit: null,
    selectedFeatureTypesForEdit: [],
    featureTypeValues: ["Experimental", "Research", "Administrative"],

    // highlighting
    adUserFeatures: [],

    featureTypes: [
      { label: "Any", value: "(Select Type)" },
      { label: "Experimental", value: "Experimental" },
      { label: "Research", value: "Research" },
      { label: "Administrative", value: "Administrative" },
    ],
  }),
  computed: {
    isValidToggleInput() {
      return (
        this.featureToggleId &&
        this.featureToggleDisplayName &&
        this.featureToggleType.value !== "Any"
      );
    },
    filterTypesDisplayName() {
      return this.selectedUserForEdit
        ? `'User '${this.selectedUserForEdit.displayName}'`
        : this.selectedGroupForEdit
        ? `'Group '${this.selectedGroupForEdit.displayName}'`
        : "";
    },
  },
  watch: {
    selectedToggleId(toggleId) {
      this.updateLinkedUsersAndGroups(toggleId);
    },
    featureToggles() {
      this.updateLinkedUsersAndGroups(this.selectedToggleId);
    },
    selectedAdUser() {
      this.showTogglesOfAdUser();
    },
  },
  async created() {
    await this.loadAll();
  },
  methods: {
    ...mapActions(["fetchAzure", "postAzure", "putAzure", "deleteAzure"]),
    ...mapMutations(["setErrorMessage"]),
    updateLinkedUsersAndGroups(toggleId) {
      this.toggleGroups = [];
      this.toggleUsers = [];
      if (!toggleId) return;

      let foundToggle = this.featureToggles.find((x) => x.id === toggleId);
      if (!foundToggle) {
        this.selectedToggleId = null;
      } else {
        this.toggleGroups = foundToggle.groups;
        this.toggleUsers = foundToggle.users;
      }
    },
    async loadFeatureToggles() {
      let url = `${appSettings.remoteurl}/api/featureToggles`;
      this.featureToggles = await this.fetchAzure(url);
    },
    isToggleAllowedForSelectedAdUser(featureToggle) {
      return this.adUserFeatures
        .map((toggle) => toggle.id)
        .includes(featureToggle.id);
    },
    editUserFeatureTypes(user) {
      this.showEditFeatureTypes = true;
      this.selectedUserForEdit = user;
      this.selectedFeatureTypesForEdit = user.featureTypes;
    },
    editGroupFeatureTypes(group) {
      this.showEditFeatureTypes = true;
      this.selectedGroupForEdit = group;
      this.selectedFeatureTypesForEdit = group.featureTypes;
    },
    stopEditingFeatureTypes() {
      this.showEditFeatureTypes = false;
      this.selectedUserForEdit = null;
      this.selectedGroupForEdit = null;
    },
    async updateFeatureTypes() {
      let url = this.selectedUserForEdit
        ? `${appSettings.remoteurl}/api/featureToggles/users/${this.selectedUserForEdit.id}/featureTypes`
        : `${appSettings.remoteurl}/api/featureToggles/groups/${this.selectedGroupForEdit.id}/featureTypes`;

      await this.postAzure({
        url,
        metadata: this.selectedFeatureTypesForEdit,
      });
      this.stopEditingFeatureTypes();
      await this.loadUsers();
      await this.loadGroups();
    },
    groupLinkedToSelectedFeature(group) {
      return this.toggleGroups.map((x) => x.id).includes(group.id);
    },
    userLinkedToSelectedFeature(user) {
      return this.toggleUsers.map((x) => x.id).includes(user.id);
    },
    checkMoveGroup(evt) {
      return !this.toggleGroups.some(
        (elem) => elem.id === evt.draggedContext.element.id
      );
    },
    async linkToggleAndGroup(evt) {
      let groupId = this.groups[evt.oldIndex].id;
      let url = `${appSettings.remoteurl}/api/featureToggles/${this.selectedToggleId}/groups/${groupId}`;
      await this.putAzure({
        url,
        metadata: {},
      });
      await this.loadFeatureToggles();
    },
    async unlinkToggleAndGroup(groupId) {
      let url = `${appSettings.remoteurl}/api/featureToggles/${this.selectedToggleId}/groups/${groupId}`;
      await this.deleteAzure({ url });
      await this.loadFeatureToggles();
    },
    checkMoveFeatureType(evt) {
      return !this.selectedFeatureTypesForEdit.some(
        (elem) => elem === evt.draggedContext.element
      );
    },
    removeFeatureType(featureType) {
      this.selectedFeatureTypesForEdit =
        this.selectedFeatureTypesForEdit.filter((x) => x != featureType);
    },
    checkMoveUser(evt) {
      return !this.toggleUsers.some(
        (elem) => elem.id === evt.draggedContext.element.id
      );
    },
    async linkToggleAndUser(evt) {
      let userId = this.users[evt.oldIndex].id;
      let url = `${appSettings.remoteurl}/api/featureToggles/${this.selectedToggleId}/users/${userId}`;
      await this.putAzure({
        url,
        metadata: {},
      });
      await this.loadFeatureToggles();
    },
    async unlinkToggleAndUser(userId) {
      let url = `${appSettings.remoteurl}/api/featureToggles/${this.selectedToggleId}/users/${userId}`;
      await this.deleteAzure({ url });
      await this.loadFeatureToggles();
    },
    async createFeatureToggle() {
      const url = `${appSettings.remoteurl}/api/featureToggles/${this.featureToggleId}`;
      await this.postAzure({
        url,
        metadata: {
          displayName: this.featureToggleDisplayName,
          featureType: this.featureToggleType.value,
        },
      });
      await this.loadFeatureToggles();
      this.showCreateFeatureToggle = false;
    },
    async deleteFeatureToggle(id) {
      const url = `${appSettings.remoteurl}/api/featureToggles/${id}`;
      await this.deleteAzure({ url });
      await this.loadFeatureToggles();
    },
    async loadAll() {
      await this.loadFeatureToggles();
      await this.loadUsers();
      await this.loadGroups();
      await this.loadAdGroups();
    },
    async loadUsers() {
      let url = `${appSettings.remoteurl}/api/featureToggles/users`;
      this.users = await this.fetchAzure(url);
    },
    async loadAdUsers(prefix) {
      if (prefix.length < 2) return;
      let url = `${appSettings.remoteurl}/api/ad/users/${prefix}`;
      this.adUsers = await this.fetchAzure(url);
    },
    async loadGroups() {
      let url = `${appSettings.remoteurl}/api/featureToggles/groups`;
      this.groups = await this.fetchAzure(url);
    },
    async loadAdGroups() {
      let url = `${appSettings.remoteurl}/api/ad/groups`;
      this.adGroups = await this.fetchAzure(url);
    },
    async createUser() {
      const url = `${appSettings.remoteurl}/api/featureToggles/users/${this.selectedAdUser.id}`;
      let metadata = {};

      await this.postAzure({
        url,
        metadata,
      });
      await this.loadUsers();
    },
    async showTogglesOfAdUser() {
      this.adUserFeatures = [];
      if (!this.selectedAdUser) return;

      const url = `${appSettings.remoteurl}/api/allowedToggles/${this.selectedAdUser.id}`;
      this.adUserFeatures = await this.fetchAzure(url);
    },
    async deleteUser(id) {
      const url = `${appSettings.remoteurl}/api/featureToggles/users/${id}`;
      await this.deleteAzure({ url });
      await this.loadUsers();
    },
    async createGroup() {
      const url = `${appSettings.remoteurl}/api/featureToggles/groups/${this.selectedAdGroup.id}`;
      let metadata = {};

      await this.postAzure({
        url,
        metadata,
      });
      await this.loadGroups();
    },
    async deleteGroup(id) {
      const url = `${appSettings.remoteurl}/api/featureToggles/groups/${id}`;
      await this.deleteAzure({ url });
      await this.loadGroups();
    },
  },
};
</script>
<style scoped>
.adRow {
  display: flex;
  flex-direction: row;
}
#editPage {
  display: flex;
  flex-direction: row;
}
#featureTogglePage {
  display: flex;
  flex-direction: column;
}
#createFeatureToggle {
  width: 400px;
  height: 300px;
  flex-direction: column;
  align-content: top;
}
.md-field .md-input {
  background-color: #3d8094;
  margin-top: 4px;
  border-radius: 8px;
}
.md-field .label {
  margin: 10px;
}
.md-table-row.md-raised {
  background-color: #5daec7;
}
.dragArea {
  background-color: silver;
  padding: 2px;
  min-height: 20px;
}
.column {
  margin: 3px;
}
.inputRow {
  display: flex;
  flex-direction: row;
  width: 300px;
}
.isAllowedForSelectedAdUser {
  border-color: yellow;
  border-style: inset;
}
.linkedToSelectedFeature {
  border-color: green;
  border-style: inset;
}
.fa-edit {
  margin-right: 4px;
}
</style>
