<template id="app-assetformat-explorer">
  <div class="formatExplorer">
    <div class="formatList">
      <div>Format Type</div>
      <app-assetformat-listitem :format="format" :type="format.metadata._type">
      </app-assetformat-listitem>
    </div>
    <div v-if="loading" style="padding: 10px">Loading...</div>
    <div v-else>
      <div v-for="folder in foldersInSelectedFolder" :key="folder">
        <md-button @click.prevent="selectFolder(folder)">
          <md-icon>folder</md-icon>{{ folder }}
        </md-button>
      </div>
      <div v-for="(file, index) in filesInSelectedFolder" :key="file">
        <div
          v-if="!file.endsWith('/')"
          style="
            display: flex;
            justify-content: space-between;
            align-items: center;
          "
        >
          <md-button>
            <md-icon
              :style="{
                color: files[index] === root ? 'red' : 'black',
              }"
              >insert_drive_file</md-icon
            >
            <a href="#" @click.prevent="downloadFile(file)">{{ file }}</a>
          </md-button>
          <div>
            <i
              class="fa"
              :class="{
                'fa-check-square-o': isRootFile(files[index]),
                'fa-square-o': !isRootFile(files[index]),
              }"
              style="margin-right: 10px"
              @click.prevent="setRoot(files[index])"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import appSettings from "./appSettings";
import appAssetFormatListItem from "./appAssetFormatListItem";

export default {
  name: "AppAssetFormatExplorer",
  components: {
    "app-assetformat-listitem": appAssetFormatListItem,
  },
  props: {
    format: {
      type: Object,
      default: Object.default,
    },
  },
  data: () => ({
    files: [],
    selectedFolder: "",
    loading: false,
    root: null,
  }),
  computed: {
    ...mapState(["user", "idToken", "selectedGroup", "selectedAsset"]),
    foldersInSelectedFolder() {
      const foldersInFolder = this.filesInFolder()
        .filter((file) => file.includes("/"))
        .map((file) => file.substr(0, file.indexOf("/")));

      let uniqueFolders = Array.from(new Set(foldersInFolder));
      uniqueFolders.sort();

      return this.selectedFolder ? ["..", ...uniqueFolders] : uniqueFolders;
    },
    filesInSelectedFolder() {
      return this.filesInFolder().filter((file) => !file.includes("/"));
    },
  },
  async created() {
    this.loading = true;
    this.selectedFolder = "";
    this.root = this.format.metadata._root;

    try {
      const fileInfos = await this.fetchAzure(
        `${appSettings.remoteurl}/api/groups/${this.selectedGroup.id}/assets/${this.selectedAsset.id}/` +
          `formats/${this.format.id}/files`
      );
      this.files = fileInfos.map((fileInfo) => fileInfo.metadata.relativePath);
    } finally {
      this.loading = false;
    }
  },
  methods: {
    ...mapActions([
      "updateAssetMetadata",
      "updateFormatMetadata",
      "fetchAzure",
    ]),
    ...mapMutations(["updateFormatInStore"]),
    isRootFile(file) {
      return this.root && this.root.includes(file);
    },
    filesInFolder() {
      return this.files
        .filter((file) => file.startsWith(this.selectedFolder))
        .map((file) => file.substr(this.selectedFolder.length));
    },
    close() {
      this.$emit("close");
    },
    setRoot(file) {
      const update = {
        _root: file,
      };
      this.updateFormatMetadata({
        format: this.format,
        metadata: update,
      });
      this.updateFormatInStore({
        id: this.format.id,
        metadata: update,
      });
      this.root = file;
    },
    selectFolder(folder) {
      this.selectedFolder =
        folder === ".."
          ? this.selectedFolder.replace(/[^/]*\/$/, "")
          : `${this.selectedFolder}${folder}/`;
    },
    async downloadFile(file) {
      if (file) {
        const url =
          `${appSettings.remoteurl}/api/groups/${this.selectedGroup.id}/assets/${this.selectedAsset.id}` +
          `/formats/${this.format.id}/files/${this.selectedFolder}${file}?access_token=${this.idToken}`;
        var a = document.createElement("a");
        a.href = url;
        a.download = file;
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove(); //afterwards we remove the element again
      }
    },
  },
};
</script>
<style>
.folder .md-ripple {
  justify-content: left !important;
}
</style>
<style scoped>
.formatExplorer {
  border-radius: 5px;
  background-color: var(--accent-color-light);
  width: 640px;
  overflow: auto;
}

.formatList {
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  margin-bottom: 20px;
  padding-left: 20px;
  padding-right: 0px;
}

#buttonBar {
  display: flex;
  justify-content: flex-end;
  align-self: stretch;
}

#btnClose {
  margin: 20px;
  margin-right: 40px;
}
.md-button {
  cursor: pointer;
}
.button {
  cursor: pointer;
}
</style>
