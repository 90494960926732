<template id="app-stl-upload">
  <md-dialog
    :md-active="isStlUpload"
    :md-click-outside-to-close="false"
    :md-close-on-esc="false"
  >
    <md-dialog-title>Surface Upload</md-dialog-title>
    <md-dialog-content id="stlUpload">
      <div>
        <div id="stlHeader">
          <md-field class="fileNameColumn">File Name</md-field>
          <md-field class="surfaceTypeColumn">Anatomical Template</md-field>
          <md-field class="displayNameColumn">Display Name</md-field>
        </div>
        <div></div>
        <md-list>
          <template v-for="(file, index) in localFileInfo.files">
            <div :key="file.fileName" class="listRow">
              <md-field id="fileName" class="fileNameColumn">{{
                shortenString(file.file.name)
              }}</md-field>
              <multiselect
                id="surfaceTemplateColumn"
                v-model="surfaceArray[index]"
                :allow-empty="false"
                deselect-label=""
                label="displayName"
                max-height="600"
                name="surfaceTemplate"
                open-direction="auto"
                :options="surfaceTemplateList"
                placeholder="Search..."
                :searchable="true"
                select-label=""
                selected-label=""
              >
              </multiselect>
              <md-field class="displayNameColumn"
                ><md-input
                  id="displayName"
                  v-model="file.displayName"
                ></md-input
              ></md-field>
            </div>
          </template>
        </md-list>
        <div v-if="hasMixedFileTypes">
          <div class="spacer"></div>
          <img
            src="/assets/icon/warning.svg"
            style="width: 24px; margin-right: 12px"
          />Multiple file types found, only uploading STL
        </div>
      </div>
    </md-dialog-content>
    <md-dialog-actions>
      <md-button class="md-primary md-raised" @click="startUpload()"
        >Upload</md-button
      >
      <md-button class="md-primary" @click="cancelUpload()">Cancel</md-button>
    </md-dialog-actions>
  </md-dialog>
</template>

<script>
import appSettings from "./appSettings";
import { mapState, mapActions } from "vuex";
import FileUploadInfo from "../classes/FileUploadInfo";
import multiselect from "vue-multiselect";

export default {
  name: "AppDicomAnonymize",
  components: {
    multiselect,
  },
  props: {
    fileUploadInfo: {
      type: Object,
      default() {
        return new FileUploadInfo();
      },
    },
  },
  data: () => ({
    surfaceArray: [],
    surfaceTemplates: [],
    surfaceTemplateList: [],
    hasMixedFileTypes: false,
    localFileInfo: new FileUploadInfo(),
    isUploading: false,
  }),
  computed: {
    ...mapState(["selectedGroup"]),
    isStlUpload() {
      return this.localFileInfo.isStl;
    },
  },
  watch: {
    surfaceArray() {
      for (let i = 0; i < this.localFileInfo.files.length; i++) {
        this.localFileInfo.files[i].surfaceType =
          this.surfaceArray[i].shortName;
      }
    },
    fileUploadInfo(fileUploadInfo) {
      this.setLocalFileInfo(fileUploadInfo);
    },
  },
  async created() {
    var templateList = await this.fetchAzure(
      `${appSettings.remoteurl}/api/surfaceTemplates`
    );

    this.surfaceTemplates = templateList
      .map((x) => x.metadata)
      .sort((a, b) => {
        return a.displayName > b.displayName ? 1 : -1;
      });
    this.setLocalFileInfo(this.fileUploadInfo);

    for (const element of this.surfaceTemplates) {
      this.surfaceTemplateList.push({
        displayName: element.displayName,
        shortName: element.shortName,
      });
    }
  },
  methods: {
    ...mapActions(["fetchAzure"]),
    setLocalFileInfo(fileUploadInfo) {
      this.localFileInfo = fileUploadInfo;
      if (!fileUploadInfo.isStl) return;

      const totalFiles = fileUploadInfo.files.length;
      this.localFileInfo.files = this.getStlFiles(fileUploadInfo);
      this.surfaceArray = [];
      for (const file of this.localFileInfo.files) {
        this.surfaceArray.push(
          this.surfaceTemplateList.find((x) => x.shortName === file.surfaceType)
        );
      }

      this.hasMixedFileTypes = fileUploadInfo.files.length != totalFiles;
    },
    getStlFiles(fileUploadInfo) {
      return fileUploadInfo.files
        .filter((file) => file.file.name.toLowerCase().endsWith(".stl"))
        .map((file) => {
          return this.addSurfaceInfo(file);
        });
    },
    addSurfaceInfo(file) {
      file.displayName = file.file.name.replace(/\.[^/.]+$/, "");
      file.surfaceType = this.guessSurfaceType(file.file.name);
      return file;
    },
    guessSurfaceType(fileName) {
      // reverse to match specific structures before the generic one,
      // e.g. tumor2 vs tumor
      const reversedSurfaceTemplates = [...this.surfaceTemplates].reverse();
      for (const surfaceTemplate of reversedSurfaceTemplates) {
        if (this.hasSurfaceRoot(fileName, surfaceTemplate.shortName)) {
          return surfaceTemplate.shortName;
        }
      }
      return "other";
    },
    hasSurfaceRoot(fileName, surfaceShortName) {
      const surfaceRoot = surfaceShortName.replace(/1$/, "").toLowerCase();
      return fileName.toLowerCase().includes(surfaceRoot);
    },
    shortenString(value) {
      return value.length <= 23
        ? value
        : `${value.slice(0, 10)}...${value.slice(-10)}`;
    },
    startUpload() {
      if (this.isUploading) {
        return;
      }
      // prevent double click to trigger upload twice
      this.isUploading = true;
      this.$emit("uploadFiles", this.fileUploadInfo);
    },
    cancelUpload() {
      this.$emit("cancelUpload");
    },
  },
};
</script>
<style>
.md-field.noAfter > .md-icon::after {
  content: none;
}
.md-field.noAfter > .md-input-action {
  top: 0px;
}
</style>
<style scoped>
#stlUpload {
  width: 620px;
  height: 440px;
  flex-direction: column;
}
#stlHeader {
  width: 540px;
  display: flex;
  flex-direction: row;
}
#stlHeader .md-field {
  margin-left: 18px;
  margin-right: 18px;
}
.fileNameColumn {
  width: 160px !important;
}
#fileName {
  margin-top: 8px;
}
#displayName {
  margin-top: 0px;
  width: 180px;
}
.surfaceTypeColumn {
  width: 150px !important;
}
.displayNameColumn {
  width: 180px !important;
}
.stlrow {
  display: flex;
  margin-top: 16px;
  flex-direction: row;
}
.md-field {
  width: 120px;
  margin-bottom: 0px;
  padding-top: 0px;
  min-height: 24px;
}
.md-field > div {
  padding-left: 12px;
  text-align: left;
}
.md-field.md-has-placeholder .md-input {
  padding-left: 12px;
}
.md-field.md-focused label,
.md-field.md-has-value label {
  display: none;
}
.md-field label {
  top: 4px;
  padding-left: 4px;
}
.md-field .md-input {
  background-color: #398ca5;
  margin-top: 4px;
  border-radius: 8px;
}
.listRow {
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  grid-gap: 20px;
  margin-bottom: 8px;
  margin-top: 8px;
  padding-left: 8px;
  padding-right: 8px;
}
::v-deep .multiselect {
  width: 150px;
}
::v-deep .multiselect__tags {
  background-color: #2e728a;
}
::v-deep .multiselect__content-wrapper {
  height: 200px;
  background-color: #2d6b7d;
  overflow-x: hidden;
}
::v-deep .multiselect__option--highlight {
  background-color: #193f4a;
}
</style>
