<template id="appAdminProgress">
  <div id="editPage">
    <div id="editHeader">
      <div id="showing">
        Showing {{ filteredProgressItems.length }}/{{ progressItems.length }}
      </div>
      <div id="refresh">
        <i class="fa fa-refresh" @click="loadProgressItems()"></i>
      </div>
      <div class="radioContainer">
        <md-radio v-model="progressState" value="InProgress"
          >In Progress</md-radio
        >
        <md-radio v-model="progressState" value="All">All</md-radio>
        <md-radio v-model="progressState" value="Failed">Failed</md-radio>
      </div>
      <md-field style="padding: 0px; margin: 0px; width: 200px"
        ><div class="label">Filter:</div>
        <md-input v-model="progressFilter" style="width: 100px"
      /></md-field>
      <md-field style="padding: 0px; margin: 0px; width: 200px"
        ><div class="label">Day:</div>
        <md-input
          v-model="day"
          :style="{
            background: isValidDay() ? '#337789' : 'red',
          }"
      /></md-field>
    </div>
    <md-table>
      <md-table-row>
        <md-table-head>Display Name</md-table-head>
        <md-table-head>Function</md-table-head>
        <md-table-head>State</md-table-head>
        <md-table-head>Percentage</md-table-head>
        <md-table-head>Error Message</md-table-head>
        <md-table-head>Duration</md-table-head>
        <md-table-head>Last Modified</md-table-head>
        <md-table-head>Group Id</md-table-head>
        <md-table-head>Asset Ids</md-table-head>
        <md-table-head></md-table-head>
      </md-table-row>
      <md-table-row v-for="item in filteredProgressItems" :key="item.id">
        <md-table-cell>{{ item.displayName }}</md-table-cell>
        <md-table-cell>{{ item.function }}</md-table-cell>
        <md-table-cell>{{ item.state }}</md-table-cell>
        <md-table-cell>{{ item.percentageCompleted }}</md-table-cell>
        <md-table-cell>{{ item.errorMessage }}</md-table-cell>
        <md-table-cell>{{
          formatDuration(item.created, item.modified)
        }}</md-table-cell>
        <md-table-cell>{{ item.modified }}</md-table-cell>
        <md-table-cell>{{ item.groupId }}</md-table-cell>
        <md-table-cell>{{ item.assetIds }}</md-table-cell>
        <md-table-cell
          ><i class="fa fa-trash" @click="deleteProgress(item.id)"></i
        ></md-table-cell>
      </md-table-row>
    </md-table>
  </div>
</template>
<script>
import appSettings from "./appSettings";
import { mapActions } from "vuex";
import moment from "moment";

export default {
  name: "AppAdminProgress",

  data: () => ({
    day: moment(new Date()).format("yyyy-MM-DD"),
    progressState: "InProgress",
    progressFilter: "",
    progressItems: [],
  }),
  computed: {
    filteredProgressItems() {
      if (!this.progressFilter) return this.progressItems;

      const lowerCaseFilter = this.progressFilter.toLowerCase();
      return this.progressItems.filter(
        (x) =>
          x.displayName.toLowerCase().includes(lowerCaseFilter) ||
          x.function.toLowerCase().includes(lowerCaseFilter) ||
          x.state.toLowerCase().includes(lowerCaseFilter) ||
          (x.errorMessage &&
            x.errorMessage.toLowerCase().includes(lowerCaseFilter)) ||
          x.groupId.includes(lowerCaseFilter) ||
          x.assetIds.some((x) => x.includes(lowerCaseFilter))
      );
    },
  },
  watch: {
    async progressState() {
      await this.loadProgressItems();
    },
    async day() {
      if (!this.isValidDay()) return;
      await this.loadProgressItems();
    },
  },
  async created() {
    await this.loadProgressItems();
  },
  methods: {
    ...mapActions(["fetchAzure", "deleteAzure"]),
    async deleteProgress(progressId) {
      let url = `${appSettings.remoteurl}/api/progress/${progressId}`;
      await this.deleteAzure({ url });
      await this.loadProgressItems();
    },
    async loadProgressItems() {
      let url = `${appSettings.remoteurl}/api/progress?modified=eq${this.day}`;
      if (this.progressState !== "All") {
        url += `&state=${this.progressState}`;
      }
      this.progressItems = await this.fetchAzure(url);
    },
    formatDuration(date1, date2) {
      const diff = moment.utc(date2).diff(moment.utc(date1));
      return moment.utc(diff).format("HH:mm:ss");
    },
    isValidDay() {
      return this.day.length === 10;
    },
  },
};
</script>
<style>
.resourceItem .md-ripple {
  justify-content: left !important;
}
</style>
<style scoped>
#showing {
  margin: 10px;
  margin-left: 20px;
}
#refresh {
  margin: 10px;
}
#editHeader {
  display: flex;
  flex-direction: row;
}
#editDetails {
  margin-left: 20px;
}
.md-field .md-input {
  background-color: #3d8094;
  margin-top: 4px;
  border-radius: 8px;
}
.md-field .label {
  margin: 10px;
}
</style>
