<template>
  <div
    class="thumbnail"
    :style="{ backgroundImage: `url(${thumbnailUrl})` }"
  ></div>
</template>

<script>
import { mapState } from "vuex";
import appSettings from "./appSettings";

export default {
  name: "AppAssetThumbnail",
  components: {},
  data: () => ({ url: "" }),
  computed: {
    ...mapState(["selectedAsset", "idToken"]),
    thumbnailUrl() {
      let thumbUrl = this.selectedAsset
        ? this.selectedAsset.metadata.thumb
        : null;

      if (!thumbUrl || thumbUrl.startsWith("http")) return thumbUrl;
      return `${appSettings.remoteurl}/api${thumbUrl}?access_token=${this.idToken}`;
    },
  },
};
</script>
<style scoped>
.thumbnail {
  display: flex;
  margin: 20px 20px;
  align-items: center;
  justify-content: center;
  height: 22vh;
  width: 22vh;
  border-radius: 12px;
  background-color: black;
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto 100%;
}
</style>
