<template id="app-search-bar">
  <div class="search-bar">
    <div class="spacer"></div>
    <app-assetgroupdropdown
      v-if="isHome"
      class="groupDropdown"
    ></app-assetgroupdropdown>
    <app-search-field> </app-search-field>
    <app-assetfilterdropdown
      v-if="isHome && viewMode === 'research'"
    ></app-assetfilterdropdown>
    <app-sortassetlist class="sortAssets"></app-sortassetlist>
    <app-my-asset-filter v-if="isHome" class="myFilter"></app-my-asset-filter>
    <div v-if="isHome" class="lumiIdContainer" @click="showLumiIdDialog = true">
      <div class="lumiId">Lumi Patient ID</div>
      <div
        class="lumiIdIcon"
        :style="{
          backgroundImage: 'url(/assets/icon/lumiid.svg)',
        }"
      >
        &nbsp;
      </div>
    </div>
    <md-dialog
      id="lumiIdLookup"
      :md-active.sync="showLumiIdDialog"
      :md-click-outside-to-close="false"
      :md-close-on-esc="false"
    >
      <md-dialog-title>Search Lumi Patient ID</md-dialog-title>
      <div id="lumiIdInput">
        <md-field class="labelAndInput">
          <div>Patient ID (hospital)</div>
          <md-input v-model="patientId" placeholder="Patient Id" required />
        </md-field>
        <md-field
          class="labelAndInput"
          :class="{ invalidInput: !isValidBirthDateInput }"
        >
          <div>Birth Date</div>
          <md-datepicker
            ref="birthDatePicker"
            v-model="birthDate"
            class="noAfter"
            md-immediately
            :md-model-type="String"
            :md-open-on-focus="false"
            ><label>yyyy-mm-dd</label></md-datepicker
          >
          <div class="datePreview">{{ formattedBirthDate }}</div>
        </md-field>
        <md-field class="labelAndInput">
          <div>Lumi Secret</div>
          <md-input
            v-model="lumiSecret"
            placeholder="Secret for protecting id lookup"
          />
        </md-field>
      </div>
      <md-dialog-actions>
        <md-button
          class="md-primary md-raised"
          :disabled="!patientId || isDirty || !isValidBirthDateInput"
          @click="onSearchLumiId()"
          >Search for {{ lumiId || "..." }}</md-button
        >
        <md-button class="md-primary" @click="showLumiIdDialog = false"
          >Cancel</md-button
        >
      </md-dialog-actions>
    </md-dialog>
  </div>
</template>

<script>
import appSettings from "./appSettings";
import appAssetFilterDropdown from "./appAssetFilterDropdown";
import appAssetGroupDropdown from "./appAssetGroupDropdown";
import appSearchField from "./appSearchField";
import appSortAssetList from "./appSortAssetList";
import { mapGetters, mapState, mapMutations, mapActions } from "vuex";
import AppMyAssetFilter from "./appMyAssetFilter.vue";
import moment from "moment";

export default {
  name: "AppSearchBar",
  components: {
    "app-assetgroupdropdown": appAssetGroupDropdown,
    "app-assetfilterdropdown": appAssetFilterDropdown,
    "app-sortassetlist": appSortAssetList,
    "app-search-field": appSearchField,
    "app-my-asset-filter": AppMyAssetFilter,
  },
  data: () => ({
    showLumiIdDialog: false,
    patientId: null,
    lumiSecret: null,
    birthDate: null,
    lumiId: null,
    timeout: null,
    isDirty: false,
    isValidBirthDateInput: true,
  }),
  computed: {
    ...mapGetters(["isHome"]),
    ...mapState(["viewMode"]),
    formattedBirthDate() {
      return this.formatTextDate(this.birthDate);
    },
  },
  watch: {
    lumiSecret() {
      this.handleLumiIdInputChanged();
    },
    patientId() {
      this.handleLumiIdInputChanged();
    },
    birthDate() {
      this.handleLumiIdInputChanged();
    },
  },
  methods: {
    ...mapMutations(["setFilter"]),
    ...mapActions(["fetchAzure"]),
    checkValidDate() {
      this.isValidBirthDateInput =
        (this.$refs.birthDatePicker.inputDate || "") === (this.birthDate || "");
    },
    handleLumiIdInputChanged: function () {
      this.checkValidDate();
      this.isDirty = true;

      if (this.timeout) clearTimeout(this.timeout);

      this.timeout = setTimeout(() => {
        this.updateLumiId();
      }, 1000);
    },
    formatTextDate(date) {
      return moment(new Date(date)).format("MMMM D, YYYY");
    },
    onSearchLumiId() {
      this.checkValidDate();
      if (!this.isValidBirthDateInput) {
        return;
      }
      this.setFilter(this.lumiId);
      this.showLumiIdDialog = false;
    },
    async updateLumiId() {
      if (!this.patientId) {
        this.lumiId = null;
        return;
      }
      let url = `${appSettings.anonymizerurl}/api/lumiid?patientId=${this.patientId}`;
      if (this.lumiSecret) url += `&lumiSecret=${this.lumiSecret}`;
      if (this.birthDate) url += `&patientBirthDate=${this.birthDate}`;
      let info = await this.fetchAzure(url);
      this.lumiId = info.lumiId;
      this.isDirty = false;
    },
  },
};
</script>
<style>
.md-field.noAfter > .md-icon::after {
  content: none;
}
</style>
<style scoped>
.createAsset {
  margin-top: 2px;
}
.spacer {
  width: 10px;
}
.sortAssets {
  margin-left: 20px;
}
.myFilter {
  margin-left: 20px;
}
#lumiIdInput {
  flex-direction: column;
  width: 345px;
  padding-left: 30px;
  padding-bottom: 20px;
  padding-right: 30px;
  border-radius: 5px;
  background-color: var(--accent-color-light);
  overflow: auto;
}
.labelAndInput {
  margin-top: 16px;
  padding-right: 4px;
  flex-direction: column;
}
.search-bar {
  display: flex;
  align-items: center;
  margin-left: 0px;
  margin-right: 20px;
  margin-top: 6px;
  padding-left: 10px;
  padding-right: 10px;
  height: 88px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-self: center;
}
#lumiIdLookup {
  flex-direction: column;
  height: 600px;
}
.lumiIdContainer {
  display: flex;
  margin-left: 10px;
  align-items: center;
}
.lumiId {
  font-size: 18px;
  color: white;
}
.lumiIdIcon {
  margin-left: 10px;
  min-width: 64px;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  background-position: center;
  background-color: #5194a7;
  background-size: 32px 32px;
  background-repeat: no-repeat;
}
.datePreview {
  margin-left: 25px;
  font-style: italic;
}
input {
  width: 280px;
}
.invalidInput {
  border: 2px solid red;
}
.md-field {
  margin-bottom: 0px;
  padding-top: 0px;
  min-height: 24px;
}
.md-field > div {
  padding-left: 12px;
}
.md-field.md-has-placeholder .md-input {
  padding-left: 12px;
  color: darkgray;
}
.md-field.md-focused label,
.md-field.md-has-value label {
  display: none;
}
.md-field label {
  top: 4px;
  padding-left: 4px;
}
.md-field .md-input {
  background-color: #3d8094;
  margin-top: 4px;
  border-radius: 8px;
}
.md-datepicker {
  background-color: #3d8094;
  border-radius: 8px;
}
</style>
