<template id="appAdminContent">
  <div id="editPage">
    <div id="editHeader">
      <multiselect
        v-model="resourceType"
        :allow-empty="false"
        class="dropDown"
        deselect-label=""
        label="label"
        :options="contentDropdownValues"
        placeholder="Start typing to search..."
        :preselect-first="true"
        :searchable="true"
        select-label=""
        selected-label=""
      ></multiselect>
    </div>
    <div id="editPageContent">
      <div id="resourceList">
        <md-field style="padding: 0px; margin: 0px"
          ><div class="label">Filter:</div>
          <md-input v-model="resourceFilter" style="width: 200px"
        /></md-field>
        <div style="margin: 10px">
          Found {{ resourceList.length }} {{ resourceType.value }}
        </div>
        <div v-for="resource in filteredResources" :key="resource.id">
          <md-button
            class="resourceItem"
            :class="{
              'md-raised':
                selectedResource && resource.id === selectedResource.id,
            }"
            @click="selectedResource = resource"
          >
            {{ resource.metadata.displayName || resource.id }}
          </md-button>
        </div>
      </div>
      <div id="editDetails">
        <div id="editHeader">
          <div v-if="selectedResource" class="label" style="margin-top: 16px">
            Resource {{ selectedResource.id }} -
            {{ selectedResource.metadata.displayName }}
          </div>
          <md-button
            v-if="selectedResource"
            class="md-primary md-raised"
            :disabled="!isDirty"
            @click="updateResourceForType()"
          >
            Update
          </md-button>
          <md-button
            v-if="selectedResource"
            class="md-primary md-raised"
            @click="showDeleteConfirm = true"
          >
            Delete
          </md-button>
          <md-button
            class="md-primary md-raised"
            @click="createResourceForType()"
          >
            Create
          </md-button>
        </div>

        <textarea
          v-model="editableMetadata"
          class="jsonText"
          cols="80"
          rows="20"
        ></textarea>
      </div>
      <md-dialog :md-active.sync="showDeleteConfirm">
        <md-dialog-title
          >Delete one of the {{ resourceType.label }}?</md-dialog-title
        >
        <md-dialog-content>
          <div id="deleteDialogContent">
            <div>
              You are about to PERMANENTLY delete
              {{ selectedResource && selectedResource.id }} -
              {{ selectedResource && selectedResource.metadata.displayName }}.
            </div>
            <div class="spacer"></div>
            <div>Are you sure?</div>
          </div>
        </md-dialog-content>
        <md-dialog-actions>
          <md-button class="md-primary md-raised" @click="permanantlyDelete"
            >Delete</md-button
          >
          <md-button class="md-primary" @click="showDeleteConfirm = false"
            >Cancel</md-button
          >
        </md-dialog-actions>
      </md-dialog>
    </div>
  </div>
</template>
<script>
import { mapActions, mapMutations } from "vuex";
import multiselect from "vue-multiselect";

export default {
  name: "AppAdminContent",
  components: {
    multiselect,
  },
  data: () => ({
    resourceFilter: "",
    resourceType: null,
    resourceList: [],
    selectedResource: null,
    editableMetadata: "",
    showDeleteConfirm: false,
    contentDropdownValues: [
      { label: "Groups", value: "groups" },
      { label: "Surface Templates", value: "surfacetemplates" },
      { label: "User Settings", value: "usersettings" },
    ],
  }),
  computed: {
    filteredResources() {
      if (!this.resourceFilter) return this.resourceList;

      const lowerCaseFilter = this.resourceFilter.toLowerCase();
      return this.resourceList.filter(
        (x) =>
          x.id.toLowerCase().includes(lowerCaseFilter) ||
          (x.metadata.displayName &&
            x.metadata.displayName.toLowerCase().includes(lowerCaseFilter))
      );
    },
    isDirty() {
      return (
        this.stringifyResourceMetadata(this.selectedResource) !==
        this.editableMetadata
      );
    },
  },
  watch: {
    selectedResource(resource) {
      this.editableMetadata = this.stringifyResourceMetadata(resource);
    },
    resourceType() {
      this.load();
      this.selectedResource = null;
    },
  },
  async created() {
    this.resourceType = this.contentDropdownValues[0];
    this.editableMetadata = this.stringifyResourceMetadata(null);
  },
  methods: {
    ...mapActions([
      "loadResources",
      "createResource",
      "updateResource",
      "deleteResource",
    ]),
    ...mapMutations(["setErrorMessage"]),
    async load() {
      this.resourceList = await this.loadResources({
        resourceType: this.resourceType.value,
      });
    },

    async createResourceForType() {
      const createdResource = await this.createResource({
        resourceType: this.resourceType.value,
        metadata: this.getParsedMetadata(),
      });
      await this.load();
      const newResource = this.resourceList.filter(
        (x) => x.id === createdResource.id
      )[0];
      this.selectedResource = newResource;
      this.displayNameForCreate = "";
    },
    async updateResourceForType() {
      await this.updateResource({
        resourceType: this.resourceType.value,
        id: this.selectedResource.id,
        metadata: this.getParsedMetadata(),
      });
      await this.load();
    },
    async runDisiorBatch() {
      this.urlToExecute = `https://segmentationfunctionstest.azurewebsites.net/api/segmentation/batch/disior/group/${this.disiorGroup.trim()}/metadata/tags/disior`;
      await this.executeUrl();
    },
    getParsedMetadata() {
      try {
        return JSON.parse(this.editableMetadata);
      } catch (e) {
        this.setErrorMessage(e);
        throw e;
      }
    },
    async permanantlyDelete() {
      await this.deleteResource({
        resourceType: this.resourceType.value,
        id: this.selectedResource.id,
      });
      await this.load();
      this.selectedResource = null;
      this.showDeleteConfirm = false;
    },
    stringifyResourceMetadata(resource) {
      return JSON.stringify(
        resource ? resource.metadata : { displayName: "" },
        null,
        2
      );
    },
  },
};
</script>
<style>
.resourceItem .md-ripple {
  justify-content: left !important;
}
</style>
<style scoped>
.radioContainer {
  margin-left: 12px;
}
.dropDown {
  width: 200px;
  margin-left: 10px;
  border-radius: 10px;
  padding-left: 6px;
  padding-top: 8px;
  margin-bottom: 20px;
}
#commands {
  display: flex;
  flex-direction: column;
}
.command {
  display: flex;
  flex-direction: row;
}
#editPage {
  display: flex;
  flex-direction: column;
}
#editPageContent {
  display: flex;
  flex-direction: row;
}
#showing {
  margin: 10px;
  margin-left: 20px;
}
#refresh {
  margin: 10px;
}
#editHeader {
  display: flex;
  flex-direction: row;
}
#editDetails {
  margin-left: 20px;
}
#resourceList {
  display: flex;
  flex-direction: column;
}
.resourceItem {
  margin: 0px;
  height: 16px;
}
.md-field .md-input {
  background-color: #3d8094;
  margin-top: 4px;
  border-radius: 8px;
}
.md-field .label {
  margin: 10px;
}
.jsonText {
  background-color: #3d8094;
  color: white;
}
::v-deep .multiselect__select {
  margin-top: 8px;
}
</style>
