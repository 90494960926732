var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"metadatacontainer"}},[_c('h1',[_vm._v(_vm._s(_vm.titleText))]),_c('div',{attrs:{"id":"formatList"}},[_vm._l((_vm.formats),function(format){return _c('div',{key:format.id,staticClass:"formatTile",class:{
        hologram: _vm.isHologram(format),
        'selected-hologram': _vm.isSelectedHologram(format),
        'selected-revision': _vm.selectedRevision === format.id,
      }},[_c('div',{staticClass:"revision-verified-box",class:{
          checked: format.verified,
        }}),_c('div',{staticClass:"select-revision-zone",on:{"click":function($event){return _vm.selectRevision(format)}}},[(_vm.isSelectedHologram(format))?_c('div',{staticClass:"active-revision"},[_vm._v(" Active ")]):_vm._e(),_c('div',{staticClass:"tile-date",class:{
            selected: _vm.isSelectedHologram(format),
          }},[_vm._v(" "+_vm._s(_vm.displayText(format))+" ")]),_c('div',[_vm._v(" "+_vm._s(format.metadata._author || "Author unknown")+" ")])]),_c('div',[(_vm.isHologram(format))?_c('i',{staticClass:"fa",class:{
            disabled: _vm.isReadOnlyGroup,
            'fa-lock': format.locked,
            'fa-lock-open': !format.locked,
          },on:{"click":function($event){return _vm.toggleLock(format)}}}):_vm._e(),(!_vm.isHologram(format))?_c('i',{staticClass:"fa fa-folder",class:{ disabled: !_vm.isReadOnlyGroup },on:{"click":function($event){return _vm.clickExplore(format)}}}):_vm._e(),_c('i',{staticClass:"fa fa-ellipsis-v ellipsis",on:{"click":function($event){return _vm.showEllipsisMenu(format, $event)}}})])])}),(_vm.selectedAsset && _vm.selectedAsset.metadata._type === 'Volume')?_c('md-button',{staticClass:"md-primary md-raised",on:{"click":function($event){return _vm.generateNifti()}}},[_vm._v("Generate Nifti")]):_vm._e()],2),_c('div',{attrs:{"id":"ellipsis-menu"}},[(_vm.selectedEllipsisMenu)?_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.onFocusOut),expression:"onFocusOut"}],staticClass:"ellipsis-menu"},[_c('ul',[_c('li',{on:{"click":function($event){return _vm.setRevisionActive(_vm.selectedEllipsisMenu)}}},[_vm._v("Set Active")]),_c('li',{class:{ disabled: _vm.isReadOnlyGroup },on:{"click":function($event){return _vm.clickDownload(_vm.selectedEllipsisMenu)}}},[_vm._v(" Download Data File ")]),_c('li',{class:{ disabled: _vm.isReadOnlyGroup },on:{"click":function($event){return _vm.clickDelete(_vm.selectedEllipsisMenu)}}},[_vm._v(" Delete ")])])]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }