<template id="app-user-profile">
  <div>
    <md-menu
      class="menu"
      md-align-trigger
      :md-close-on-click="true"
      :md-close-on-select="true"
      md-direction="top-end"
      md-size="auto"
    >
      <strong style="color: white">{{ userDisplayName }}</strong>
      <md-button class="md-icon-button" md-menu-trigger>
        <md-icon>person</md-icon>
      </md-button>
      <md-menu-content class="menuContent">
        <div class="author-card">
          <div class="author-card-info">
            <span v-if="!userName">Welcome</span>
            <div v-if="userName" id="menuLogo">
              <img src="/assets/image/logoAugmeditWhite.svg" />
            </div>
            <span>
              <strong>{{ userDisplayName }}</strong>
            </span>
            <span>{{ userName }}</span>
            <md-button v-if="userName" class="md-dense" @click="signOut">
              Sign Out
            </md-button>
            <md-button class="md-dense" @click="showAbout = true">
              About </md-button
            ><md-button class="md-dense" @click="openUserGuide()">
              User Guide </md-button
            ><md-button
              class="md-dense"
              @click="resetData(), fillUrl(), (showUserFeedback = true)"
            >
              Report Feedback
            </md-button>
            <md-button
              v-if="allowedFeatureToggles.includes('audit_reader') || isAdmin"
              class="md-dense"
              ><router-link to="/admin/audit"
                >Audit Data</router-link
              ></md-button
            >
          </div>
        </div>
      </md-menu-content>
    </md-menu>
    <md-dialog :md-active.sync="showAbout">
      <md-dialog-title>About</md-dialog-title>
      <md-dialog-content class="aboutPopup">
        <div class="aboutContainer">
          <div class="info">
            <div class="aboutEntry">
              <img class="icon" src="/assets/icon/Regulatory_Address.svg" />
              <div class="aboutText">
                <span>Manufacturer:</span> <span>Augmedit B.V.</span>
                <span>Galerij 15</span> <span>1411 LH Naarden</span>
                <span>The Netherlands</span> <span>Tel. +31 617 1010 94</span>
                <span>support@augmedit.com</span>
                <span>www.augmedit.com</span>
              </div>
            </div>
            <div class="aboutEntry">
              <img class="icon" src="/assets/icon/Regulatory_Releasedate.svg" />
              <div class="aboutText">
                <span>Date of manufacture:</span>
                <span>{{ versions.ReleaseDate }}</span>
              </div>
            </div>
          </div>
          <div class="aboutEntry">
            <div class="iconContainer">
              <img src="/assets/icon/Regulatory_MDIcon.svg" />
              <img src="/assets/icon/Regulatory_CE.svg" />
            </div>
            <div class="aboutText, descriptionText">
              <span
                >Lumi software is a Class I medical device in the European Union
                complying with the applicable requirements of the Medical Device
                Directive (93/42/EEC)</span
              >
            </div>
          </div>
          <div class="aboutEntry">
            <img
              class="icon"
              src="/assets/icon/Regulatory_InstructionsForUser.svg"
            />
            <div class="aboutText">
              <span>Consult the Instructions for Use</span>
            </div>
          </div>
          <div class="aboutEntry">
            <div class="iconContainer">
              <img
                class="icon"
                src="/assets/icon/Regulatory_Version_nopadding.svg"
              />
            </div>
            <div class="aboutText">
              <span>Software version number:</span>
              <span class="mainVersion">
                {{ versions.ProductVersion }}
              </span>
              <br />
              <span>System version numbers:</span>
              <span v-for="item in relevantVersions" :key="item.SystemID">
                {{ item.SystemName }}: {{ item.version }}
              </span>
            </div>
          </div>
        </div>
      </md-dialog-content>
      <md-dialog-actions>
        <md-button class="md-primary md-raised" @click="showAbout = false"
          >OK</md-button
        >
      </md-dialog-actions> </md-dialog
    ><md-dialog :md-active.sync="showUserFeedback">
      <md-dialog-title>Report Feedback</md-dialog-title>
      <md-dialog-content class="feedbackPopup">
        <md-icon class="md-size-2x bugIcon">edit_note</md-icon>
        <form>
          <label class="formLabel">Title *</label>
          <input
            v-model="reportTitle"
            class="reportInput"
            :class="{ invalidInput: titleFaulty }"
            maxlength="256"
            placeholder="Title of the feedback"
            required
            @input="resetTitleBorder()"
          />
          <label class="formLabel">URL of Patient or 3D model</label>
          <input
            v-model="assetUrl"
            class="reportInput"
            :class="{ invalidInput: urlFaulty }"
            maxlength="1024"
            placeholder="URL of Patient or 3D model"
            required
            @input="resetUrlBorder()"
          />
          <label class="formLabel">Description *</label>
          <textarea
            v-model="reportDescription"
            class="reportInput"
            :class="{ invalidInput: descriptionFaulty }"
            maxlength="1024"
            placeholder="Description"
            required
            rows="10"
            @input="resetDescriptionBorder()"
          >
          </textarea>
          <div class="requiredFieldHint">* Fields are required</div>
          <div>&nbsp;</div>
          <div class="radioContainer">
            <label class="formLabel"
              >Do you want to stay informed about the progress?</label
            >
            <md-radio
              v-model="userUpdateRequestedString"
              class="md-primary"
              value="Yes"
              >Yes</md-radio
            >
            <md-radio
              v-model="userUpdateRequestedString"
              class="md-primary"
              value="No"
              >No</md-radio
            >
          </div>
        </form>
      </md-dialog-content>
      <md-dialog-actions>
        <md-button class="md-primary md-raised" @click="submitUserFeedback()"
          >Send</md-button
        ><md-button class="md-primary" @click="showUserFeedback = false"
          >Cancel</md-button
        >
      </md-dialog-actions>
    </md-dialog>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import appSettings from "./appSettings";
import detectOsAndBrowser from "./osandbrowserdetectutil.js";
import CountryVerification from "@/classes/CountryVerification";

export default {
  name: "AppUserProfile",
  data: () => ({
    showAbout: false,
    showUserFeedback: false,
    titleFaulty: false,
    urlFaulty: false,
    descriptionFaulty: false,
    reportTitle: null,
    assetUrl: null,
    reportDescription: null,
    userUpdateRequestedString: "No",
    userUpdateRequested: false,
    versions: {
      ProductName: "",
      ProductVersion: "",
      systems: [
        {
          SystemName: null,
          SystemID: null,
          type: null,
          version: null,
          branch: null,
          build: null,
          showInfo: false,
        },
      ],
    },
  }),
  computed: {
    ...mapState([
      "user",
      "idToken",
      "selectedGroup",
      "selectedAsset",
      "allowedFeatureToggles",
    ]),
    ...mapGetters(["lastBreadcrumb", "isHome", "isAdmin"]),
    userName() {
      return this.user ? this.user.userName : "";
    },
    userDisplayName() {
      return this.user ? this.user.displayName : "";
    },
    lumiViewWebVersion() {
      return {
        SystemName: "Lumi.Web",
        type: "app",
        version: appSettings.siteversion,
      };
    },
    relevantVersions() {
      let versions = this.versions.systems.filter((version) => {
        return version.showInfo === "true";
      });
      versions.push(this.lumiViewWebVersion);
      return versions.sort((a, b) => {
        return a.SystemName > b.SystemName ? 1 : -1;
      });
    },
  },
  watch: {
    userUpdateRequestedString(newValue) {
      this.userUpdateRequested = newValue == "Yes" ? true : false;
    },
  },
  async created() {
    await this.getVersions();
  },
  methods: {
    ...mapActions(["fetchAzure", "postAzure", "signOut"]),
    async lumiWebGLVersion() {
      const buildnr = await fetch("Modules/buildnr.txt");
      return {
        SystemName: "Lumi.WebGL",
        type: "app",
        version: (await buildnr.text()).trim(),
        showInfo: "true",
      };
    },
    async getVersions() {
      this.versions = await this.fetchAzure(
        `${appSettings.remoteurl}/api/version`
      );
      if (CountryVerification.getEnvironment() === "test") {
        this.versions.systems.push(await this.lumiWebGLVersion());
      }
    },
    async submitUserFeedback() {
      if (
        !this.validateForm(
          this.reportTitle,
          this.assetUrl,
          this.reportDescription
        )
      )
        return;

      this.showUserFeedback = false;
      var osAndBrowserInfo = await detectOsAndBrowser();

      const userFeedback = {
        title: this.reportTitle,
        description: this.reportDescription,
        contextUrl: this.assetUrl,
        osAndVersion: osAndBrowserInfo.os,
        browserAndVersion: osAndBrowserInfo.browser,
        userUpdateRequested: this.userUpdateRequested,
      };
      await this.postAzure({
        url: `${appSettings.remoteurl}/api/userfeedback`,
        metadata: userFeedback,
      });
    },
    validateForm(title, url, desc) {
      let isValid = true;
      if (!title || !desc) {
        if (!title) this.titleFaulty = true;
        if (!desc) this.descriptionFaulty = true;
        isValid = false;
      }
      if (url) {
        try {
          new URL(url);
        } catch {
          this.urlFaulty = true;
          isValid = false;
        }
      }
      if (
        (title && title.length > 256) ||
        (url && url.length > 1024) ||
        (desc && desc.length > 1024)
      ) {
        alert("Too many characters in the field !");
        isValid = false;
      }

      return isValid;
    },
    resetTitleBorder() {
      this.titleFaulty = false;
    },
    resetUrlBorder() {
      this.urlFaulty = false;
    },
    resetDescriptionBorder() {
      this.descriptionFaulty = false;
    },
    fillUrl() {
      if (this.isHome) return;

      this.assetUrl = `https://${appSettings.host}/${this.selectedGroup.id}/${this.lastBreadcrumb.asset.id}`;
    },
    resetData() {
      this.reportTitle = null;
      this.assetUrl = null;
      this.reportDescription = null;
      this.userUpdateRequestedString = "No";
      this.userUpdateRequested = false;
      this.titleFaulty = false;
      this.urlFaulty = false;
      this.descriptionFaulty = false;
    },
    addWebGLVersionToList(versionNumber) {
      var index = this.versions.systems.indexOf(
        this.versions.systems.find((x) => x.SystemName === "Lumi.WebGL")
      );
      this.versions.systems.splice(index, 1);

      this.versions.systems.push({
        SystemName: "Lumi.WebGL",
        type: "app",
        showInfo: "true",
        version: versionNumber,
      });
    },
    openUserGuide() {
      const environment = CountryVerification.getEnvironment();
      const url = `${appSettings.userguideurl}?environment=${environment}&access_token=${this.idToken}`;
      window.open(url).focus();
    },
  },
};
</script>
<style scoped>
#menuLogo {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 12px;
  margin-bottom: 12px;
}
#menuLogo img {
  width: 50px;
}
.reportInput {
  background-color: #2d6b7d;
  border-radius: 12px;
  border: 0;
  color: white;
  padding: 10px;
  font-size: 16px;
  width: 100%;
  resize: none;
}
.reportInput:focus {
  outline: none;
}
::placeholder {
  color: #d0d0d0;
}
form {
  margin: 20px;
}
form .reportInput {
  margin-top: 6px;
  margin-bottom: 12px;
}
form .unusableCheck {
  float: left;
  width: 60px;
  transform: scale(1.25);
}
.formLabel {
  float: left;
}
.inputLabel {
  float: left;
  font-size: 16px;
}
.bugIcon {
  position: absolute;
  left: 50%;
  top: 12px;
  transform: translateX(-50%);
}
.reportPopup {
  display: flex;
  width: 800px;
  height: 700px;
}
.feedbackPopup {
  display: flex;
  width: 800px;
  height: 564px;
  position: relative;
}
.feedbackPopup form {
  position: absolute;
  padding-left: 24px;
  padding-right: 24px;
  bottom: 0;
}
.menu {
  line-height: 40px;
  margin-top: 3px;
}
.menuContent {
  margin-top: 50px;
  border-radius: 20px;
  width: 100%;
}

.requiredFieldHint {
  display: flex;
}

.radioContainer {
  display: flex;
}

.radioContainer .md-radio {
  --md-theme-default-primary: white;
  margin: 0 12px 0 24px;
}
.invalidInput {
  border: 2px solid red;
}

/*This section contains the styling for the about PopUp*/
.aboutPopup {
  width: 600px;
  height: 700px;
}

span {
  display: block;
  text-align: left;
  font-size: 16px;
  line-height: 24px;
}

.md-dialog-title {
  text-align: center;
}

.aboutContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.aboutEntry {
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: row;
  gap: 2em;
}
.icon {
  margin: 0;
}
.iconContainer {
  height: 100%;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.iconContainer img {
  padding-top: 0.3em;
  display: block;
  margin: 0;
}
.descriptionText {
  width: 60%;
}
.info {
  display: flex;
  flex-direction: row;
}
.aboutText {
  margin: 0;
}
</style>
