export default class CountryVerification {
  static isInCorrectCountry(countryCode) {
    const environment = this.getEnvironment();
    countryCode = countryCode?.toLowerCase() || "";
    return (
      this.isNonProduction(environment) ||
      countryCode === environment ||
      (countryCode === "" && environment == "prod")
    );
  }

  static getEnvironment() {
    const hostname = window.location.hostname.toLowerCase();
    let domainSplit = hostname.split(".");
    let domainStart = domainSplit[0];
    return domainStart === "www" ? "prod" : domainStart;
  }

  static isNonProduction(environment) {
    if (!environment) environment = this.getEnvironment();
    return (
      environment === "lab" ||
      environment === "test" ||
      environment === "localhost"
    );
  }

  static getUrlForRegion(countryCode) {
    if (!countryCode) countryCode = "www";
    const domainName = [countryCode.toLowerCase(), "lumi.cloud"]
      .filter(Boolean)
      .join(".");
    return window.location.protocol + "//" + domainName;
  }
}
