<template id="app-asset-content">
  <div class="containerContent">
    <app-asset-hologram v-if="showUnity" htmlpage="modulesViewer.html">
    </app-asset-hologram>
    <div v-else class="noViewer">You can edit the patient information here</div>
    <div class="actionBar">
      <div class="authorItem">
        <div class="title">Author</div>
        <div class="small">{{ author }}</div>
        <div v-if="isAssetOwner" class="small">
          <strong>owner</strong>
        </div>
      </div>
      <div style="flex: 1">&nbsp;</div>
      <div
        v-if="assetType === 'hologram'"
        class="contentIcon"
        :class="{
          'is-active-button': isHologramDirty,
        }"
        @click="saveHologram"
      >
        <div><i class="fa fa-save"></i></div>
        <div>
          <div class="title">Save</div>
          <div class="small">Save Hologram</div>
        </div>
      </div>
      <div v-if="showUnity" class="contentIcon" @click="toggleIsMaximized">
        <div><i class="fa fa-window-maximize"></i></div>
        <div v-if="isMaximized">
          <div class="title">Details</div>
          <div class="small">Normalize viewer</div>
        </div>
        <div v-else>
          <div class="title">Maximize</div>
          <div class="small">Maximize viewer</div>
        </div>
      </div>
      <div v-if="folder" class="contentIcon" @click="selectFolder">
        <div><i class="fa fa-arrow-right"></i></div>
        <div>
          <div class="title">Sub-items</div>
          <div class="small">Go to items in this folder</div>
        </div>
      </div>
      <div
        v-if="assetType === 'hologram' && isShareAllowed"
        class="contentIcon"
        @click="startSharing()"
      >
        <div><i class="fa fa-share"></i></div>
        <md-menu md-direction="top-start" md-size="big">
          <div md-menu-trigger>
            <div class="title">Share</div>
            <div class="small">Share Hologram</div>
          </div>
        </md-menu>
      </div>
      <div
        v-if="assetType === 'hologram'"
        class="contentIcon"
        @click="showHoloQR = true"
      >
        <div><i class="fa fa-qrcode"></i></div>
        <md-menu md-direction="top-start" md-size="big">
          <div md-menu-trigger>
            <div class="title">Hololens</div>
            <div class="small">View in Augmented Reality</div>
          </div>
        </md-menu>
      </div>
      <div
        class="contentIcon"
        :class="{ readonly: isReadOnlyGroup }"
        @click="showDeleteConfirm = true"
      >
        <div><i class="fa fa-trash"></i></div>
        <div>
          <div class="title">Delete</div>
          <div class="small">Delete {{ assetType }}</div>
        </div>
      </div>
      <div
        v-if="showUnity && isLookingGlassAllowed"
        class="contentIcon"
        :class="{
          'is-active-button': isLookingGlassEnabled.value,
        }"
        @click="hitLookingGlassUrl()"
        @contextmenu="openLookingGlassMenu($event)"
      >
        <div><i class="fa fa-eye"></i></div>
        <div>
          <div class="title">Looking Glass</div>
          <div class="small">Open Looking Glass</div>
        </div>
      </div>
      <div
        v-if="showUnity && isMizarAllowed"
        class="contentIcon"
        @click="hitMizarUrl()"
        @contextmenu="openMizarMenu($event)"
      >
        <div><i class="fa fa-desktop"></i></div>
        <div>
          <div class="title">Mizar</div>
          <div class="small">Show on Mizar</div>
        </div>
      </div>
      <div
        v-if="assetType === 'study' || assetType === 'Person'"
        class="contentIcon"
        :class="{ readonly: isReadOnlyGroup }"
        @click="setAssetToCopy(selectedAsset)"
      >
        <div><i class="fa fa-share"></i></div>
        <div>
          <div class="title">Move/Copy</div>
          <div v-if="assetType === 'study'" class="small">
            Move or copy study
          </div>
          <div v-if="assetType === 'person'" class="small">
            Move or copy studies of person
          </div>
        </div>
      </div>
      <div
        v-if="selectedAssetWorkflowState.length > 0"
        class="contentIcon"
        @click="showWorkflowState = true"
      >
        <div v-if="hasLastWorkflowState('InProgress')">
          <i class="fa fa-spinner"></i>
        </div>
        <div v-else-if="hasLastWorkflowState('Failed')">
          <i class="fa fa-exclamation-circle"></i>
        </div>
        <div v-else>
          <i class="fa fa-bell"></i>
        </div>
      </div>
    </div>

    <app-share-dialog
      v-if="showShareDialog"
      :shared-resource="sharedResource"
      @share-dialog-closed="showShareDialog = false"
    >
    </app-share-dialog>

    <md-dialog :md-active.sync="showDeleteConfirm">
      <md-dialog-title>Delete this {{ assetType }}?</md-dialog-title>
      <md-dialog-content>
        <div class="dialogContent">
          <div>{{ deleteMessage }}</div>
          <div class="spacer"></div>
          <div>Are you sure?</div>
        </div>
      </md-dialog-content>
      <md-dialog-actions>
        <md-button class="md-primary md-raised" @click="deleteAsset"
          >Delete</md-button
        >
        <md-button class="md-primary" @click="showDeleteConfirm = false"
          >Cancel</md-button
        >
      </md-dialog-actions>
    </md-dialog>
    <md-dialog :md-active.sync="showHoloQR">
      <md-dialog-title>View in Augmented Reality</md-dialog-title>
      <div class="holoQR">
        <qr-code
          class="QRContainer"
          error-level="L"
          :size="qrHeight"
          :text="qrUrl"
        ></qr-code>
      </div>
      <md-dialog-actions>
        <md-button class="md-primary" @click="showHoloQR = false"
          >Close</md-button
        >
      </md-dialog-actions>
    </md-dialog>
    <md-dialog :md-active.sync="showWorkflowState">
      <md-dialog-title>Progress</md-dialog-title>
      <div class="jsonContent">
        <md-list>
          <template v-for="entry in sortedWorkflowStates">
            <md-list-item :key="entry.id" style="margin-top: 2px">
              <div class="workflowItem">
                <div class="workflowHeader">
                  {{ entry.function }}: {{ entry.displayName }}
                </div>
                <div v-if="entry.percentageCompleted">
                  Percentage Completed:
                  {{ entry.percentageCompleted }}
                </div>
                <div v-if="entry.errorMessage">
                  Error: {{ entry.errorMessage }}
                </div>
                <div v-if="entry.attributes">
                  <div
                    v-for="attributeEntry in Object.entries(entry.attributes)"
                    :key="attributeEntry[0]"
                  >
                    {{ attributeEntry[0] }}:
                    {{ attributeEntry[1] }}
                  </div>
                </div>
                <div v-if="entry.state !== entry.displayName">
                  State: {{ entry.state }}
                </div>
                <div>Created: {{ formatDateTime(entry.created) }}</div>
                <div>Last Update: {{ formatDateTime(entry.modified) }}</div>
                <div v-if="entry.state === 'InProgress'">
                  Started
                  {{ formatDuration(toUtcDate(entry.created), now) }} ago
                </div>
                <div v-else>
                  Completed in
                  {{
                    formatDuration(
                      toUtcDate(entry.created),
                      toUtcDate(entry.modified)
                    )
                  }}
                </div>
              </div>
            </md-list-item>
          </template>
        </md-list>
      </div>
      <md-dialog-actions>
        <md-button class="md-primary" @click="showWorkflowState = false"
          >Close</md-button
        >
      </md-dialog-actions>
    </md-dialog>
    <context-menu ref="mizarMenu">
      <div>
        <div @click="copyToClipboard(getMizarUrl())">Copy url</div>
      </div>
    </context-menu>
    <context-menu ref="lookingGlassMenu">
      <div>
        <div @click="copyToClipboard(getLookingGlassUrl())">Copy url</div>
      </div>
    </context-menu>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
import appSettings from "./appSettings";
import moment from "moment";
import AppAssetHologram from "./appAssetHologram";
import AppShareDialog from "./appShareDialog";
import featureToggles from "../enums/FeatureToggles.js";
import contextMenu from "./contextMenu";
import { bus } from "../eventBus";

export default {
  name: "AppAssetContent",
  components: {
    "app-asset-hologram": AppAssetHologram,
    "app-share-dialog": AppShareDialog,
    contextMenu,
  },
  data: () => ({
    featureToggles: featureToggles,
    showDeleteConfirm: false,
    showHoloQR: false,
    showShareDialog: null,
    sharedResource: null,
    showWorkflowState: false,
    isHologramDirty: false,
    isLookingGlassEnabled: { value: false },
    now: Date.now(),
  }),
  computed: {
    ...mapState([
      "idToken",
      "selectedGroup",
      "selectedAsset",
      "user",
      "isMaximized",
      "selectedAssetWorkflowState",
      "allowedFeatureToggles",
      "breadcrumbs",
    ]),
    ...mapGetters(["isReadOnlyGroup", "isAssetOwner", "showUnity"]),
    isShareAllowed() {
      return this.isFeatureAllowed(this.featureToggles.SHARE_MOBILE);
    },
    isLookingGlassAllowed() {
      return this.isFeatureAllowed(this.featureToggles.LOOKING_GLASS);
    },
    isMizarAllowed() {
      return this.isFeatureAllowed(this.featureToggles.MIZAR);
    },
    qrHeight() {
      let height = (window.outerWidth / window.innerWidth) * window.innerHeight;
      return (height - 260) * 0.8;
    },
    qrUrl() {
      if (this.getAssetType() !== "hologram") return null;

      const resource = this.getSharedResource();
      return `https://${appSettings.host}/${this.selectedGroup.id}/${this.selectedAsset.id}?resource=${resource}`;
    },
    author() {
      return this.selectedAsset ? this.selectedAsset.metadata._author : null;
    },
    folder() {
      return this.selectedAsset ? this.selectedAsset.metadata._folder : null;
    },
    assetType() {
      return this.getAssetType();
    },
    deleteMessage() {
      if (!this.selectedAsset) return "";

      let type = this.selectedAsset.metadata._type;
      if (type === "Person")
        return "All scans and holograms associated to this person will be removed.";
      if (type === "DICOMStudy")
        return "All holograms associated to this study will be removed.";
      if (type === "Set")
        return "All information associated to this hologram will be removed.";
      const lowerCasedType = this.lowercaseFirstCharacter(this.getAssetType());
      return `All information associated to this ${lowerCasedType} will be removed.`;
    },
    sortedWorkflowStates() {
      var sortedWorkflowStates = [...this.selectedAssetWorkflowState];
      sortedWorkflowStates.sort((a, b) => a.created < b.created);
      return sortedWorkflowStates;
    },
    lastWorkflowStatePerFunction() {
      var filteredWorkflowStates = [];
      this.sortedWorkflowStates
        .filter((entry) => entry.state !== "Skipped")
        .forEach((x) => {
          if (!filteredWorkflowStates.find((y) => x.function === y.function)) {
            filteredWorkflowStates.push(x);
          }
        });
      return filteredWorkflowStates;
    },
  },
  created() {
    bus.$on("onSaveStateChanged", (event) => {
      this.isHologramDirty = event.isDirty > 0;
    });
    bus.$on(
      "variable.add.UserViewModel.EnableLookingGlass",
      (eventVariable) => {
        this.isLookingGlassEnabled = eventVariable;
      }
    );
  },
  mounted() {
    setInterval(() => {
      this.now = Date.now();
    }, 30000);
  },
  methods: {
    ...mapMutations([
      "setSelectedAsset",
      "setIsMaximized",
      "setAssetToCopy",
      "setErrorMessage",
      "updateFormatInStore",
      "setStatusMessage",
    ]),
    ...mapActions(["postAzure", "updateAssetMetadata"]),
    isFeatureAllowed(feature) {
      return this.allowedFeatureToggles.includes(feature);
    },
    openMizarMenu(e) {
      this.$refs.mizarMenu.open(e);
      e.preventDefault();
    },
    openLookingGlassMenu(e) {
      this.$refs.lookingGlassMenu.open(e);
      e.preventDefault();
    },
    copyToClipboard(text) {
      let localThis = this;
      navigator.clipboard.writeText(text).then(
        function () {
          localThis.copiedToClipboard = true;
        },
        function (err) {
          localThis.setErrorMessage("Error while copying: " + err);
        }
      );
    },
    hitLookingGlassUrl() {
      this.isLookingGlassEnabled.value = !this.isLookingGlassEnabled.value;
      if (this.isLookingGlassEnabled.value) {
        window.open(this.getLookingGlassUrl(), "_blank");
      }
    },
    getLookingGlassUrl() {
      return (
        `lumilook://${appSettings.host}?groupId=${this.selectedGroup.id}&assetId=${this.selectedAsset.id}&` +
        `access_token=${this.idToken}`
      );
    },
    hitMizarUrl() {
      window.open(this.getMizarUrl(), "_blank");
    },
    getMizarUrl() {
      return (
        `lumimizar://${appSettings.host}?groupId=${this.selectedGroup.id}&assetId=${this.selectedAsset.id}&` +
        `access_token=${this.idToken}`
      );
    },
    hasLastWorkflowState(state) {
      return this.lastWorkflowStatePerFunction.some(
        (elem) => elem.state === state
      );
    },
    getAssetType() {
      if (!this.selectedAsset) return "";

      let type = this.selectedAsset.metadata._type;
      if (type === "DICOMStudy") return "study";
      if (type === "Set") return "hologram";
      return type || "asset";
    },
    lowercaseFirstCharacter(str) {
      return str.charAt(0).toLowerCase() + str.slice(1);
    },
    deleteAsset() {
      let type = this.selectedAsset.metadata._type;
      let deleteType =
        type === "Person" ? "patient" : type === "Set" ? "hologram" : "asset";
      this.$emit(`delete${deleteType}`);
      this.showDeleteConfirm = false;
    },
    saveHologram() {
      if (this.isHologramDirty) {
        bus.$emit("unitySaveHologram");
        setTimeout(() => {
          if (!this.isHologramDirty) {
            this.setStatusMessage("Changes saved");
          }
        }, 1000); // Wait for saving changes applied with max 1s waiting time.
      } else {
        this.setStatusMessage("Already up-to-date");
      }
    },
    toggleIsMaximized() {
      this.setIsMaximized(!this.isMaximized);
    },
    selectFolder() {
      this.setSelectedAsset(null);
    },
    toUtcDate(dateString) {
      return moment.utc(dateString).toDate();
    },
    formatDateTime(dateTime) {
      return (
        dateTime && moment(this.toUtcDate(dateTime)).format("YYYY-MM-DD HH:mm")
      );
    },
    formatDuration(date1, date2) {
      const ms = Math.abs(date1 - date2);
      return moment.duration(ms).humanize();
    },
    async startSharing() {
      var resource = this.getSharedResource();
      if (!resource) return;

      await this.lockResource(resource);

      this.sharedResource = resource;
      this.showShareDialog = true;
    },
    async lockResource(resource) {
      const groupsPart = resource.substr(0, resource.indexOf("/patients"));
      const hologramRevisionPart = resource.substr(
        resource.indexOf("/holograms")
      );
      const url = `${appSettings.remoteurl}/api${groupsPart}${hologramRevisionPart}/lock`;
      await this.postAzure({ url, responseType: "text" });

      this.updateFormatInStore({
        id: resource.substr(resource.lastIndexOf("/") + 1),
        locked: true,
        metadata: {},
      });
    },
    getSharedResource() {
      if (!this.selectedAsset.formats) return null;

      const setFormats = this.selectedAsset.formats.filter(
        (x) => x.metadata._type === "SET"
      );
      if (setFormats.length === 0) {
        this.setErrorMessage("no hologram revisions found");
        return null;
      }
      const lastSetFormat = setFormats[setFormats.length - 1];
      const patientId = this.getPatientId();
      return `/groups/${this.selectedGroup.id}/patients/${patientId}/holograms/${this.selectedAsset.id}/revisions/${lastSetFormat.id}`;
    },
    getPatientId() {
      const breadcrumbs = this.breadcrumbs;
      if (breadcrumbs.length < 2)
        throw "no patient selected, sharing not possible";
      return breadcrumbs[1].asset.id;
    },
  },
};
</script>

<style lang="scss" scoped>
.workflowItem {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  align-items: left;
  font-size: 12px;
  white-space: normal;
}
.workflowItem * {
  overflow-wrap: anywhere;
}
.workflowHeader {
  font-size: 14px;
}

.dialogContent {
  display: flex;
  flex-direction: column;
}
.spacer {
  height: 20px;
}
.jsonContent {
  width: 420px;
  height: 800px;
  border-radius: 5px;
  background-color: var(--accent-color-light);
  overflow: auto;
}
.noViewer {
  color: white;
  background-color: black;
  font-size: 28px;
  height: 80vh;
  margin: 20px;
  align-items: center;
  display: flex;
  justify-content: center;
  align-content: center;
}
.buttonIcon {
  margin-left: 4px;
  margin-right: 4px;
}
.holoQR {
  background-color: #53a4bd;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  .QRContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 16px;
    border: white 24px solid;
    border-radius: 8px;
  }
}
.containerContent {
  display: flex;
  flex-flow: column;
  overflow: hidden;
}
.actionBar {
  display: flex;
  justify-content: flex-end;
}
.actionBar div {
  margin-right: 12px;
}
.contentIcon {
  user-select: none;
  display: flex;
  width: 200px;
  flex-direction: row;
  align-items: center;
  padding-top: 24px;
  padding-bottom: 24px;
  padding-left: 15px;
  cursor: pointer;
}
.authorItem {
  margin-left: 25px;
  padding-top: 24px;
  padding-bottom: 24px;
}
.h1 {
  font-size: 20px;
  margin: 0px;
}
.paddingtop {
  padding-top: 12px;
}
hr {
  margin: 0px;
}
.small {
  font-size: 12px;
  color: white;
  line-height: 12px;
}
.title {
  font-size: 18px;
  margin-bottom: 5px;
  color: white;
}
.readonly {
  opacity: 0.4;
  pointer-events: none;
}

.is-active-button {
  background-color: #3a8ca5;
  border-radius: 12px;
}
</style>
