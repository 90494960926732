import Vue from "vue";
import Vuex from "vuex";
import appSettings from "../components/appSettings";
import moment from "moment";
import { PublicClientApplication } from "@azure/msal-browser";
import LumiApi from "../classes/LumiApi";

Vue.use(Vuex);

function _cloneAsset(asset) {
  // to make reactive changes make a clone, otherwise changes will not be picked up:
  // you can watch for deep properties, but new ones will not be detected
  // Ok to clone using JSON serialization, as it is only a value class
  return JSON.parse(JSON.stringify(asset));
}

const homeLabel = "Home";
const homeBreadcrumb = {
  asset: { id: homeLabel, metadata: { displayName: homeLabel } },
  filter: "",
  sort: "_modified",
};

const lumiApi = new LumiApi();

export default new Vuex.Store({
  state: {
    idToken: null,
    tokenExpiry: null,
    breadcrumbs: [homeBreadcrumb],
    assetGroups: [],
    viewMode: "clinical",
    selectedGroup: null,
    assets: [],
    allowedFeatureToggles: [],
    workflowProgress: {},
    // watchers do not properly see changes in the workflowProgress object,
    // so use the modified to trigger them
    workflowProgressModified: "",
    selectedAsset: null,
    selectedAssetWorkflowState: [],
    searchMyPatients: false,
    typeFilter: "Person",
    statusMessage: "",
    errorMessage: "",
    serviceNotice: "",
    loading: false,
    loadingGroups: false,
    creating: false,
    assetToCopy: null,
    isMaximized: true,
    userId: null,
    user: null,
    countryCode: null,
  },
  getters: {
    isAdmin: (state) => {
      if (!state.selectedGroup) return false;

      let assetgroupPermission = state.selectedGroup.metadata._permission;
      return assetgroupPermission === "All";
    },
    isReadOnlyGroup: (state) => {
      if (!state.selectedGroup) return true;

      let assetgroupPermission = state.selectedGroup.metadata._permission;
      return assetgroupPermission !== "All" && assetgroupPermission !== "Write";
    },
    isAssetOwner: (state) => {
      if (!state.selectedAsset) return false;
      if (!state.user || !state.user.userId) return false;

      return state.user.userId === state.selectedAsset.metadata._owner;
    },
    inListMode: (state) => {
      return !state.selectedAsset;
    },
    showUnity: (state) => {
      if (!state.selectedAsset || !state.selectedAsset.metadata) return false;

      const type = state.selectedAsset.metadata._type;
      return (
        type === "Set" ||
        type === "Surface" ||
        type === "PointCloud" ||
        type === "Volume"
      );
    },
    lastBreadcrumb: (state) => {
      return state.breadcrumbs[state.breadcrumbs.length - 1];
    },
    filter: (state) => {
      return state.breadcrumbs[state.breadcrumbs.length - 1].filter;
    },
    sort: (state) => {
      return state.breadcrumbs[state.breadcrumbs.length - 1].sort;
    },
    isHome: (state) => {
      return state.breadcrumbs.length === 1;
    },
  },
  actions: {
    signOut({ commit, state }) {
      let msalClient = new PublicClientApplication(appSettings.msalConfig);
      msalClient.setActiveAccount(null);
      commit("setUser", null);
      commit("setIdToken", null);
      delete window.sessionStorage.accesstoken;
      delete window.localStorage["accesstoken"];
      window.sessionStorage.removeItem("newGroups");
      let user = state.user;
      if (user) {
        // Sign out using url to prevent popup with one account
        window.location.href =
          "https://login.microsoftonline.com/common/oauth2/logout?post_logout_redirect_uri=" +
          `${encodeURIComponent(appSettings.msalConfig.auth.redirectUri)}`;
      }
    },
    async fetchFeatureToggles({ commit, state }) {
      const url = `${appSettings.remoteurl}/api/allowedToggles/me`;
      const toggles = await lumiApi.executeApiRequest(
        commit,
        url,
        "GET",
        state.idToken
      );
      return toggles;
    },
    async fetchAppInsightsConnectionString({ commit, state }) {
      const url = `${appSettings.remoteurl}/api/logging/webtelemetrykey`;
      return await lumiApi.executeApiJsonRequest(
        commit,
        url,
        "GET",
        state.idToken
      );
    },
    async loadResources({ commit, state }, payload) {
      const url = `${appSettings.remoteurl}/api/${payload.resourceType}`;
      return await lumiApi.loadResources(commit, url, state.idToken);
    },
    async createResource({ commit, state }, payload) {
      const url = `${appSettings.remoteurl}/api/${payload.resourceType}`;
      return await lumiApi.executeApiJsonRequest(
        commit,
        url,
        "POST",
        state.idToken,
        payload.metadata
      );
    },
    async updateResource({ commit, state }, payload) {
      const url = `${appSettings.remoteurl}/api/${payload.resourceType}/${payload.id}`;
      await lumiApi.executeApiJsonRequest(
        commit,
        url,
        "PUT",
        state.idToken,
        payload.metadata,
        {
          "If-Match": '"' + payload.metadata._version + '"',
        }
      );
    },
    async deleteResource({ commit, state }, payload) {
      const url = `${appSettings.remoteurl}/api/${payload.resourceType}/${payload.id}`;
      return await lumiApi.deleteResource(
        commit,
        url,
        state.idToken,
        payload.errorHandler,
        payload.successHandler
      );
    },
    async loadAsset({ commit, state }, payload) {
      const detailsDirective = payload.includeDetails
        ? "?_include=formats,parents"
        : "";
      const url = `${appSettings.remoteurl}/api/groups/${payload.groupId}/assets/${payload.assetId}${detailsDirective}`;
      return await lumiApi.executeApiRequest(commit, url, "GET", state.idToken);
    },
    async loadGroups({ commit, state }) {
      const url = `${appSettings.remoteurl}/api/groups`;
      const groups = await lumiApi.loadResources(commit, url, state.idToken);
      window.sessionStorage.setItem("newGroups", JSON.stringify(groups));
      commit("setGroups", groups);
    },
    async updateSelectedAssetWorkflowState({ commit, state }, payload) {
      const url = `${appSettings.remoteurl}/api/progress?assetId=${payload.assetId}`;
      commit(
        "setSelectedAssetWorkflowState",
        await lumiApi.executeApiRequest(commit, url, "GET", state.idToken)
      );
    },
    async updateSelectedGroupWorkflowState({ commit, state }) {
      if (!state.selectedGroup) return;

      const url = `${appSettings.remoteurl}/api/progress?groupId=${
        state.selectedGroup.id
      }&modified=eq${moment(new Date()).format("yyyy-MM-DD")}`;
      let groupProgress = await lumiApi.executeApiRequest(
        commit,
        url,
        "GET",
        state.idToken
      );
      commit("setGroupProgress", groupProgress);
    },
    async updateAssetMetadata({ commit, state }, payload) {
      const asset = payload.asset;
      let url = `${appSettings.remoteurl}/api/groups/${state.selectedGroup.id}/assets/${asset.id}`;
      let version = asset.metadata._version;
      let updatedAsset = await lumiApi.executeApiJsonRequest(
        commit,
        url,
        "PUT",
        state.idToken,
        payload.metadata,
        {
          "If-Match": '"' + version + '"',
        }
      );
      if (state.selectedAsset) {
        commit("setSelectedAsset", updatedAsset);
      }
    },
    async updateFormatMetadata({ commit, state }, payload) {
      let url = `${appSettings.remoteurl}/api/groups/${state.selectedGroup.id}/assets/${state.selectedAsset.id}/formats/${payload.format.id}`;
      let version = payload.format.metadata._version;
      await lumiApi.executeApiJsonRequest(
        commit,
        url,
        "PUT",
        state.idToken,
        payload.metadata,
        {
          "If-Match": '"' + version + '"',
        }
      );
    },
    async deleteAzure({ commit, state }, payload) {
      return await lumiApi.deleteResource(
        commit,
        payload.url,
        state.idToken,
        payload.errorHandler,
        payload.successHandler
      );
    },
    async fetchAzure({ commit, state }, url) {
      return await lumiApi.executeApiRequest(commit, url, "GET", state.idToken);
    },
    async getAzure({ commit, state }, payload) {
      return await lumiApi.executeApiRequest(
        commit,
        payload.url,
        "GET",
        state.idToken,
        undefined,
        {},
        payload.responseType
      );
    },
    async postAzureForm({ commit, state }, payload) {
      return await lumiApi.executeApiRequest(
        commit,
        payload.url,
        "POST",
        state.idToken,
        payload.formData,
        {
          "augmedit-partial-upload": payload.isPartialUpload,
        },
        payload.responseType || "json"
      );
    },
    async postAzure({ commit, state }, payload) {
      return await lumiApi.executeApiJsonRequest(
        commit,
        payload.url,
        "POST",
        state.idToken,
        payload.metadata,
        {},
        payload.responseType,
        payload.errorHandler,
        payload.successHandler
      );
    },
    async putAzure({ commit, state }, payload) {
      const headers = payload.ifMatchValue
        ? {
            "If-Match": '"' + payload.ifMatchValue + '"',
          }
        : null;
      return await lumiApi.executeApiJsonRequest(
        commit,
        payload.url,
        "PUT",
        state.idToken,
        payload.metadata,
        headers,
        payload.responseType,
        payload.errorHandler,
        payload.successHandler
      );
    },
    async createFormat({ commit, state }, payload) {
      let url = `${appSettings.remoteurl}/api/groups/${state.selectedGroup.id}/assets/${payload.assetId}/formats`;
      return await lumiApi.executeApiJsonRequest(
        commit,
        url,
        "POST",
        state.idToken,
        payload.metadata || {}
      );
    },
    async createDelegationCredentials({ commit, state }, resource) {
      let url = `${appSettings.remoteurl}/api/token/delegation${resource}`;
      return await lumiApi.executeApiRequest(commit, url, "GET", state.idToken);
    },
  },
  mutations: {
    goHome(state) {
      state.breadcrumbs = [homeBreadcrumb];
      state.selectedAsset = null;
    },
    setHomeBreadcrumb(state) {
      state.breadcrumbs = [homeBreadcrumb];
    },
    pushBreadcrumb(state, asset) {
      state.breadcrumbs.push({
        asset: asset,
        filter: "",
        sort: "_modified",
      });
      state.sort = "_modified";
      state.filter = "";
    },
    popBreadcrumb(state) {
      state.breadcrumbs.pop();
    },
    setViewMode(state, mode) {
      state.viewMode = mode;
    },
    updateProgress(state, payload) {
      for (let assetId of payload.assetIds) {
        state.workflowProgress[assetId] = payload.percentageCompleted;
      }
      state.workflowProgressModified = Date.now();
    },
    setGroupProgress(state, groupProgress) {
      let sorted = groupProgress.sort((a, b) => {
        return a.modified > b.modified ? 1 : -1;
      });
      let workflowProgress = {};
      for (let progress of sorted) {
        for (let assetId of progress.assetIds) {
          workflowProgress[assetId] = progress.percentageCompleted;
        }
      }
      state.workflowProgress = workflowProgress;
      state.workflowProgressModified = Date.now();
    },
    setBreadcrumbs(state, breadcrumbs) {
      state.breadcrumbs = breadcrumbs;
    },
    addGroup(state, assetGroup) {
      state.assetGroups.push(assetGroup);
    },
    setGroups(state, assetGroups) {
      state.assetGroups = assetGroups;
    },
    setAllowedFeatureToggles(state, allowedFeatureToggles) {
      state.allowedFeatureToggles = allowedFeatureToggles;
    },
    setSelectedGroup(state, selectedGroup) {
      const oldGroupName = state.selectedGroup ? state.selectedGroup.id : null;
      const newGroupName = selectedGroup ? selectedGroup.id : null;

      if (oldGroupName !== newGroupName) {
        state.selectedAsset = null;
      }
      state.selectedGroup = selectedGroup;
    },
    setAssets(state, assets) {
      state.assets = assets;
    },
    deleteAssetMetadataEntry(state, key) {
      let newAsset = _cloneAsset(state.selectedAsset);
      delete newAsset.metadata[key];
      state.selectedAsset = newAsset;
    },
    updateThumbnailInMetadata(state, url) {
      let newAsset = _cloneAsset(state.selectedAsset);
      newAsset.metadata.thumb = url;
      state.selectedAsset = newAsset;
    },
    updateFormatInStore(state, format) {
      const key = Object.keys(format.metadata)[0];
      const value = format.metadata[key];
      let newAsset = _cloneAsset(state.selectedAsset);
      newAsset.formats.forEach((x) => {
        if (x.id === format.id) {
          x.metadata[key] = value;
          x.metadata._version = x.metadata._version + 1;
          x.locked = format.locked;
          x.selected = format.selected ?? false;
        } else if (format.selected) {
          // If changing selected, change all other selected to false
          x.selected = false;
        }
      });
      state.selectedAsset = newAsset;
    },
    deleteAssetFormatFromState(state, formatId) {
      let newAsset = _cloneAsset(state.selectedAsset);
      newAsset.formats = newAsset.formats.filter((x) => x.id !== formatId);
      state.selectedAsset = newAsset;
    },
    setSelectedAsset(state, selectedAsset) {
      state.selectedAsset = selectedAsset;
    },
    setSelectedAssetWorkflowState(state, workflowState) {
      state.selectedAssetWorkflowState = workflowState;
    },
    setTypeFilter(state, typeFilter) {
      state.typeFilter = typeFilter;
    },
    setSearchMyPatients(state, searchMyPatients) {
      state.searchMyPatients = searchMyPatients;
    },
    setFilter(state, filter) {
      state.breadcrumbs[state.breadcrumbs.length - 1].filter = filter;
    },
    setSort(state, sort) {
      state.breadcrumbs[state.breadcrumbs.length - 1].sort = sort;
    },
    setStatusMessage(state, statusMessage) {
      state.statusMessage = statusMessage;
    },
    setErrorMessage(state, errorMessage) {
      state.errorMessage = errorMessage;
    },
    setServiceNotice(state, serviceNotice) {
      state.serviceNotice = serviceNotice;
    },
    setLoading(state, loading) {
      state.loading = loading;
    },
    setLoadingGroups(state, loadingGroups) {
      state.loadingGroups = loadingGroups;
    },
    setCreating(state, creating) {
      state.creating = creating;
    },
    setIdToken(state, idToken) {
      state.idToken = idToken;
    },
    setTokenExpiry(state, tokenExpiry) {
      state.tokenExpiry = tokenExpiry;
    },
    setAssetToCopy(state, assetToCopy) {
      state.assetToCopy = assetToCopy;
    },
    setIsMaximized(state, isMaximized) {
      state.isMaximized = isMaximized;
    },
    setUserId(state, userId) {
      state.userId = userId;
    },
    setUser(state, user) {
      state.user = user;
    },
    setCountryCode(state, countryCode) {
      state.countryCode = countryCode;
    },
    setValidCountry(state, isValidCountry) {
      state.validCountry = isValidCountry;
    },
  },
  strict: true,
});

Vue.mixin({
  methods: {},
});
