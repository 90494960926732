<template id="app-share-dialog">
  <div>
    <md-dialog :md-active.sync="showShareUrl">
      <md-dialog-title>Share Hologram via</md-dialog-title>
      <div v-if="delegationCredentials">
        <div id="shareTabs">
          <div
            class="shareTab"
            :class="{
              activeTab: activeTabNumber === 0,
            }"
            @click="activeTabNumber = 0"
          >
            QR
          </div>
          <div
            class="shareTab"
            :class="{
              activeTab: activeTabNumber === 1,
            }"
            @click="activeTabNumber = 1"
          >
            Text Message
          </div>
          <div
            class="shareTab"
            :class="{
              activeTab: activeTabNumber === 2,
            }"
            @click="activeTabNumber = 2"
          >
            Print
          </div>
        </div>
        <div id="tabContent">
          <div
            v-if="activeTabNumber === 0"
            id="scanQrTab"
            @contextmenu="openMenu($event)"
          >
            <qr-code
              class="qrContainer"
              error-level="L"
              :size="qrSize"
              :text="sharedResourceUrl"
            ></qr-code>
          </div>
          <div
            v-else-if="activeTabNumber === 1"
            id="smsTab"
            :style="{
              height: qrSize + 'px',
              minwidth: qrSize + 'px',
            }"
          >
            <div>Send a link of this hologram to a mobile phone</div>
            <div id="sendLine">
              <VueTelInput
                v-model="phoneNumber"
                v-bind="phoneProps"
              ></VueTelInput>
              <md-button
                class="md-primary md-raised"
                :disabled="!phoneNumber || isSendingSms"
                @click="sendSms()"
                >Send Text</md-button
              >
              <app-status-icon :status="smsStatus"></app-status-icon>
            </div>
            <div v-if="sendSmsErrorMessage" id="sendErrorMessage">
              {{ sendSmsErrorMessage }}
            </div>
            <div class="small">
              Phone number will not be stored or used for any other purposes
            </div>
          </div>
          <div v-else id="printTab">
            <div id="printPage">
              <img class="appLogo" src="/assets/image/Augmedit.png" />
              <qr-code
                error-level="L"
                :size="280"
                :text="sharedResourceUrl"
              ></qr-code>
              <div id="scanText">
                Scan QR with mobile device to see hologram
              </div>
            </div>
            <md-button
              class="md-primary md-raised nastyButton"
              @click="printQr()"
              >Print</md-button
            >
          </div>
        </div>
      </div>
      <div v-else id="generating">Generating sharing information</div>
      <md-dialog-actions>
        <md-button
          class="md-primary nastyButton"
          @click="
            showShareUrl = false;
            showSharePin = true;
          "
          >Show PIN</md-button
        >
      </md-dialog-actions>
    </md-dialog>
    <md-dialog :md-active.sync="showSharePin">
      <md-dialog-title>Share Hologram PIN</md-dialog-title>
      <div
        id="pinCodeDialog"
        :style="{
          height: qrSize + 'px',
        }"
      >
        <div v-if="delegationCredentials">
          <div id="pinCode" @contextmenu="openMenu($event)">
            <div v-for="(char, index) in firstPinPart" :key="`first-${index}`">
              <div class="pinDigit">{{ char }}</div>
            </div>
            <div class="separator">-</div>
            <div v-for="(char, index) in secondPinPart" :key="`sec-${index}`">
              <div class="pinDigit">{{ char }}</div>
            </div>
          </div>
          <div>Send PIN by email</div>
          <div id="sendLine">
            <md-field id="inputLine">
              <md-input
                v-model="emailAddress"
                placeholder="name@domain.com"
                style="width: 120px"
            /></md-field>
            <md-button
              class="md-primary md-raised"
              :disabled="
                !emailAddress || !emailAddress.includes('@') || isSendingEmail
              "
              @click="sendMail()"
              >Send Mail</md-button
            >
            <app-status-icon :status="mailStatus"></app-status-icon>
          </div>
          <div v-if="sendMailErrorMessage" id="sendErrorMessage">
            {{ sendMailErrorMessage }}
          </div>
          <div class="small">
            Email address will not be stored or used for any other purposes
          </div>
        </div>
        <div v-else>Generating sharing information</div>
      </div>
      <md-dialog-actions>
        <md-button
          class="md-primary"
          :disabled="!delegationCredentials"
          @click="
            showSharePin = false;
            showShareUrl = true;
          "
          >Show QR</md-button
        >
        <md-button
          class="md-primary"
          @click="
            showSharePin = false;
            delegationCredentials = null;
          "
          >Close</md-button
        >
      </md-dialog-actions>
    </md-dialog>
    <context-menu ref="menu">
      <div>
        <div @click="copy()">{{ copyText }}</div>
      </div>
    </context-menu>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import appSettings from "./appSettings";
import contextMenu from "./contextMenu";
import appStatusIcon from "./appStatusIcon";
import { VueTelInput } from "vue-tel-input";

export default {
  name: "AppShareDialog",
  components: {
    contextMenu,
    appStatusIcon,
    VueTelInput,
  },
  props: {
    sharedResource: {
      type: String,
      default() {
        return "";
      },
    },
  },
  data: () => ({
    copiedToClipboard: false,
    delegationCredentials: null,
    showShareUrl: false,
    activeTabNumber: 0,
    showSharePin: false,
    sharedResourceUrl: null,
    // Mail
    emailAddress: null,
    emailMessageId: null,
    sendMailErrorMessage: null,
    mailStatus: null,
    // SMS
    phoneNumber: null,
    smsMessageId: null,
    sendSmsErrorMessage: null,
    smsStatus: null,
    phoneProps: {
      mode: "international",
      defaultCountry: "NL",
      placeholder: "Enter a phone number",
      preferredCountries: ["NL", "CH", "DE"],
    },
  }),
  computed: {
    copyText() {
      return this.copiedToClipboard
        ? "Copied"
        : this.showSharePin
        ? "Copy PIN"
        : "Copy Link";
    },
    firstPinPart() {
      return this.delegationCredentials.pinCode.slice(0, 3);
    },
    secondPinPart() {
      return this.delegationCredentials.pinCode.slice(3, 6);
    },
    qrSize() {
      return this.dialogHeight - 420;
    },
    dialogHeight() {
      let height = (window.outerWidth / window.innerWidth) * window.innerHeight;
      return Math.max(800, height * 0.7);
    },
    isSendingEmail() {
      return this.mailStatus === "InProgress";
    },
    isSendingSms() {
      return this.smsStatus === "InProgress";
    },
  },
  watch: {
    showShareUrl() {
      this.notifyParentIfClosed();
    },
    showSharePin() {
      this.notifyParentIfClosed();
    },
  },
  async created() {
    this.showShareUrl = true;
    this.delegationCredentials = await this.createDelegationCredentials(
      this.sharedResource
    );
    var host = appSettings.host.replace("www.", "");
    this.sharedResourceUrl = `https://landing.${host}?resource=${this.sharedResource}&delegationId=${this.delegationCredentials.delegationId}`;
  },
  methods: {
    ...mapActions(["getAzure", "postAzure", "createDelegationCredentials"]),
    openMenu(e) {
      this.copiedToClipboard = false;
      this.$refs.menu.open(e);
      e.preventDefault();
    },
    printQr() {
      window.print();
    },
    notifyParentIfClosed() {
      if (!this.showSharePin && !this.showShareUrl) {
        this.$emit("share-dialog-closed");
      }
    },
    copy() {
      this.copyToClipboard(
        this.showSharePin
          ? this.delegationCredentials.pinCode
          : this.sharedResourceUrl
      );
    },
    copyToClipboard(text) {
      let localThis = this;
      navigator.clipboard.writeText(text).then(
        function () {
          localThis.copiedToClipboard = true;
        },
        function (err) {
          localThis.setErrorMessage("Error while copying: " + err);
        }
      );
    },
    async sendMail() {
      const url = `${appSettings.remoteurl}/api/mail/send/hologram-pin/`;
      this.sendMailErrorMessage = null;
      this.mailStatus = "InProgress";
      this.emailMessageId = await this.postAzure({
        url,
        metadata: {
          emailAddress: this.emailAddress,
          pinCode: this.delegationCredentials.pinCode,
        },
        responseType: "text",
        errorHandler: this.handleSendMailError,
      });
      this.checkMailStatus();
    },
    async checkMailStatus() {
      const url = `${appSettings.remoteurl}/api/mail/status/${this.emailMessageId}`;
      this.mailStatus = await this.getAzure({
        url,
        responseType: "text",
      });
      if (this.isSendingEmail) {
        this.timeout = setTimeout(() => {
          this.checkMailStatus();
        }, 1000);
      }
    },
    handleSendMailError(e) {
      this.sendMailErrorMessage = e.details || e.message;
    },
    async sendSms() {
      const url = `${appSettings.remoteurl}/api/sms/send/resource/`;
      this.sendSmsErrorMessage = null;
      this.smsStatus = "InProgress";
      this.smsMessageId = await this.postAzure({
        url,
        metadata: {
          phoneNumber: this.phoneNumber,
          resourceUri: this.sharedResourceUrl,
        },
        responseType: "text",
        errorHandler: this.handleSendSmsError,
      });
      this.checkSmsStatus();
    },
    async checkSmsStatus() {
      const url = `${appSettings.remoteurl}/api/sms/status/${this.smsMessageId}`;
      this.smsStatus = await this.getAzure({
        url,
        responseType: "text",
      });
      if (this.isSendingSms) {
        this.timeout = setTimeout(() => {
          this.checkSmsStatus();
        }, 1000);
      }
    },
    handleSendSmsError(e) {
      this.sendSmsErrorMessage = e.details || e.message;
    },
  },
};
</script>

<style scoped>
#tabContent {
  background-color: #53a4bd;
}
@media print {
  body * {
    visibility: hidden;
  }
  html,
  body {
    width: 210mm;
    height: 297mm;
  }
  .nastyButton {
    visibility: hidden !important;
  }
  #printTab {
    display: contents !important;
  }
  #printPage,
  #printPage * {
    visibility: visible;
  }
  #printPage {
    box-sizing: unset;
    transform: scale(1.5);
    height: 100%;
    top: 0;
    left: 0;
    margin: 40px !important;
    padding: 15px;
    font-size: 14px;
    line-height: 18px;
    border-style: none !important;
  }
}
#printPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  border-width: 1px;
  border-style: solid;
  border-color: var(--accent-color-light);
  margin: 10px;
  padding: 20px;
}
#scanText {
  margin: 20px;
  width: 200px;
  color: black;
  font-size: xx-small;
}

#generating {
  width: 400px;
  height: 200px;
  text-align: center;
}
#pinCodeDialog {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #53a4bd;
  text-align: center;
}
#printTab {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}
.appLogo {
  width: 180px;
  margin: 10px;
}

#sendLine {
  margin-top: 10px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
#sendErrorMessage {
  background-color: red;
}
#inputLine {
  padding-top: 3px;
  padding-left: 12px;
  margin: 0px;
  background-color: var(--accent-color-light);
  border-radius: 10px;
  min-height: 20px;
  max-height: 40px;
  width: 240px;
  margin-top: 5px;
}
#pinCode {
  margin: 40px;
  display: flex;
  flex-direction: row;
}
.pinDigit {
  width: 50px;
  margin-left: 10px;
  height: 70px;
  padding-top: 24px;
  font-size: 30pt;
  font-weight: 400;
  color: #53a4bd;
  text-align: center;
  border-radius: 6px 6px;
  filter: drop-shadow(0 0 0.2rem black);
  background-color: white;
}
.separator {
  font-size: 80px;
  margin-top: 24px;
  margin-left: 6px;
  color: var(--accent-color-light);
}

input.pin::placeholder {
  opacity: 0.5;
}

#smsTab {
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

#scanQrTab {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.qrContainer {
  margin: 8px;
  border: white 24px solid;
  border-radius: 8px;
}

#shareTabs {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.activeTab {
  background-color: #53a4bd !important;
}
.shareTab {
  width: 180px;
  margin-left: 2px;
  margin-right: 2px;
  height: 30px;
  padding-top: 5px;
  text-align: center;
  background-color: var(--accent-color-dark);
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  box-shadow: 3px -3px 3px rgba(0, 0, 0, 0.2);
}
</style>
<style>
.vue-tel-input {
  background-color: var(--accent-color-light) !important;
}
.vti__dropdown-list {
  background-color: var(--accent-color-light) !important;
}
.vti__input {
  background-color: var(--accent-color-dark) !important;
}
.vti__dropdown.open {
  background-color: #53a4bd !important;
}
.vti__dropdown:hover {
  background-color: #53a4bd !important;
}
.vti__dropdown-item.highlighted {
  background-color: #53a4bd !important;
}
</style>
