<template>
  <div class="mainPage">
    <app-event-stream @eventgrid-event="onEvent"></app-event-stream>
    <app-toolbar></app-toolbar>
    <app-date-provider></app-date-provider>
    <div v-if="!selectedAsset">
      <div v-if="assetGroups.length > 0">
        <div class="title">{{ title }}</div>
        <app-search-bar class="searchBar"></app-search-bar>
      </div>
      <div v-if="loading || loadingGroups" class="padded centered">
        <div class="centered">
          <h1>One moment please</h1>
          <h2>Fetching your data...</h2>
        </div>
      </div>
      <div v-else-if="creating" id="fileDropperContainer">
        <app-file-dropper
          id="fileDropper"
          accept="*"
          :directory="!isHome ? false : 'directory'"
        ></app-file-dropper>
      </div>
      <div v-else>
        <app-asset-list></app-asset-list>
        <div v-if="isHome && hasMoreAssets" id="loadMore" @click="loadMore()">
          <md-button> Load More </md-button>
        </div>
      </div>
    </div>
    <app-copy-asset @asset-deleted="onListAssetDeleted"></app-copy-asset>
    <!-- The div is hidden using a bound style rather than a v-if,
    as a v-if will destroy the DOM node that the Unity instance is attached to -->
    <div
      :style="{
        display: selectedAsset ? 'block' : 'none',
      }"
    >
      <app-asset-page
        @asset-deleted="onSelectedAssetDeleted"
        @file-uploading="setUploading"
      ></app-asset-page>
    </div>
  </div>
</template>

<script>
import appDateProvider from "./appDateProvider.vue";
import appToolbar from "./appToolbar";
import appAssetList from "./appAssetList";
import appFileDropper from "./appFileDropper";
import appSearchBar from "./appSearchBar";
import appAssetPage from "./appAssetPage";
import appSettings from "./appSettings";
import appEventStream from "./appEventStream";
import AppCopyAsset from "./appCopyAsset";
import { bus } from "../eventBus.js";
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";

const loadingMessage = "Loading...";

export default {
  name: "AppMain",
  components: {
    "app-date-provider": appDateProvider,
    "app-toolbar": appToolbar,
    "app-asset-page": appAssetPage,
    "app-event-stream": appEventStream,
    "app-asset-list": appAssetList,
    "app-file-dropper": appFileDropper,
    "app-search-bar": appSearchBar,
    "app-copy-asset": AppCopyAsset,
  },
  data: () => ({
    poppedassetId: null,
    assetQueryCount: 50,
    hasMoreAssets: false,
  }),
  computed: {
    title() {
      return this.isHome
        ? "Select Your Patient"
        : this.breadcrumbs[1].asset.metadata.displayName;
    },
    selectedGroupId() {
      return this.selectedGroup ? this.selectedGroup.id : null;
    },
    selectedAssetId() {
      return this.selectedAsset ? this.selectedAsset.id : null;
    },
    currentHost() {
      // TODO: add identifier to make host specific to session
      return window.location.origin;
    },
    queryFields() {
      return (
        `${this.selectedAssetId}_${this.lastBreadcrumb.asset.id}_${this.selectedGroupId}` +
        `_${this.typeFilter}_${this.filter}_${this.sort}_${this.searchMyPatients}`
      );
    },
    ...mapState([
      "breadcrumbs",
      "selectedGroup",
      "assetGroups",
      "assets",
      "selectedAsset",
      "typeFilter",
      "errorMessage",
      "viewMode",
      "creating",
      "loading",
      "loadingGroups",
      "searchMyPatients",
      "user",
    ]),
    ...mapGetters([
      "isHome",
      "lastBreadcrumb",
      "showUnity",
      "inListMode",
      "filter",
      "sort",
    ]),
  },
  watch: {
    async queryFields() {
      if (!this.inListMode) return;
      this.assetQueryCount = 50;
      await this.loadAssetsWithLoadScreen();
    },
    async assetQueryCount() {
      await this.loadAssets();
    },
    async selectedGroupId(groupId) {
      if (!groupId) return;

      this.setSelectedGroupInLocalStorage();
      this.updateSelectedGroupWorkflowState();
    },
    viewMode(viewMode) {
      if (viewMode === "clinical") {
        this.setTypeFilter("Person");
      }
      window.localStorage["viewMode"] = viewMode;
    },
    async selectedAsset(asset, previousAsset) {
      if (asset) {
        if (!previousAsset) {
          this.addAssetToBrowserHistory(asset);
          this.setAssets([]);
          this.pushBreadcrumb(asset);
        }
        this.updateCachedAsset(asset);
        if (!asset.formats) {
          // formats are only retrieved when you add the includeDetails directive
          // what is not done during a list query to save time
          this.setSelectedAsset(
            await this.loadAsset({
              groupId: this.selectedGroup.id,
              assetId: asset.id,
              includeDetails: true,
            })
          );
        }
        await this.updateSelectedAssetWorkflowState({ assetId: asset.id });
      } else {
        this.setSelectedAssetWorkflowState([]);
        window.history.replaceState({}, "home", "/");
      }
    },
    errorMessage(errorMessage) {
      if (this.$appInsights.loaded) {
        this.$appInsights.trackException({
          exception: errorMessage,
        });
      }
    },
  },
  async created() {
    this.setViewMode(window.localStorage["viewMode"] || "clinical");
    const deeplink = this.getDeeplink();
    await this.initializeGroups(deeplink);
    if (deeplink) {
      await this.selectAsset(deeplink.groupId, deeplink.assetId);
    }
    const toggles = await this.fetchFeatureToggles();
    this.setAllowedFeatureToggles(toggles.map((x) => x.id));
    const data = await this.fetchAppInsightsConnectionString();
    this.$appInsights.load(data.connectionString);
    this.$appInsights.trackEvent({
      name: "initialized",
    });
  },
  mounted() {
    window.onpopstate = (e) => {
      this.setHomeBreadcrumb();
      if (e.state && e.state.asset && e.state.assetgroup) {
        this.poppedassetId = e.state.asset.id;
        // might have been modified, so load again
        this.selectAsset(e.state.assetGroup.id, e.state.asset.id);
      } else {
        window.history.replaceState({}, "home", "/");
        this.goHome();
      }
    };
  },
  methods: {
    ...mapMutations([
      "goHome",
      "setBreadcrumbs",
      "setHomeBreadcrumb",
      "addGroup",
      "setGroups",
      "setSelectedGroup",
      "setSelectedAsset",
      "setSelectedAssetWorkflowState",
      "pushBreadcrumb",
      "popBreadcrumb",
      "setErrorMessage",
      "setTypeFilter",
      "setAssets",
      "setLoading",
      "setLoadingGroups",
      "setViewMode",
      "updateProgress",
      "setAllowedFeatureToggles",
      "setServiceNotice",
    ]),
    ...mapActions([
      "loadAsset",
      "loadGroups",
      "updateSelectedAssetWorkflowState",
      "updateSelectedGroupWorkflowState",
      "fetchAzure",
      "fetchFeatureToggles",
      "fetchAppInsightsConnectionString",
    ]),
    loadMore() {
      this.assetQueryCount += 50;
    },
    async initializeGroups(deeplink) {
      const storedGroups = this.getGroupsFromSessionStorage();
      if (storedGroups) {
        this.setGroups(storedGroups);
      }
      const hasGroupToBeginWith = this.setGroupFromLocalStorage(deeplink);
      try {
        if (hasGroupToBeginWith) {
          // Loading in background if group for deeplink or default group is available
          this.loadGroups();
        } else {
          // Block on loading
          this.setLoadingGroups(true);
          await this.loadGroups();
        }
      } finally {
        this.setLoadingGroups(false);
      }
    },
    setGroupFromLocalStorage(deeplink) {
      const storedSelectedGroup = this.getSelectedGroupFromLocalStorage();
      if (!storedSelectedGroup) return false;
      if (deeplink && storedSelectedGroup.id !== deeplink.groupId) return false;

      // already fill dropdown with the selected asset group,
      // full list will be fetched asynchronously
      this.setGroups([
        { id: loadingMessage, metadata: {} },
        storedSelectedGroup,
      ]);
      this.setSelectedGroup(storedSelectedGroup);
      return true;
    },
    getDeeplink() {
      const pathname = window.location.pathname;
      if (!pathname || pathname === "/") return;

      let paths = pathname.substr(1).split("/");
      let groupId = paths[0];
      let assetId = paths[1];

      if (!groupId || !assetId) return;
      return { groupId: groupId, assetId: assetId };
    },
    addAssetToBrowserHistory(asset) {
      // Hmm, not very beautiful, but does the job for now
      if (asset.id === this.poppedassetId) return;

      window.history.pushState(
        {
          asset: asset,
          assetgroup: this.selectedGroup,
        },
        "",
        `/${this.selectedGroup.id}/${asset.id}`
      );
    },
    isAssetCached(assetId) {
      if (this.selectedAsset && this.selectedAsset.id === assetId) {
        return true;
      }
      return this.assets.find((c) => c.id === assetId);
    },
    onEvent(event) {
      if (event.eventType.startsWith("User")) {
        this.onUserEvent(event);
        return;
      }
      if (!this.selectedGroup) return;
      const eventData = JSON.parse(event.data);
      if (this.selectedGroup.id !== eventData.groupId) return;

      switch (event.eventType) {
        case "AssetEvent.Insert":
          this.onCreateAssetEvent(eventData);
          break;
        case "AssetEvent.Update":
          this.onUpdateAssetEvent(eventData, event);
          break;
        case "AssetFormatEvent.Update":
        case "AssetFormatEvent.Insert":
        case "AssetFormatEvent.Delete":
        case "AssetFormatEvent.AddFiles":
        case "AssetEvent.AddFiles":
          this.onAssetFormatEvent(eventData, event);
          break;
        case "AssetEvent.Delete":
          this.onDeleteAssetEvent(eventData);
          break;
        case "ProgressEvent.Update":
          this.onProgressUpdateEvent(
            eventData.assetIds,
            eventData.function,
            eventData.percentageCompleted
          );
          break;
      }
    },
    onUserEvent(event) {
      const eventData = JSON.parse(event.data);
      switch (event.eventType) {
        case "Users.OverlappingSessionWarning":
          this.onOverlappingUserWarningEvent(eventData);
          break;
      }
    },
    async onProgressUpdateEvent(assetIds, generator, percentageCompleted) {
      if (this.selectedAsset && assetIds.includes(this.selectedAssetId)) {
        await this.updateSelectedAssetWorkflowState({
          assetId: this.selectedAssetId,
        });
      }
      this.updateProgress({ assetIds, percentageCompleted });
    },
    onCreateAssetEvent(eventData) {
      if (!this.inListMode) return;
      this.updateListIfNeeded(eventData);
    },
    onUpdateAssetEvent(eventData, event) {
      if (this.inListMode) {
        this.updateListIfNeeded(eventData);
        return;
      }
      if (
        this.isAssetCached(eventData.assetId) &&
        eventData.origin !== this.currentHost
      ) {
        // Asset Updates from other hosts have to be handled,
        // Local updates are handled via Vuex
        this.updateAsset(this.selectedGroup.id, eventData.assetId);
      }
      if (eventData.assetId !== this.selectedAssetId) return;
      this.forwardUpdateToUnityIfNeeded(event);
    },
    onAssetFormatEvent(eventData, event) {
      if (eventData.assetId !== this.selectedAssetId) return;

      // Format updates are very tricky to keep in sync, so synchronize even if from own host
      this.updateAsset(this.selectedGroup.id, eventData.assetId);
      var customOrigin =
        window.location.hostname +
        "/" +
        this.selectedGroup.id +
        "/" +
        this.selectedAsset.id;

      if (
        event.eventType !== "AssetFormatEvent.Update" ||
        eventData.origin !== customOrigin ||
        eventData.owner !== this.user.userId
      ) {
        this.forwardUpdateToUnityIfNeeded(event);
      }
    },
    async updateAsset(groupId, assetId) {
      const asset = await this.loadAsset({
        groupId: groupId,
        assetId: assetId,
        includeDetails: true,
      });
      if (asset) {
        this.updateCachedAsset(asset);
      }
    },
    updateListIfNeeded(eventData) {
      let listShouldUpdate = this.isAssetCached(eventData.assetId);
      if (
        eventData.parentAsset &&
        eventData.parentAsset.indexOf(this.lastBreadcrumb.asset.id) > -1
      ) {
        listShouldUpdate = true;
      }
      if (
        this.typeFilter === "All" ||
        this.typeFilter === eventData.assetType
      ) {
        listShouldUpdate = true;
      }
      if (listShouldUpdate) {
        this.loadAssets();
      }
    },
    forwardUpdateToUnityIfNeeded(event) {
      if (this.showUnity) {
        bus.$emit("unityUpdateEvent", event);
      }
    },
    onDeleteAssetEvent(eventData) {
      this.updateListIfNeeded(eventData);
      // TODO: breadcrumbs and list
      if (eventData.origin === this.currentHost) return; // Already knew this

      if (this.selectedAssetId === eventData.assetId) {
        this.setSelectedAsset(null);
        this.setErrorMessage(
          "Selected asset was deleted by another user or process"
        );
      }
    },
    async onOverlappingUserWarningEvent(eventData) {
      await new Promise((r) => setTimeout(r, 1000));
      this.setServiceNotice(eventData.Message);
    },
    setUploading(status) {
      this.uploading = status;
    },
    setSelectedGroupInLocalStorage() {
      window.localStorage["selectedGroupId"] = this.selectedGroup.id;
      window.localStorage["selectedGroupDisplayName"] =
        this.selectedGroup.metadata.displayName || this.selectedGroup.id;
      const permission = this.selectedGroup.metadata._permission;
      window.localStorage["selectedGroupPermission"] = permission;
    },
    getSelectedGroupFromLocalStorage() {
      const storedGroupId = window.localStorage["selectedGroupId"];
      const storedGroupDisplayName =
        window.localStorage["selectedGroupDisplayName"];

      return storedGroupId
        ? {
            id: storedGroupId,
            metadata: {
              displayName: storedGroupDisplayName,
              _permission: this.getStoredGroupPermission(),
            },
          }
        : null;
    },
    getStoredGroupPermission() {
      return window.localStorage["selectedGroupPermission"];
    },
    getGroupsFromSessionStorage() {
      let assetGroups = window.sessionStorage.getItem("newGroups");
      return assetGroups ? JSON.parse(assetGroups) : null;
    },
    async selectAsset(groupId, assetId) {
      if (!assetId || !groupId) {
        throw `received undefined group or asset: ${groupId}/${assetId}`;
      }
      let nextGroup = this.findGroup(groupId);
      if (!nextGroup) {
        throw `Could not find asset group ${groupId}`;
      }

      let nextAsset = this.findAsset(assetId);
      // if nextAsset is undefined, it could be new or not in query results
      if (!nextAsset || !nextAsset.formats) {
        nextAsset = await this.loadAsset({
          groupId: groupId,
          assetId: assetId,
          includeDetails: true,
        });
        if (!nextAsset) {
          throw `API does not return ${groupId}/${assetId}`;
        }
      }

      await this.setSelectedGroup(nextGroup);
      await this.setSelectedAsset(nextAsset);
    },
    findGroup(groupId) {
      return this.assetGroups.find((c) => c.id === groupId);
    },
    findAsset(assetId) {
      return this.assets.find((c) => c.id === assetId);
    },
    async onSelectedAssetDeleted() {
      this.setSelectedAsset(null);
      this.popBreadcrumb();
    },
    async onListAssetDeleted() {
      this.loadAssets();
    },
    updateCachedAsset(newAsset) {
      const index = this.getAssetIndexByName(this.assets, newAsset.id);
      if (index > -1) {
        let assets = [...this.assets];
        assets[index] = { ...newAsset };
        this.setAssets(assets);
      }
      if (this.selectedAsset && this.selectedAsset.id === newAsset.id) {
        this.setSelectedAsset(newAsset);
      }
      const breadcrumbIndex = this.getBreadrumbIndexByName(
        this.breadcrumbs,
        newAsset.id
      );
      if (breadcrumbIndex > -1) {
        let breadcrumbs = [...this.breadcrumbs];
        breadcrumbs[breadcrumbIndex] = {
          asset: newAsset,
          filter: breadcrumbs[breadcrumbIndex].filter,
          sort: breadcrumbs[breadcrumbIndex].sort,
        };
        this.setBreadcrumbs(breadcrumbs);
      }
    },
    getBreadrumbIndexByName(breadcrumbs, id) {
      const cachedAsset = breadcrumbs.find((c) => c.asset.id === id);
      return cachedAsset ? breadcrumbs.indexOf(cachedAsset) : -1;
    },
    getAssetIndexByName(assets, id) {
      const cachedAsset = assets.find((c) => c.id === id);
      return cachedAsset ? assets.indexOf(cachedAsset) : -1;
    },
    async loadAssetsWithLoadScreen() {
      try {
        this.setLoading(true);
        await this.loadAssets();
      } finally {
        this.setLoading(false);
      }
    },
    async loadAssets() {
      if (!this.selectedGroup) return;

      let queryString = this.buildQueryString();
      let assets = await this.fetchAzure(
        `${appSettings.remoteurl}/api/groups/${this.selectedGroup.id}/assets?${queryString}`
      );
      if (this.isHome) {
        this.hasMoreAssets = assets.length > this.assetQueryCount;
        this.setAssets(assets.slice(0, this.assetQueryCount));
      } else {
        this.setAssets(assets);
      }
    },
    buildQueryString() {
      const sort = this.sort;
      let sortOrder = sort === "_modified" ? "-" : "+";
      let parentAsset = "";
      let count = "";
      let typeFilter = "";
      let myPatientsFilter = "";
      if (this.isHome) {
        count = `&_count=${this.assetQueryCount + 1}`;
        typeFilter =
          this.typeFilter === "All" ? "" : `&_type=${this.typeFilter}`;
        if (this.searchMyPatients) {
          myPatientsFilter = `&_owner=${encodeURIComponent(this.user.userId)}`;
        }
      } else {
        let parentBreadcrumb = this.breadcrumbs[this.breadcrumbs.length - 1];
        parentAsset = `&_parentAsset=/groups/${this.selectedGroup.id}/assets/${parentBreadcrumb.asset.id}`;
      }
      const filter = this.filter;
      let filterString = filter ? `&_all=*${encodeURIComponent(filter)}*` : "";
      return `_sort=${sortOrder}${sort}${filterString}${typeFilter}${myPatientsFilter}${count}${parentAsset}`;
    },
  },
};
</script>

<style scoped>
.mainPage {
  display: flex;
  flex-direction: column;
}

.title {
  text-align: center;
  color: white;
  font-size: 40px;
  margin-top: 20px;
  margin-bottom: 40px;
}

.searchBar {
  margin-top: 50px;
  margin-bottom: 20px;
}

#fileDropperContainer {
  margin-top: 40px;
  height: 480px;
  display: flex;
  flex-direction: column;
}

#fileDropper {
  align-self: center;
  display: flex;
  flex-direction: column;
  width: 60%;
}

#loadMore {
  display: flex;
  justify-content: center;
  font-size: 22px;
}

#loadMore > .md-button {
  font-size: 24px;
  background-color: #3d8094;
  padding: 10px;
  box-shadow: 0px 2px 5px black;
  color: silver;
}
</style>
