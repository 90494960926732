<template id="appAdminAudit">
  <div id="editPage">
    <form class="md-layout" novalidate @submit.prevent="validateUser">
      <div id="editHeader" class="md-layout">
        <md-card-content>
          <div id="showing" class="md-small-size-50" style="margin-top: 30px">
            Filtered {{ auditItems.length }} audits
          </div>
        </md-card-content>
        <md-card-content>
          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field>
                <label class="input-label" for="audit-id">Audit ID</label>
                <md-input id="audit-id" v-model="auditId" name="audit-id" />
              </md-field>
            </div>
          </div>
        </md-card-content>
        <md-card-content>
          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field>
                <label class="input-label" for="user-id">User ID</label>
                <md-input id="user-id" v-model="userId" name="user-id" />
              </md-field>
            </div>
          </div>
        </md-card-content>
        <md-card-content>
          <div class="md-layout md-gutter">
            <div class="md-layout-item md-small-size-100">
              <md-field>
                <label class="input-label" for="patient-id">Patient ID</label>
                <md-input
                  id="patient-id"
                  v-model="patientId"
                  name="patient-id"
                />
              </md-field>
            </div>
          </div>
        </md-card-content>
        <md-card-content>
          <div class="md-layout md-gutter">
            <div
              class="md-layout-item"
              style="margin-top: 10px; min-width: 200px"
            >
              <md-datepicker
                ref="datePicker"
                v-model="day"
                class="noAfter"
                md-immediately
                :md-model-type="String"
                :md-open-on-focus="false"
                ><label>yyyy-mm-dd</label></md-datepicker
              >
            </div>
          </div>
        </md-card-content>
        <md-card-content>
          <div class="md-layout md-gutter">
            <div
              class="md-layout-item"
              style="margin-top: 10px; min-width: 200px"
            >
              <multiselect
                v-model="dateOption"
                :allow-empty="false"
                class="dropDown"
                deselect-label=""
                label="label"
                :options="dateOptions"
                placeholder="Start typing to search..."
                :preselect-first="true"
                :searchable="false"
                select-label=""
                selected-label=""
              ></multiselect>
            </div>
          </div>
        </md-card-content>
      </div>
    </form>
    <md-table v-model="paginatedAuditItems">
      <md-table-row
        slot="md-table-row"
        slot-scope="{ item }"
        @click="foldOrUnfold($event)"
      >
        <md-table-cell md-label="Date and Time">{{
          localTime(item._created)
        }}</md-table-cell>
        <md-table-cell md-label="Event Id">{{ item.id }}</md-table-cell>
        <md-table-cell md-label="User"
          >{{ item.user.userId }} ({{ item.user.id }})</md-table-cell
        >
        <md-table-cell md-label="Resource Id">{{
          item.resource.id
        }}</md-table-cell>
        <md-table-cell md-label="Resource Type">{{
          item.resource.resourceType
        }}</md-table-cell>
        <md-table-cell md-label="Resource Name">{{
          item.resource.name
        }}</md-table-cell>
        <md-table-cell md-label="Action">{{ item.action }}</md-table-cell>
        <md-table-cell md-label="Result">{{ item.outcome }}</md-table-cell>
        <md-table-cell v-if="item.patients.length > 1" md-label="Patients">
          <div class="foldable">Affected: {{ item.patients.length }}</div>
          <div class="fold">
            <div class="foldable folded">
              <div v-for="patient in item.patients" :key="patient.id">
                {{ patient.patientId }} ({{ patient.id }})
              </div>
            </div>
          </div>
        </md-table-cell>
        <md-table-cell v-else md-label="Patient">{{
          item.patients.length == 1 ? item.patients[0].id : ""
        }}</md-table-cell>
      </md-table-row>
      <md-table-pagination
        :md-data="auditItems"
        :md-page-options="[10, 20, 50, 100, 250, 500]"
        :md-page-size="10"
        :md-paginated-data.sync="paginatedAuditItems"
      />
    </md-table>
  </div>
</template>
<script>
import appSettings from "./appSettings";
import { mapActions } from "vuex";
import moment from "moment";
import multiselect from "vue-multiselect";

export default {
  name: "AppAdminAudit",

  components: {
    multiselect,
  },

  data: () => ({
    day: moment(new Date()).format("yyyy-MM-DD"),
    patientId: "",
    userId: "",
    auditId: "",
    auditItems: [],
    paginatedAuditItems: [],
    dateOption: null,
    dateOptions: [
      { label: "Equals", value: "eq" },
      { label: "Less than", value: "lt" },
      { label: "Greater than", value: "gt" },
      { label: "Less or Equal", value: "le" },
      { label: "Greater or Equal", value: "ge" },
    ],
  }),
  computed: {
    getQuery() {
      const dateOption = this.dateOption?.value ?? "eq";
      return [
        this.patientId ? `patientId=${this.patientid}` : "",
        this.userId ? `userId=${this.userId}` : "",
        this.auditId ? `auditId=${this.auditId}` : "",
        `created=${dateOption}${this.day}`,
      ]
        .filter((x) => x.length > 0)
        .join("&");
    },
  },
  watch: {
    async dateOption() {
      await this.loadAuditItems();
    },
    async patientId() {
      await this.loadAuditItems();
    },
    async userId() {
      await this.loadAuditItems();
    },
    async auditId() {
      await this.loadAuditItems();
    },
    async day() {
      if (!this.isValidDay()) return;
      await this.loadAuditItems();
    },
  },
  async created() {
    await this.loadAuditItems();
  },
  methods: {
    ...mapActions(["fetchAzure", "deleteAzure"]),
    async deleteProgress(progressId) {
      let url = `${appSettings.remoteurl}/api/progress/${progressId}`;
      await this.deleteAzure({ url });
      await this.loadProgressItems();
    },
    async loadAuditItems() {
      let url = `${appSettings.remoteurl}/api/audit?${this.getQuery}`;
      let data = await this.fetchAzure(url);

      // Can't change reference of array after assigning, so have to clear array rather than replacing it.
      while (this.auditItems.length > 0) {
        this.auditItems.pop();
      }

      for (var i = 0; i < data.length; i++) {
        this.auditItems.push(data[i]);
      }
    },
    formatDuration(date1, date2) {
      const diff = moment.utc(date2).diff(moment.utc(date1));
      return moment.utc(diff).format("HH:mm:ss");
    },
    isValidDay() {
      return this.day.length === 10;
    },
    foldOrUnfold(ev) {
      const foldables = ev.currentTarget.getElementsByClassName("foldable");
      for (let i = 0; i < foldables.length; i++) {
        foldables[i].classList.toggle("folded");
      }
    },
    localTime(date) {
      return date && moment(moment.utc(date).toDate()).format("lll");
    },
  },
};
</script>
<style>
.resourceItem .md-ripple {
  justify-content: left !important;
}
</style>
<style scoped>
#showing {
  margin: 10px;
  margin-left: 20px;
}
#refresh {
  margin: 10px;
}
#editHeader {
  display: flex;
  flex-direction: row;
}
#editDetails {
  margin-left: 20px;
}
.md-field .md-input {
  background-color: #3d8094;
  margin-top: 4px;
  border-radius: 8px;
}
.md-field .label {
  margin: 10px;
}
.dropDown {
  width: 200px;
  margin-left: 10px;
  border-radius: 10px;
  padding-left: 6px;
  padding-top: 8px;
  margin-bottom: 20px;
}
.select-label {
  margin-left: 15px;
}
.input-label {
  padding-left: 10px;
  padding-top: 3px;
}
.page-item {
  margin-right: 5px;
  padding: 5px;
  background-color: #468799;
  font-weight: bold;
}
.page-item.selected {
  color: lightgreen;
}
.foldable.folded {
  display: none;
  height: 100%;
}
</style>
