<template id="app-asset-metadata">
  <div id="metadatacontainer">
    <h1>{{ assetType }} Metadata</h1>
    <md-content id="metadatalist">
      <div
        v-if="selectedAsset && selectedAsset.metadata._type === 'Surface'"
        class="metadataEntry surfaceType"
      >
        <span>Anatomical Template</span>
        <multiselect
          id="surfaceTemplate"
          v-model="selectedSurfaceType"
          :allow-empty="false"
          deselect-label=""
          label="displayName"
          name="surfaceTemplate"
          :options="surfaceTemplates"
          placeholder="Start typing to search..."
          :searchable="true"
          select-label=""
          selected-label=""
        ></multiselect>
      </div>
      <md-list>
        <template v-for="entry in metadataEntries">
          <md-list-item :key="entry.key">
            <div class="metadataEntry">
              <span>{{ entry.displayKey }} </span>
              <div class="rowInput">
                <md-field v-if="isReadOnlyGroup">
                  <md-input v-model="entry.value" readonly />
                </md-field>
                <md-field v-else>
                  <md-input
                    v-model="entry.value"
                    placeholder="value"
                    @change="onValueChange(entry.key, $event.target.value)"
                    @keydown.stop="editStart()"
                  />
                </md-field>

                <md-button
                  v-if="!isReadOnlyGroup && !defaultFields.includes(entry.key)"
                  class="md-icon-button md-dense"
                  @click="deleteKey(entry.key)"
                >
                  <md-icon small>delete</md-icon>
                </md-button>
                <div v-else style="width: 44px; margin: 0px 6px"></div>
              </div>
            </div>
          </md-list-item>
        </template>
        <div v-if="!isReadOnlyGroup" class="buttonbar">
          <md-button class="addMoreButton" @click="openMetadataDialog()">
            + Add More
          </md-button>
        </div>
        <div v-else class="nobuttonbar"></div>
      </md-list>
    </md-content>
    <md-dialog :md-active.sync="showAddMetadataDialog">
      <md-dialog-title>Add More</md-dialog-title>

      <md-dialog-content>
        <div class="metadataEntry">
          <div class="rowInput">
            <md-field>
              <md-input v-model="newKey" placeholder="key" />
            </md-field>
          </div>
          <div class="rowInput">
            <md-field>
              <md-input v-model="newValue" placeholder="value" />
            </md-field>
          </div>
        </div>
      </md-dialog-content>

      <md-dialog-actions>
        <md-button
          class="md-primary md-raised"
          :disabled="!newKey"
          @click="addMetadata()"
          >Ok</md-button
        >
        <md-button class="md-primary" @click="showAddMetadataDialog = false"
          >Cancel</md-button
        >
      </md-dialog-actions>
    </md-dialog>
  </div>
</template>

<script>
import appSettings from "./appSettings";
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
import multiselect from "vue-multiselect";

function capitalizeFirstCharacter(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}
export default {
  name: "AppAssetMetadata",
  components: {
    multiselect,
  },
  data() {
    return {
      metadataEntries: [],
      showAddMetadataDialog: false,
      newKey: null,
      newValue: null,
      surfaceTemplates: [],
      surfaceType: null,
      selectedSurfaceType: null,
      defaultFields: ["displayName"],
    };
  },
  computed: {
    ...mapState(["selectedAsset"]),
    ...mapGetters(["isReadOnlyGroup"]),
    assetType() {
      const assetType = this.selectedAsset && this.selectedAsset.metadata._type;
      if (!assetType) return "Unknown";
      return assetType === "Set"
        ? "Hologram"
        : assetType === "DICOMStudy"
        ? "DICOM Study"
        : assetType;
    },
  },
  watch: {
    selectedAsset() {
      this._loadMetadata();
    },
    selectedSurfaceType(surface) {
      if (!surface) return;
      this.surfaceType = surface.shortName;
    },
    surfaceType(surfaceType) {
      if (
        !this.selectedAsset ||
        this.selectedAsset.metadata.surfaceType === surfaceType
      )
        return;
      this.onValueChange("surfaceType", surfaceType);
    },
  },
  async created() {
    this._loadMetadata();
    var templateList = await this.fetchAzure(
      `${appSettings.remoteurl}/api/surfaceTemplates`
    );

    this.surfaceTemplates = templateList.map((x) => x.metadata);
  },
  methods: {
    editStart() {},
    ...mapMutations(["deleteAssetMetadataEntry"]),
    ...mapActions(["updateAssetMetadata", "fetchAzure"]),
    _loadMetadata() {
      this.metadataEntries = [];
      this.surfaceType = null;

      if (!this.selectedAsset) return;

      const metadata = this.selectedAsset.metadata;
      this.surfaceType = metadata.surfaceType;
      this.selectedSurfaceType = this.getSurfaceTypeFromShortName(
        metadata.surfaceType
      );
      const sortedHeaderFields = [
        "displayName",
        "PatientName",
        "BirthDate",
        "PatientId",
        "PatientIdIssuer",
        "thumb",
      ];
      sortedHeaderFields.forEach((key) => {
        const value = metadata[key];
        if (value || this.defaultFields.includes(key))
          this.metadataEntries.push({
            key: key,
            displayKey: capitalizeFirstCharacter(key),
            value: this.formatValue(key, value),
          });
      });
      let remainingMetadataEntries = Object.entries(metadata)
        .map((entry) => {
          const key = entry[0];
          const value = entry[1];
          return {
            key: key,
            displayKey:
              key === "_modified" ? "Modified" : capitalizeFirstCharacter(key),
            value: this.formatValue(key, value),
          };
        })
        .filter((entry) => entry.displayKey[0] !== "_" && entry.value)
        .filter((entry) => entry.key !== "surfaceType")
        .filter((entry) => !sortedHeaderFields.includes(entry.key))
        .sort((a, b) => {
          return a.displayKey > b.displayKey ? 1 : -1;
        });
      this.metadataEntries.push(...remainingMetadataEntries);
    },
    formatValue(key, value) {
      return key === "BirthDate" ? this.formatDate(value) : value;
    },
    formatDate(date) {
      if (date) {
        return date.replace(/T.*/, "");
      }
      return "";
    },
    openMetadataDialog() {
      this.showAddMetadataDialog = true;
      window.scrollTo(0, 0);
    },
    deleteKey(key) {
      this.deleteAssetMetadataEntry(key);
      this.updateAssetMetadata({
        asset: this.selectedAsset,
        metadata: { [key]: null },
      });
    },
    onValueChange(key, value) {
      this.updateAssetMetadata({
        asset: this.selectedAsset,
        metadata: { [key]: value },
      });
    },
    addMetadata() {
      this.showAddMetadataDialog = false;
      this.onValueChange(this.newKey, this.newValue);
      this.newKey = null;
      this.newValue = null;
    },
    getSurfaceTypeFromShortName(surfaceType) {
      return this.surfaceTemplates.find((x) => x.shortName === surfaceType);
    },
  },
};
</script>
<style scoped>
#metadatacontainer {
  border-radius: 5px;
  color: white;
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
}
#metadatacontainer h1 {
  font-weight: bold;
}

.button {
  border-radius: 10px;
  padding: 10px 30px;
  margin: 10px;
  margin-right: 40px;
  cursor: pointer;
}
.buttons {
  display: flex;
  flex: 1;
  align-self: stretch;
  justify-content: flex-end;
}
ul.md-list.md-theme-default {
  background-color: transparent !important;
}

#metadatalist {
  background: transparent;
  align-self: stretch;
  border-radius: 5px;
  display: block;
  transform: scaleY(1);
  transition: transform 2.4s ease-in-out;
  max-height: 500px;
  overflow: auto;
}
.buttonbar {
  display: flex;
  align-items: center;
}

.md-menu.md-select {
  padding-left: 12px !important;
}

label {
  color: white;
}

span {
  color: white;
}

.md-field {
  background-color: rgba(45, 107, 125, 0.6);
  border-radius: 5px;
  padding-top: 10px;
  padding-bottom: 0px;
  margin-bottom: 0px;
  width: 274px;
  height: 48px;
}

.md-field .md-input {
  background-color: transparent;
  margin-left: 12px;
  caret-color: white;
}

.md-field.md-theme-default.md-focused label {
  color: white;
  pointer-events: auto;
  top: -6px;
  opacity: 1;
  font-size: 15px;
}

.rowInput {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.metadataEntry {
  display: flex;
  flex-direction: column;
  font-family: "Open Sans", sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 12px;
}

.surfaceType {
  margin-left: 16px;
}

.addMoreButton {
  font-size: 18px;
  font-family: "Open Sans", sans-serif;
  font-weight: bold;
}
.multiselect {
  width: 276px;
}

::v-deep .multiselect__tags {
  background-color: #275c6b;
}
</style>
