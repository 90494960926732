<template id="appAdminBatch">
  <div id="editPage">
    <div v-if="!selectedGroup">
      No group selected, please go to asset view first!
    </div>
    <div v-if="deleteProgressMessage">{{ deleteProgressMessage }}</div>
    <div id="commands">
      <div class="radioContainer">
        <md-radio v-model="deleteType" value="asset">Assets</md-radio>
        <md-radio v-model="deleteType" value="format">Formats</md-radio>
      </div>
      <div v-if="deleteType === 'asset'" class="command">
        <div>Asset Type</div>
        <multiselect
          v-model="assetType"
          :allow-empty="false"
          class="dropDown"
          deselect-label=""
          label="label"
          :options="assetTypes"
          placeholder="Start typing to search..."
          :preselect-first="true"
          :searchable="false"
          select-label=""
          selected-label=""
        ></multiselect>
        <md-button
          class="md-primary md-raised"
          @click="showDeleteConfirm = true"
        >
          Delete Shown Assets
        </md-button>
      </div>
      <div v-else class="command">
        <div>Asset/Format Type</div>
        <multiselect
          v-model="formatType"
          :allow-empty="false"
          class="dropDown"
          deselect-label=""
          label="label"
          :options="formatTypes"
          placeholder="Start typing to search..."
          :preselect-first="true"
          :searchable="false"
          select-label=""
          selected-label=""
        ></multiselect>
        <div>Generator</div>
        <multiselect
          v-model="generatorType"
          :allow-empty="false"
          class="dropDown"
          deselect-label=""
          label="label"
          :options="filteredGeneratorTypes"
          placeholder="Start typing to search..."
          :preselect-first="true"
          :searchable="false"
          select-label=""
          selected-label=""
        ></multiselect>
        <md-button
          class="md-primary md-raised"
          @click="showDeleteConfirm = true"
        >
          Delete Shown Formats
        </md-button>
      </div>

      <md-table>
        <md-table-row>
          <md-table-head>Asset Id</md-table-head>
          <md-table-head>Asset Name</md-table-head>
          <md-table-head v-if="deleteType === 'format'"
            >Formats (Id/Name/Generator</md-table-head
          >
        </md-table-row>
        <md-table-row v-for="asset in filteredAssets" :key="asset.id">
          <md-table-cell>{{ asset.id }}</md-table-cell
          ><md-table-cell>{{ asset.metadata.displayName }}</md-table-cell>
          <md-table-cell v-if="deleteType === 'format'">
            <md-table>
              <md-table-row v-for="format in asset.formats" :key="format.id">
                <md-table-cell>{{ format.id }}</md-table-cell
                ><md-table-cell>{{
                  format.metadata.displayName
                }}</md-table-cell>
                <md-table-cell>{{ format.metadata.generator }}</md-table-cell>
              </md-table-row>
            </md-table>
          </md-table-cell>
        </md-table-row>
      </md-table>
    </div>
    <md-dialog :md-active.sync="showDeleteConfirm">
      <md-dialog-title>Delete shown {{ deleteType }}s?</md-dialog-title>
      <md-dialog-content>
        <div id="deleteDialogContent">
          <div>{{ deleteMessage }}.</div>
          <div v-if="deleteType === 'asset'">
            All children will also be deleted!
          </div>
          <div class="spacer"></div>
          <div>Are you sure?</div>
        </div>
      </md-dialog-content>
      <md-dialog-actions>
        <md-button class="md-primary md-raised" @click="permanantlyDelete"
          >Delete</md-button
        >
        <md-button class="md-primary" @click="showDeleteConfirm = false"
          >Cancel</md-button
        >
      </md-dialog-actions>
    </md-dialog>
  </div>
</template>
<script>
import { mapState, mapActions, mapMutations } from "vuex";
import appSettings from "./appSettings";
import multiselect from "vue-multiselect";

export default {
  name: "AppAdminBatch",
  components: {
    multiselect,
  },
  data: () => ({
    showDeleteConfirm: false,
    deleteProgressMessage: null,
    deleteType: "asset",
    assets: [],
    assetType: null,
    assetTypes: [
      { label: "Volume", value: "Volume" },
      { label: "Surface", value: "Surface" },
      { label: "Person", value: "Patient" },
      { label: "DICOM Study", value: "DICOMStudy" },
      { label: "Hologram", value: "Set" },
    ],
    formatType: null,
    formatTypes: [
      { label: "Volume", value: "VOLUME", assetType: "Volume" },
      { label: "Hologram", value: "SET", assetType: "Set" },
      { label: "Surface/Stl", value: "STL", assetType: "Surface" },
      { label: "Surface/Gltf", value: "GLTF", assetType: "Surface" },
    ],
    generatorType: null,
    generatorTypes: [
      {
        label: "Dicom2Volume",
        value: "Augmedit DICOM Study to Volume convertor",
        assetType: "Set",
      },
      {
        label: "Dicom2Volume",
        value: "Augmedit DICOM Study to Volume convertor",
        assetType: "Volume",
      },
      {
        label: "VolumeConvertor",
        value: "VolumeConvertor",
        assetType: "Volume",
      },
      {
        label: "Segmentation",
        value: "Disior Segmentation",
        assetType: "Set",
      },
      {
        label: "Stl Simplifier",
        value: "AugmeditSimplifier",
        assetType: "Surface",
      },
      {
        label: "Stl2Gltf",
        value: "AugmeditStl2GltfConverter",
        assetType: "Surface",
      },
      {
        label: "All",
        value: "All",
        assetType: "All",
      },
    ],
  }),
  computed: {
    ...mapState(["selectedGroup", "idToken"]),
    filteredAssets() {
      return this.assets.map((a) => this.filterFormats(a)).filter((x) => x);
    },
    filteredGeneratorTypes() {
      return this.generatorTypes.filter(
        (x) =>
          this.formatType.assetType === x.assetType || x.assetType === "All"
      );
    },
    deleteMessage() {
      return `You are about to PERMANENTLY delete ${this.filteredAssets.length} ${this.deleteType}s.`;
    },
  },
  watch: {
    assetType() {
      this.queryAssets();
    },
    formatType() {
      this.generatorType = this.filteredGeneratorTypes[0];
      this.queryAssets();
    },
    selectedGroup() {
      this.queryAssets();
    },
    deleteType() {
      this.queryAssets();
    },
  },
  created() {
    this.assetType = this.assetTypes[0];
    this.formatType = this.formatTypes[0];
  },

  methods: {
    ...mapActions(["fetchAzure", "deleteAzure"]),
    ...mapMutations(["setErrorMessage"]),
    filterFormats(asset) {
      let clone = { ...asset };
      if (clone.formats) {
        clone.formats = clone.formats.filter(
          (format) =>
            format.metadata._type === this.formatType.value &&
            (this.generatorType.value === "All" ||
              (format.metadata.generator &&
                format.metadata.generator.name === this.generatorType.value))
        );
        if (clone.formats.length === 0) {
          return null;
        }
      }
      return clone;
    },
    async queryAssets() {
      if (!this.selectedGroup) return;

      this.assets = [];
      const include = this.deleteType === "format" ? "&_include=formats" : "";
      const assetType =
        this.deleteType === "format"
          ? this.formatType.assetType
          : this.assetType.value;
      this.assets = await this.fetchAzure(
        `${appSettings.remoteurl}/api/groups/${this.selectedGroup.id}/assets?_type=${assetType}${include}`
      );
    },
    async permanantlyDelete() {
      this.showDeleteConfirm = false;
      let index = 1;
      let assetCount = this.filteredAssets.length;
      try {
        for (let asset of this.filteredAssets) {
          this.deleteProgressMessage = `Deleting ${index}/${assetCount}`;
          if (this.deleteType === "asset") {
            let url = `${appSettings.remoteurl}/api/groups/${this.selectedGroup.id}/assets/${asset.id}`;
            await this.deleteAzure({ url });
          } else {
            for (let format of asset.formats) {
              let url = `${appSettings.remoteurl}/api/groups/${this.selectedGroup.id}/assets/${asset.id}/formats/${format.id}`;
              await this.deleteAzure({ url });
            }
          }
        }
      } finally {
        this.deleteProgressMessage = null;
        this.queryAssets();
      }
    },
  },
};
</script>

<style scoped>
#commands {
  display: flex;
  flex-direction: column;
}
.command {
  display: flex;
  flex-direction: row;
}
#editPage {
  display: flex;
  flex-direction: column;
}
.jsonText {
  background-color: #3d8094;
  color: white;
}
.md-field .md-input {
  background-color: #3d8094;
  margin-top: 4px;
  border-radius: 8px;
}
.md-field .label {
  margin: 10px;
}
.radioContainer {
  width: 340px;
}
.md-radio {
  margin: 2px;
  margin-top: 15px;
}
</style>
