<template id="app-tool-bar">
  <div class="toolbar">
    <div class="toolbarSide">
      <img class="appLogo" src="/assets/image/appLogoIcon.png" />
      <app-asset-breadcrumb> </app-asset-breadcrumb>
    </div>
    <div class="toolbarSide">
      <md-button v-if="isAdmin || isFeatureToggleAdmin">
        <router-link to="/admin">Admin Editor</router-link>
      </md-button>
      <md-button
        :class="{ 'md-raised': viewMode === 'research' }"
        @click="setViewMode(viewMode === 'clinical' ? 'research' : 'clinical')"
        >Research View</md-button
      >
      <app-user-profile></app-user-profile>
    </div>
  </div>
</template>

<script>
import appUserProfile from "./appUserProfile";
import appAssetBreadCrumb from "./appAssetBreadcrumb.vue";
import { mapState, mapGetters, mapMutations } from "vuex";
import featureToggles from "../enums/FeatureToggles.js";

export default {
  name: "AppToolBar",
  components: {
    "app-user-profile": appUserProfile,
    "app-asset-breadcrumb": appAssetBreadCrumb,
  },
  data: () => ({
    featureToggles: featureToggles,
  }),
  computed: {
    ...mapState(["viewMode", "allowedFeatureToggles"]),
    ...mapGetters(["isAdmin"]),
    isFeatureToggleAdmin() {
      return this.allowedFeatureToggles.includes(
        this.featureToggles.FEATURE_TOGGLE_ADMIN
      );
    },
  },
  methods: {
    ...mapMutations(["setViewMode"]),
  },
};
</script>

<style scoped>
.toolbar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.toolbarSide {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.appLogo {
  height: 25px !important;
}
</style>
