<template id="app-date-provider">
  <div style="height: 0px"></div>
</template>

<script>
import moment from "moment";
import { mapState } from "vuex";
export default {
  name: "AppDateProvider",
  computed: {
    ...mapState(["countryCode"]),
  },
  watch: {
    "$store.state.countryCode": function () {
      this.setMomentLocale();
    },
  },
  created() {
    this.setMomentLocale();
  },
  methods: {
    setMomentLocale() {
      const locale = this.countryCode
        ? "en-" + this.countryCode.toLowerCase()
        : "en-gb";
      moment.locale(locale.toLocaleLowerCase());
    },
  },
};
</script>
