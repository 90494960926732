<template id="app-file-dropper-progress">
  <md-dialog :md-active="isUploading || isCancelled">
    <md-dialog-title>Upload Files</md-dialog-title>
    <md-dialog-content>
      <div v-if="isCancelled" style="margin-top: 20px; height: 50px">
        <span> Cancelling upload, please wait.. </span>
      </div>
      <div v-else id="uploadProgressDiv">
        <progress :max="100" :value="uploadProgress"></progress><br />
        {{ uploadProgress }}% completed
      </div>
    </md-dialog-content>
    <md-dialog-actions>
      <md-button class="md-primary" @click="$emit('cancelUpload')"
        >Cancel</md-button
      >
    </md-dialog-actions>
  </md-dialog>
</template>

<script>
import uploadStages from "../enums/UploadStages.js";

export default {
  name: "AppFileDropperProgress",
  props: {
    uploadStage: {
      type: String,
      default() {
        return "";
      },
    },
    uploadProgress: {
      type: Number,
      default() {
        return 0;
      },
    },
  },
  data: () => ({
    uploadStages: uploadStages,
  }),
  computed: {
    isCancelled() {
      return this.uploadStage === uploadStages.CANCELLED;
    },
    isUploading() {
      return this.uploadStage === uploadStages.UPLOADING;
    },
  },
};
</script>
<style scoped>
#file-dropper-container {
  background-color: var(--accent-color-dark);
  border-radius: 10px;
  color: white;
  padding: 5px 0px;
  padding-top: 20px;
  display: flex;
  flex-direction: columns;
}
#filedropper-content {
  background-color: var(--accent-color-light);
  border-radius: 5px;
  align-self: stretch;
  display: flex;
  justify-content: center;
  padding: 60px 10px;
}
#btnCancel {
  align-self: flex-end;
  margin-right: 20px;
  margin-bottom: 20px;
  cursor: pointer;
}
progress {
  height: 60px;
  width: 100%;
}
progress::-moz-progress-bar {
  background: blue;
}
progress::-webkit-progress-value {
  background: blue;
}
progress {
  color: blue;
}
#uploadProgressDiv {
  width: 60%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
