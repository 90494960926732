import Vue from "vue";
import VueRouter from "vue-router";
import App from "./App.vue";
import VueMaterial from "vue-material";
import "vue-material/dist/vue-material.min.css";
import appAdmin from "./components/appAdmin";
import appAdminProgress from "./components/appAdminProgress";
import appAdminWarnings from "./components/appAdminWarnings";
import appAdminContent from "./components/appAdminContent";
import appAdminCommands from "./components/appAdminCommands";
import appAdminBatch from "./components/appAdminBatch";
import appAdminFeatureToggles from "./components/appAdminFeatureToggles";
import appMain from "./components/appMain";
import VueQRCodeComponent from "vue-qr-generator";
import appAdminAudit from "./components/appAdminAudit.vue";
import appInsights from "./plugins/app-insights";

Vue.component("QrCode", VueQRCodeComponent);

Vue.config.productionTip = false;
Vue.use(VueRouter);
Vue.use(VueMaterial);
Vue.use(appInsights);

Vue.directive("click-outside", {
  bind: function (el, binding, vnode) {
    el.clickOutsideEvent = function (event) {
      if (!(el == event.target || el.contains(event.target))) {
        vnode.context[binding.expression](event);
      }
    };
    document.body.addEventListener("click", el.clickOutsideEvent);
  },
  unbind: function (el) {
    document.body.removeEventListener("click", el.clickOutsideEvent);
  },
});

const routes = [
  { path: "/", component: appMain },
  {
    path: "/admin",
    component: appAdmin,
    children: [
      { path: "", component: appAdminProgress },
      {
        path: "progress",
        component: appAdminProgress,
      },
      {
        path: "warnings",
        component: appAdminWarnings,
      },
      {
        path: "content",
        component: appAdminContent,
      },
      {
        path: "commands",
        component: appAdminCommands,
      },
      {
        path: "batch",
        component: appAdminBatch,
      },
      {
        path: "featureToggles",
        component: appAdminFeatureToggles,
      },
      {
        path: "audit",
        component: appAdminAudit,
      },
    ],
  },
];

const router = new VueRouter({
  routes,
});

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
