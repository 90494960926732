<template id="appAssetBreadcrumb">
  <div style="display: flex">
    <div
      v-for="(c, i) in breadcrumbs"
      :key="c.asset.id"
      class="normalBreadcrumb"
      @click="gotoCrumb(c.asset.id)"
    >
      <div v-if="c.asset.id !== 'Home'" class="breadcrumbArrow"></div>
      <md-button>
        <strong>{{ getDisplayName(c, i === breadcrumbs.length - 1) }}</strong>
      </md-button>
    </div>
  </div>
</template>
<script>
import { mapState, mapMutations } from "vuex";
import { bus } from "../eventBus";

export default {
  name: "AppAssetBreadcrumb",
  data() {
    return {
      isHologramDirty: false,
      wasAboutToLeaveTo: null,
    };
  },
  computed: {
    ...mapState(["breadcrumbs", "selectedGroup", "selectedAsset"]),
  },
  watch: {
    async breadcrumbs(breadcrumbs) {
      this.setFilter(breadcrumbs[breadcrumbs.length - 1].filter);

      await this.completeBreadcrumbsHierarchy();
    },
    isHologramDirty() {
      window.onbeforeunload = this.isHologramDirty
        ? function () {
            return true;
          }
        : null;
      if (this.wasAboutToLeaveTo !== null) {
        this.gotoCrumb(this.wasAboutToLeaveTo);
        this.wasAboutToLeaveTo = null;
      }
    },
  },
  created() {
    bus.$on("onSaveStateChanged", (event) => {
      this.isHologramDirty = event.isDirty > 0;
    });
    bus.$on("onSavePromptCancelled", () => {
      this.wasAboutToLeaveTo = null;
    });
  },
  methods: {
    ...mapMutations([
      "setSelectedAsset",
      "setBreadcrumbs",
      "goHome",
      "setFilter",
    ]),
    async gotoCrumb(assetId) {
      if (this.isHologramDirty) {
        this.wasAboutToLeaveTo = assetId;
        this.confirmSave();
        return;
      }
      const breadcrumbs = [...this.breadcrumbs];
      while (breadcrumbs.length > 0) {
        if (breadcrumbs[breadcrumbs.length - 1].asset.id !== assetId) {
          breadcrumbs.pop();
        } else {
          this.setBreadcrumbs(breadcrumbs);
          this.setSelectedAsset(null);
          return;
        }
      }
    },
    confirmSave() {
      bus.$emit("unityUnsavedChangesPrompt");
    },
    getIsDirtyAsterisk() {
      return this.isHologramDirty ? "*" : "";
    },
    getDisplayName(crumb, isLastElement) {
      const asteriskToLastElement = isLastElement
        ? this.getIsDirtyAsterisk()
        : "";
      const displayName = crumb.asset.metadata.displayName;
      if (displayName) return displayName + asteriskToLastElement;

      let type = crumb.asset.metadata._type;
      if (type === "DICOMStudy") return "Study";
      if (type === "Set") return "Hologram" + asteriskToLastElement;
      return type || "Unknown";
    },
    async completeBreadcrumbsHierarchy() {
      let newBreadcrumbs = [];
      for (let i = this.breadcrumbs.length - 1; i > 0; i--) {
        const breadcrumb = this.updateBreadcrumb(this.breadcrumbs[i]);
        newBreadcrumbs.push(breadcrumb);
        this.addMissingParentBreadcrumbs(
          newBreadcrumbs,
          breadcrumb,
          this.breadcrumbs[i - 1]
        );
      }
      newBreadcrumbs.push(this.breadcrumbs[0]);

      if (newBreadcrumbs.length !== this.breadcrumbs.length) {
        newBreadcrumbs.reverse();
        this.setBreadcrumbs(newBreadcrumbs);
      }
    },
    updateBreadcrumb(breadcrumb) {
      if (this.selectedAsset && this.selectedAsset.id === breadcrumb.asset.id) {
        // Set selected asset with parent information
        breadcrumb.asset = this.selectedAsset;
      }
      return breadcrumb;
    },
    addMissingParentBreadcrumbs(newBreadcrumbs, breadcrumb, parentBreadcrumb) {
      let parent = breadcrumb.asset.parent;
      while (parent && parent.id !== parentBreadcrumb.asset.id) {
        newBreadcrumbs.push({
          asset: parent,
          filter: "",
          sort: "_modified",
        });
        parent = parent.parent;
      }
    },
  },
};
</script>
<style scoped>
.md-button {
  margin-left: 0px;
}

.breadcrumbArrow {
  background-image: url("/public/assets/image/Arrow_Turquoise.png");
  background-repeat: no-repeat;
  height: 20px;
  width: 12px;
  background-size: 12px 20px;
  margin-top: 14px;
}

.normalBreadcrumb {
  display: flex;
  flex-direction: row;
  min-width: 1%;
}
</style>
