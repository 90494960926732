<template id="app-search-field">
  <div class="search-field" :class="{ focus }">
    <input
      id="searchInput"
      v-model="localFilter"
      class="search-input"
      :placeholder="focus ? '' : 'Search'"
      type="text"
      @blur="focus = false"
      @focus="focus = true"
      @keydown.enter="setFilterImmediately($event.target.value)"
    />
    <md-button
      v-if="localFilter"
      class="md-icon-button"
      @click="setFilterImmediately('')"
    >
      <md-icon>close</md-icon>
    </md-button>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

export default {
  name: "AppSearchField",
  data() {
    return {
      focus: false,
      timeout: null,
    };
  },
  computed: {
    ...mapGetters(["filter"]),
    localFilter: {
      set(filter) {
        // debounce
        if (this.timeout) clearTimeout(this.timeout);

        this.timeout = setTimeout(() => {
          this.setFilter(filter);
        }, 1000);
      },
      get() {
        return this.filter;
      },
    },
  },
  methods: {
    ...mapMutations(["setFilter"]),
    setFilterImmediately(filter) {
      clearTimeout(this.timeout);
      this.setFilter(filter);
    },
  },
};
</script>

<style scoped>
.search-field {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: linear-gradient(to left bottom, #4c7f90, #4a7e8c);
  margin-left: 8px;
  margin-right: 20px;
  padding-left: 24px;
  padding-right: 10px;
  border-radius: 64px;
  height: 64px;
  min-width: 150px;
  max-width: 360px;
  width: 100%;
  box-shadow: inset 4px 4px 10px rgba(0, 0, 0, 0.4);
}
.search-input {
  border: 0px;
  flex: 1;
  width: 100%;
  height: 40;
  font-size: large;
  background-color: transparent;
}
.search-input::placeholder {
  color: white;
}
.search-input:focus {
  color: white;
  outline: none;
}
</style>
