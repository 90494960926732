<template id="app-asset-page">
  <div style="display: flex; flex-wrap: wrap">
    <!-- The div is hidden using a bound style rather than a v-if,
    as a v-if will destroy the DOM node that the Unity instance is attached to -->
    <div
      style="width: 360px; overflow-y: auto"
      :style="{
        display: showMaximized ? 'none' : 'block',
      }"
    >
      <div style="display: flex; flex-flow: column; height: 100%">
        <div style="flex: 1; overflow-y: auto">
          <span @click="uploadThumbnail">
            <app-asset-thumbnail id="thumbnail"></app-asset-thumbnail>
            <md-tooltip md-direction="bottom"
              >Click thumbnail to change</md-tooltip
            >
          </span>
          <input
            id="selectThumbnail"
            accept="image/png, image/jpeg, image/gif"
            file
            hidden
            type="file"
            @change="onThumbnailUploaded($event)"
          />
          <app-asset-metadata></app-asset-metadata>
        </div>
      </div>
    </div>

    <div style="flex: 5; overflow-y: auto">
      <app-asset-content
        @click="click($event)"
        @deleteasset="deleteAsset"
        @deletehologram="deleteHologram"
        @deletepatient="deletePatient"
      ></app-asset-content>
    </div>

    <!-- The div is hidden using a bound style rather than a v-if,
    as a v-if will destroy the DOM node that the Unity instance is attached to -->
    <div
      style="width: 360px; overflow-y: auto"
      :style="{
        display: showMaximized ? 'none' : 'block',
      }"
    >
      <div style="display: flex; flex-flow: column; height: 100%">
        <div style="flex: 1; overflow-y: auto">
          <div class="fileDrop">
            <app-file-dropper accept="*"> </app-file-dropper>
          </div>
          <app-asset-formats
            @deleteformat="deleteFormatConfirm($event)"
            @downloadformat="downloadFormat($event)"
            @exploreformat="exploreFormat($event)"
            @setactive="setRevisionActive($event)"
            @togglelock="toggleRevisionLock($event)"
          ></app-asset-formats>
        </div>
      </div>
    </div>

    <!-- only enable dialogs when in asset: otherwise the dialogs have to do much checking -->
    <div v-if="selectedGroup && selectedAsset">
      <md-dialog
        :md-active.sync="showExplorer"
        @md-closed="formatToExplore = null"
      >
        <md-dialog-title>Asset Format Content</md-dialog-title>
        <app-asset-format-explorer
          :format="formatToExplore"
        ></app-asset-format-explorer>
        <md-dialog-actions>
          <md-button class="md-primary" @click="showExplorer = false"
            >Close</md-button
          >
        </md-dialog-actions>
      </md-dialog>

      <md-dialog :md-active.sync="showDeleteConfirm">
        <md-dialog-title>Are you sure?</md-dialog-title>
        <md-dialog-content v-if="formatToDelete">
          Are you sure you want to delete {{ formatToDelete.id }} ?
        </md-dialog-content>
        <md-dialog-actions>
          <md-button
            class="md-primary md-raised"
            @click="deleteFormat(formatToDelete)"
            >Delete</md-button
          >
          <md-button class="md-primary" @click="showDeleteConfirm = false"
            >Cancel</md-button
          >
        </md-dialog-actions>
      </md-dialog>
      <md-dialog v-if="deleteErrorMessage" md-active="true">
        <md-dialog-title>Error occurred</md-dialog-title>
        <md-dialog-content>
          {{ deleteErrorMessage }}
        </md-dialog-content>
        <md-dialog-actions>
          <md-button
            class="md-primary md-raised"
            @click="deleteErrorMessage = null"
            >Ok</md-button
          >
        </md-dialog-actions>
      </md-dialog>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
import appSettings from "./appSettings";
import appAssetContent from "./appAssetContent";
import appAssetFormatExplorer from "./appAssetFormatExplorer";
import appAssetThumbnail from "./appAssetThumbnail";
import appAssetMetadata from "./appAssetMetadata";
import appAssetFormats from "./appAssetFormats";
import appFileDropper from "./appFileDropper";

export default {
  name: "AppAssetPage",
  components: {
    "app-asset-content": appAssetContent,
    "app-asset-format-explorer": appAssetFormatExplorer,
    "app-asset-formats": appAssetFormats,
    "app-asset-metadata": appAssetMetadata,
    "app-file-dropper": appFileDropper,
    "app-asset-thumbnail": appAssetThumbnail,
  },
  data: () => {
    return {
      showNavigation: false,
      showSidepanel: false,
      showExplorer: false,
      showQR: false,
      showDeleteConfirm: false,
      deleteErrorMessage: null,
      type: "unknown",
      formatToDelete: null,
      formatToExplore: null,
    };
  },
  computed: {
    ...mapState([
      "selectedAsset",
      "selectedGroup",
      "user",
      "idToken",
      "isMaximized",
    ]),
    ...mapGetters(["showUnity"]),
    showMaximized() {
      return this.isMaximized && this.showUnity;
    },
  },
  methods: {
    ...mapMutations([
      "setErrorMessage",
      "setIsMaximized",
      "updateFormatInStore",
      "deleteAssetFormatFromState",
      "updateThumbnailInMetadata",
    ]),
    ...mapActions([
      "deleteAzure",
      "fetchAzure",
      "putAzure",
      "postAzure",
      "postAzureForm",
    ]),
    uploadThumbnail() {
      document.querySelector("#selectThumbnail").click();
    },
    async onThumbnailUploaded(event) {
      if (event.target.files.length === 0) return;

      const file = event.target.files[0];
      const formData = new FormData();
      formData.append(file.name, file);
      const relativeUrl = `/groups/${this.selectedGroup.id}/assets/${this.selectedAsset.id}/thumb`;
      const url = `${appSettings.remoteurl}/api${relativeUrl}`;
      const responseType = "text";
      await this.postAzureForm({ url, formData, responseType });
      this.updateThumbnailInMetadata(`${relativeUrl}/${file.name}`);
    },
    back() {
      this.$router.go(-1);
    },
    async downloadFormat(format) {
      this.download(format);
    },
    async exploreFormat(format) {
      this.showExplorer = true;
      this.formatToExplore = format;
    },
    async download(format) {
      let url = await this.getDownloadUrl(format);
      url += `?access_token=${this.idToken}`;
      var a = document.createElement("a");
      a.href = url;
      a.download = format
        ? `${this.selectedAsset.id}_${format.id}.zip`
        : `${this.selectedAsset.id}.zip`;
      document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
      a.click();
      a.remove(); //afterwards we remove the element again
    },
    async getDownloadUrl(format) {
      let url = `${appSettings.remoteurl}/api/groups/${this.selectedGroup.id}/assets/${this.selectedAsset.id}`;
      if (format) {
        url += `/formats/${format.id}`;
      }
      if (format.metadata._type !== "SET") return url + "/zip";

      // For hologram just download the .set file
      const files = await this.fetchAzure(
        `${appSettings.remoteurl}/api/groups/${this.selectedGroup.id}/assets/${this.selectedAsset.id}/` +
          `formats/${format.id}/files`
      );
      if (files.length != 1) {
        this.setErrorMessage("one file expected, found " + files.length);
        throw "one file expected, found " + files.length;
      }
      url += `/files/${files[0].id}`;
      return url;
    },
    async deleteFormatConfirm(format) {
      this.showDeleteConfirm = true;
      this.formatToDelete = format;
      window.scrollTo(0, 0);
    },
    async deleteFormat(format) {
      const formatId = format.id;
      this.deleteAssetFormatFromState(formatId);
      this.showDeleteConfirm = false;

      const url = `${appSettings.remoteurl}/api/groups/${this.selectedGroup.id}/assets/${this.selectedAsset.id}/formats/${formatId}`;
      await this.deleteAzure({ url });
    },
    async toggleRevisionLock(format) {
      const lockAction = format.locked ? "unlock" : "lock";
      const url = `${appSettings.remoteurl}/api/groups/${this.selectedGroup.id}/holograms/${this.selectedAsset.id}/revisions/${format.id}/${lockAction}`;
      await this.postAzure({ url, responseType: "text" });
      this.updateFormatInStore({
        id: format.id,
        locked: !format.locked,
        metadata: {},
      });
    },
    async setRevisionActive(format) {
      const url = `${appSettings.remoteurl}/api/groups/${this.selectedGroup.id}/holograms/${this.selectedAsset.id}/revisions/${format.id}/select`;
      await this.putAzure({ url, responseType: "text" });
      this.updateFormatInStore({
        id: format.id,
        locked: format.locked,
        selected: true,
        metadata: {},
      });
    },
    handleDeleteError(e) {
      this.deleteErrorMessage = e.details || e.message;
    },
    handleDeleteSuccess() {
      this.$emit("asset-deleted", this.selectedAsset);
    },
    async deleteAsset() {
      this.deleteAssetOfType("asset");
    },
    async deletePatient() {
      this.deleteAssetOfType("patient");
    },
    async deleteHologram() {
      this.deleteAssetOfType("hologram");
    },
    async deleteAssetOfType(type) {
      const url = `${appSettings.remoteurl}/api/groups/${this.selectedGroup.id}/${type}s/${this.selectedAsset.id}`;
      this.deleteAzure({
        url,
        errorHandler: this.handleDeleteError,
        successHandler: this.handleDeleteSuccess,
      });
    },
  },
};
</script>

<style scoped>
#thumbnail {
  cursor: pointer;
}
</style>
