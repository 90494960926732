<template id="appAdmin">
  <div id="adminPage">
    <md-button><router-link to="/">Asset View</router-link></md-button>
    <md-button
      ><router-link to="/admin/progress">Progress</router-link></md-button
    >
    <md-button
      ><router-link to="/admin/warnings">Warnings</router-link></md-button
    >
    <md-button v-if="isAdmin"
      ><router-link to="/admin/content">Content</router-link></md-button
    >
    <md-button
      ><router-link to="/admin/commands">Commands</router-link></md-button
    >
    <md-button v-if="isAdmin"
      ><router-link to="/admin/batch">Batch Delete</router-link></md-button
    >
    <md-button
      ><router-link to="/admin/featureToggles"
        >Feature Toggles</router-link
      ></md-button
    >
    <md-button v-if="allowedFeatureToggles.includes('audit_reader') || isAdmin"
      ><router-link to="/admin/audit">Audit Data</router-link></md-button
    >
    <router-view></router-view>
  </div>
</template>
<script>
import { mapGetters, mapState, mapActions, mapMutations } from "vuex";

export default {
  name: "AppAdmin",
  computed: {
    ...mapGetters(["isAdmin"]),
    ...mapState(["allowedFeatureToggles"]),
  },
  async created() {
    if (this.allowedFeatureToggles.length < 1) {
      this.updateFeatureToggles();
    }
  },
  methods: {
    ...mapMutations(["setAllowedFeatureToggles"]),
    ...mapActions(["fetchFeatureToggles"]),
    async updateFeatureToggles() {
      var toggles = await this.fetchFeatureToggles();
      this.setAllowedFeatureToggles(toggles.map((x) => x.id));
    },
  },
};
</script>
<style></style>
