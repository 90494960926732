import { ApplicationInsights } from "@microsoft/applicationinsights-web";

export default {
  install(Vue) {
    Vue.appInsights = {
      loaded: false,
      load: (connectionString) => {
        Vue.appInsights = new ApplicationInsights({
          config: {
            connectionString,
          },
        });
        Vue.appInsights.load = () => {};
        Vue.appInsights.loaded = true;
        Vue.appInsights.loadAppInsights();
      },
    };

    Object.defineProperty(Vue.prototype, "$appInsights", {
      get: () => Vue.appInsights,
    });
  },
};
