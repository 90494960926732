<template id="appAdminWarnings">
  <div id="editPage">
    <div id="editHeader">
      <div id="refresh">
        <i class="fa fa-refresh" @click="loadHologramsWithWarnings()"></i>
      </div>
      <md-field style="padding: 0px; margin: 0px; width: 180px"
        ><div class="label">Max per group:</div>
        <md-input v-model="maxWarnings" style="width: 50px"
      /></md-field>
      <md-field style="padding: 0px; margin: 10px"
        >Click on a row to go to the hologram</md-field
      ><md-field v-if="currentGroupName" style="padding: 0px; margin: 10px">
        Querying group {{ currentGroupName }}
      </md-field>
    </div>
    <md-table>
      <md-table-row>
        <md-table-head>Group Name</md-table-head>
        <md-table-head>Hologram Id</md-table-head>
        <md-table-head>Hologram Name</md-table-head>
        <md-table-head>Created</md-table-head>
        <md-table-head>Warning</md-table-head>
      </md-table-row>
      <md-table-row
        v-for="item in warnings"
        :key="item.id"
        @click="goToHologram(item)"
      >
        <md-table-cell>{{ item.groupName }}</md-table-cell>
        <md-table-cell>{{ item.id }}</md-table-cell>
        <md-table-cell>{{ item.metadata.displayName }}</md-table-cell>
        <md-table-cell>{{ formatDate(item.metadata._modified) }}</md-table-cell>
        <md-table-cell>{{ item.metadata.warning }}</md-table-cell>
      </md-table-row>
    </md-table>
  </div>
</template>
<script>
import appSettings from "./appSettings";
import { mapActions, mapState } from "vuex";
import moment from "moment";

export default {
  name: "AppAdminWarnings",
  data: () => ({
    maxWarnings: 50,
    currentGroupName: "",
    warnings: [],
    querying: false,
  }),
  computed: {
    ...mapState(["assetGroups"]),
  },
  watch: {
    async maxWarnings() {
      await this.loadHologramsWithWarnings();
    },
  },
  async created() {
    await this.loadHologramsWithWarnings();
  },
  methods: {
    ...mapActions(["fetchAzure"]),
    goToHologram(item) {
      window.location.href = `/${item.groupId}/${item.id}`;
    },
    formatDate(date) {
      return date && moment(date).format("yyyy-MM-DD");
    },
    async loadHologramsWithWarnings() {
      if (this.querying) return;

      this.querying = true;
      try {
        const notEmpty = encodeURIComponent(".+");
        const queryString = `_sort=-_modified&_type=Set&_count=${this.maxWarnings}&warning=${notEmpty}`;
        this.warnings = [];
        for (let group of this.assetGroups) {
          this.currentGroupName = group.metadata.displayName;
          const url = `${appSettings.remoteurl}/api/groups/${group.id}/assets?${queryString}`;
          const warnings = await this.fetchAzure(url);
          const warningsWithGroupName = warnings.map((asset) => ({
            groupId: group.id,
            groupName: this.currentGroupName,
            ...asset,
          }));
          this.warnings = this.warnings.concat(warningsWithGroupName);
        }
      } finally {
        this.querying = false;
        this.currentGroupName = "";
      }
    },
  },
};
</script>
<style scoped>
#showing {
  margin: 10px;
  margin-left: 20px;
}
#refresh {
  margin: 10px;
}
#editHeader {
  display: flex;
  flex-direction: row;
}
#editDetails {
  margin-left: 20px;
}
.md-field .md-input {
  background-color: #3d8094;
  margin-top: 4px;
  border-radius: 8px;
}
.md-field .label {
  margin: 10px;
}
.label {
  width: 200px;
}
</style>
