<template>
  <div class="typeButtons">
    <div
      v-for="t in types"
      :key="t.key"
      class="filterButtonContainer"
      :value="t.key"
    >
      <md-button
        class="filterButton"
        :class="{ 'md-raised': t.key === typeFilter }"
        @click="clickedFilterType(t.key)"
        ><div
          class="filterIcon"
          :style="{
            backgroundImage: 'url(/assets/icon/' + t.key + 'Icon.svg)',
          }"
        >
          &nbsp;
        </div></md-button
      >
      <div class="typeName">{{ t.value }}</div>
    </div>
  </div>
</template>
<script>
import { mapMutations, mapState } from "vuex";
export default {
  name: "AppAssetFilterDropdown",
  data() {
    return {
      types: [
        { key: "All", value: "All" },
        { key: "Person", value: "Person" },
        { key: "DICOMStudy", value: "Study" },
        { key: "Set", value: "Hologram" },
      ],
    };
  },
  computed: {
    ...mapState(["typeFilter"]),
  },
  methods: {
    ...mapMutations(["setTypeFilter"]),
    clickedFilterType(type) {
      this.setTypeFilter(type);
    },
  },
};
</script>
<style scoped>
.typeButtons {
  margin-top: 5px;
  display: flex;
  flex-direction: row;
}
.filterButtonContainer {
  width: 60px;
  margin: 2px;
  position: relative;
  text-align: center;
  overflow: visible;
}
.filterButton {
  text-transform: none;
  font-size: 14px;
  min-width: 1%;
  height: 48px;
  width: 48px;
  margin-left: 0px;
  margin-right: 0px;
  background-blend-mode: difference;
  background-color: #3d8094;
}
.filterIcon {
  margin: auto;
  width: 20px;
  height: 20px;
  background-size: 20px 20px;
  background-repeat: no-repeat;
  background-blend-mode: difference;
}

.typeName {
  color: white;
  font-family: "Open Sans", sans-serif;
  font-weight: 300;
  text-align: center;
}
.md-button.md-theme-default.md-raised:not([disabled]) {
  background: #5e9fb0;
}
.md-button {
  border-radius: 50% !important;
  color: white !important;
}
</style>
