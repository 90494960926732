const extensionToAssetType = {
  ".dcm": "DICOMStudy",
  ".set": "Set",
  ".gltf": "Surface",
  ".glb": "Surface",
  ".stl": "Surface",
  ".vol": "Volume",
  ".nii.gz": "Surface",
  ".off": "PointCloud",
  ".pnt": "PointCloud",
};

export default class FileUploadInfo {
  constructor(files, analyzedFileInfo) {
    this.files = files || [];

    this.assetType = null;
    this.isDicom = false;
    this.anonymizeSupported = true;
    this.isStl = false;
    this.patientAsset = null;
    this.assetGroupId = null;

    this.PatientID = null;
    this.PatientName = null;
    this.PatientBirthDate = null;
    this.PatientSex = null;

    let merged = Object.assign(this, analyzedFileInfo);
    merged._detectAssetType();
    return merged;
  }
  _detectAssetType() {
    if (this.isDicom) {
      // DICOM is detected outside of this class
      this.assetType = "DICOMStudy";
    } else if (this._containsStlFiles()) {
      this.assetType = "Surface";
      this.isStl = true;
    } else this.assetType = this._mapFileExtension();
  }
  _mapFileExtension() {
    for (let file of this.files) {
      const fileName = file.file.name;
      let extension = fileName.endsWith(".gz")
        ? fileName.replace(/^.*(\.[^/.]+\.gz)$/, "$1")
        : fileName.replace(/^.*(\.[^/.]+)$/, "$1");
      const assetType = extensionToAssetType[extension];
      if (assetType) {
        return assetType;
      }
    }
    return "Unknown";
  }
  _containsStlFiles() {
    return (
      this.files.filter((file) => {
        const fileName = file.file.name;
        return fileName.toLowerCase().includes(".stl");
      }).length > 0
    );
  }
}
