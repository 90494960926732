<template id="app-asset-list">
  <div id="container">
    <div class="containers" :class="{ center: assets.length === 0 }">
      <div v-if="!isReadOnlyGroup" id="addButton" @click="setCreating(true)">
        <div
          class="addIcon"
          :style="{
            backgroundImage: 'url(/assets/icon/AddIcon.svg)',
          }"
        ></div>
        <div class="addText">Add</div>
      </div>
      <div v-for="asset in assets" :key="asset.id">
        <app-asset-tile :asset="asset"></app-asset-tile>
      </div>
      <div v-if="assets.length === 0" class="padded centered">
        <div>
          <!--h3>No results</h3-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import appAssetTile from "./appAssetTile";
import { mapState, mapMutations, mapGetters } from "vuex";

export default {
  name: "AppAssetList",
  components: {
    "app-asset-tile": appAssetTile,
  },
  computed: {
    ...mapGetters(["isReadOnlyGroup"]),
    ...mapState(["assets", "creating", "selectedGroup"]),
  },
  methods: {
    clickAsset(asset) {
      this.selectedAsset(asset);
    },
    ...mapMutations(["selectedAsset", "setCreating"]),
  },
};
</script>

<style>
.containers {
  margin-top: 30px;
  display: flex;
  flex: 1;
  flex-flow: row;
  flex-wrap: wrap;
  overflow: auto;
  align-content: flex-start;
  justify-content: flex-start;
}
#container {
  display: flex;
}
#addButton {
  background-color: #2d6b7d;
  box-shadow: 6px 6px 8px rgba(0, 0, 0, 0.6);
  width: 196px;
  height: 263px;
  margin: 20px;
  border-radius: 10px;
  cursor: pointer;
  position: relative;
  transition: 0.4s;
}
#addButton:hover {
  background-color: #53899b;
}
#addButton .addText {
  top: 60%;
  position: absolute;
  width: 100%;
  font-size: 21px;
  color: white;
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}
.addIcon {
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translateX(-50%);
  width: 36px;
  height: 36px;
}
.center {
  text-align: center;
  display: flex;
  align-items: center;
}
.centered {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
</style>
