<template>
  <div id="groupDropdown">
    <multiselect
      v-model="currentGroup"
      :allow-empty="false"
      :custom-label="getAssetGroupDisplayName"
      deselect-label=""
      :options="assetGroups"
      placeholder="Start typing to search..."
      :searchable="true"
      select-label=""
      selected-label=""
    ></multiselect>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import multiselect from "vue-multiselect";

export default {
  name: "AppGroupDropDown",
  components: {
    multiselect,
  },

  data: () => ({
    currentGroup: "",
  }),
  computed: {
    ...mapState(["assetGroups", "selectedGroup"]),
  },
  watch: {
    currentGroup(group) {
      this.setSelectedGroup(group);
    },
    assetGroups() {
      this._populateDropDown();
      // After loading the whole list, the selected asset group should be set to the real group
      // rather than the one cached in local storage
      this.setSelectedGroup(this.currentGroup);
    },
  },
  created() {
    this._populateDropDown();
  },
  methods: {
    ...mapMutations(["setSelectedGroup"]),
    _populateDropDown() {
      if (this.selectedGroup) {
        this.currentGroup = this.assetGroups.find(
          (g) => g.id === this.selectedGroup.id
        );
      }
    },
    getAssetGroupDisplayName(asd) {
      return asd.metadata.displayName;
    },
  },
};
</script>
<style src="./stylesheets/multiselect.css"></style>
<style scoped>
input {
  color: white !important;
  -webkit-text-fill-color: white !important;
}
#groupDropdown {
  min-width: 224px;
  width: 400px;
  margin-right: 10px;
  position: relative;
}
</style>
<style scoped>
::v-deep .multiselect__tags {
  height: 64px;
  border-radius: 30px;
  box-shadow: inset 2px 4px 10px rgba(0, 0, 0, 0.5);
}
::v-deep .multiselect__single {
  line-height: 48px;
  margin-left: 8px;
}
::v-deep .multiselect__input {
  height: 48px;
  margin-left: 8px;
}
::v-deep .multiselect__select {
  top: 12px;
}
::v-deep .multiselect--active {
  border-radius: 30px;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.4);
}
::v-deep .multiselect__content-wrapper {
  border-radius: 10px;
  box-shadow: 3px 10px 20px rgba(0, 0, 0, 0.4);
}
</style>
