export default class CookieUtility {
  static getCookies() {
    const out = {};
    const cookies = document.cookie.split(";");
    if (cookies.length == 0) return out;
    cookies.forEach((e) => {
      const keyValue = e.split("=");
      if (keyValue.length === 2) {
        out[keyValue[0]] = keyValue[1];
      }
    });
    return out;
  }

  static hasCookie(cookieName) {
    return cookieName in CookieUtility.getCookies();
  }

  static getCookieOrDefault(cookieName) {
    const cookies = CookieUtility.getCookies();
    return cookieName in cookies ? cookies[cookieName] : null;
  }

  static setCookie(cookieName, cookieValue) {
    document.cookie = `${cookieName}=${cookieValue}`;
  }
}
