<template>
  <div id="optionlist">
    <label class="switch">
      <input
        v-model="sortType"
        type="checkbox"
        value="sortType"
        @change="setSortType()"
      />
      <span class="slider">
        <div class="toggleText">Last</div>
        <div class="toggleText">A-Z</div>
      </span>
    </label>
  </div>
</template>
<script>
import { mapGetters, mapMutations } from "vuex";
export default {
  name: "AppSortAssetList",
  data: () => ({
    options: [
      { key: "_modified", value: "Last" },
      { key: "displayName", value: "A-Z" },
    ],
    selectedOption: "_modified",
    sortType: false,
  }),
  computed: {
    ...mapGetters(["lastBreadcrumb"]),
  },
  watch: {
    lastBreadcrumb(breadcrumb) {
      this.selectedOption = breadcrumb.sort;
    },
  },
  created() {
    this.selectedOption = this.lastBreadcrumb.sort;
    if (this.selectedOption === this.options[1].key) this.sortType = true;
    else this.sortType = false;
  },
  methods: {
    ...mapMutations(["setSort"]),
    setSortType() {
      if (this.sortType) {
        this.selectedOption = this.options[1].key;
        this.setSort(this.options[1].key);
      } else {
        this.selectedOption = this.options[0].key;
        this.setSort(this.options[0].key);
      }
    },
  },
};
</script>
<style scoped>
#optionlist {
  display: flex;
  justify-content: space-around;
  margin-right: 10px;
}
.md-button.md-theme-default.md-raised:not([disabled]) {
  background: linear-gradient(to left bottom, #4c7f90, #4a7e8c);
}
.md-button {
  color: white !important;
}

/*Sorting Toggle related styling*/
.switch {
  position: relative;
  display: inline-block;
  width: 128px;
  height: 64px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #468799;
  transition: 0.4s;
  padding: 4px;
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.5);
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 56px;
  width: 56px;
  top: 50%;
  transform: translateY(-50%);
  background-color: #4d90a3;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider:before {
  transform: translate(64px, -50%);
}

.toggleText {
  position: absolute;
  color: white;
  display: inline-block;
  line-height: 58px;
  width: 50%;
  text-align: center;
  user-select: none;
}
.slider :first-child {
  left: 0;
}
.slider :last-child {
  right: 0;
}
</style>
