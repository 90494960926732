<template id="app-asset-formats">
  <div id="metadatacontainer">
    <h1>{{ titleText }}</h1>
    <div id="formatList">
      <div
        v-for="format in formats"
        :key="format.id"
        class="formatTile"
        :class="{
          hologram: isHologram(format),
          'selected-hologram': isSelectedHologram(format),
          'selected-revision': selectedRevision === format.id,
        }"
      >
        <div
          class="revision-verified-box"
          :class="{
            checked: format.verified,
          }"
        ></div>
        <div class="select-revision-zone" @click="selectRevision(format)">
          <div v-if="isSelectedHologram(format)" class="active-revision">
            Active
          </div>
          <div
            class="tile-date"
            :class="{
              selected: isSelectedHologram(format),
            }"
          >
            {{ displayText(format) }}
          </div>
          <div>
            {{ format.metadata._author || "Author unknown" }}
          </div>
        </div>
        <div>
          <i
            v-if="isHologram(format)"
            class="fa"
            :class="{
              disabled: isReadOnlyGroup,
              'fa-lock': format.locked,
              'fa-lock-open': !format.locked,
            }"
            @click="toggleLock(format)"
          ></i>
          <i
            v-if="!isHologram(format)"
            class="fa fa-folder"
            :class="{ disabled: !isReadOnlyGroup }"
            @click="clickExplore(format)"
          ></i>
          <i
            class="fa fa-ellipsis-v ellipsis"
            @click="showEllipsisMenu(format, $event)"
          >
          </i>
        </div>
      </div>
      <md-button
        v-if="selectedAsset && selectedAsset.metadata._type === 'Volume'"
        class="md-primary md-raised"
        @click="generateNifti()"
        >Generate Nifti</md-button
      >
    </div>
    <div id="ellipsis-menu">
      <div
        v-if="selectedEllipsisMenu"
        v-click-outside="onFocusOut"
        class="ellipsis-menu"
      >
        <ul>
          <li @click="setRevisionActive(selectedEllipsisMenu)">Set Active</li>
          <li
            :class="{ disabled: isReadOnlyGroup }"
            @click="clickDownload(selectedEllipsisMenu)"
          >
            Download Data File
          </li>
          <li
            :class="{ disabled: isReadOnlyGroup }"
            @click="clickDelete(selectedEllipsisMenu)"
          >
            Delete
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import appSettings from "./appSettings";
import featureToggles from "../enums/FeatureToggles.js";
import { mapState, mapGetters, mapActions } from "vuex";
import { bus } from "../eventBus.js";

export default {
  name: "AppAssetFormats",
  data: () => ({
    featureToggles: featureToggles,
    selectedRevision: null,
    selectedEllipsisMenu: null,
  }),
  computed: {
    ...mapState([
      "selectedGroup",
      "selectedAsset",
      "allowedFeatureToggles",
      "isMaximized",
    ]),
    ...mapGetters(["isReadOnlyGroup"]),
    formats() {
      if (!this.selectedAsset || !this.selectedAsset.formats) return [];
      const metadata = this.selectedAsset.metadata;
      const outputFormats =
        metadata._type === "Set"
          ? this.getFormatsOfType(["SET"])
          : metadata._type === "Volume"
          ? this.getFormatsOfType(["VOLUME", "NIFTI"])
          : this.selectedAsset.formats;
      outputFormats.sort(
        (a, b) => new Date(b.metadata._created) - new Date(a.metadata._created)
      );
      return outputFormats;
    },
    titleText() {
      if (!this.selectedAsset) return "";
      const metadata = this.selectedAsset.metadata;
      return metadata._type === "Set"
        ? "Revisions"
        : metadata._type === "Volume"
        ? "Volumes"
        : "Asset Formats";
    },
  },
  watch: {
    "$store.state.isMaximized": function () {
      if (this.isMaximized) {
        this.selectedRevision = null;
      }
    },
    "$store.state.selectedAsset": function () {
      this.selectedRevision = null;
    },
  },
  methods: {
    ...mapActions(["getAzure", "postAzure"]),
    generateNifti() {
      const url = `${appSettings.remoteurl}/api/groups/${this.selectedGroup.id}/assets/${this.selectedAsset.id}/requestFormat?formatType=VOLUME&maxSize=original&orientation=original&bufferType=niftiFloat`;
      this.getAzure({
        url,
      });
    },
    getFormatsOfType(types) {
      return this.selectedAsset.formats.filter((x) =>
        types.includes(x.metadata._type)
      );
    },
    onFocusOut(event) {
      if (event.srcElement.classList.contains("ellipsis")) return;
      this.selectedEllipsisMenu = null;
    },
    clickDelete(format) {
      this.$emit("deleteformat", format);
      this.selectedEllipsisMenu = null;
    },
    clickDownload(format) {
      this.$emit("downloadformat", format);
      this.selectedEllipsisMenu = null;
    },
    clickExplore(format) {
      this.$emit("exploreformat", format);
    },
    toggleLock(format) {
      this.$emit("togglelock", format);
    },
    displayText(format) {
      const postfix = format.metadata.displayName
        ? ` (${format.metadata.displayName})`
        : "";
      if (this.isHologram(format)) {
        const modifiedTime = this.localTime(format.metadata._created);
        return modifiedTime + postfix;
      }
      return format.metadata._type + postfix;
    },
    isHologram(format) {
      return format.metadata._type === "SET";
    },
    isSelectedHologram(format) {
      if (!this.isHologram(format)) return false;
      const formats = this.formats;
      if (formats.some((x) => x.selected)) {
        return format.selected;
      }
      formats.sort(
        (a, b) => new Date(b.metadata._created) - new Date(a.metadata._created)
      );
      return this.formats[0].id === format.id;
    },
    setRevisionActive(format) {
      this.$emit("setactive", format);
      this.selectedEllipsisMenu = null;
      this.selectRevision(format);
    },
    selectRevision(format) {
      const re = /\/groups\/([0-9]*)\/assets\/([0-9]*)/;
      const match = re.exec(format.metadata._root);
      const groupId = match[1];
      const assetId = match[2];
      bus.$emit("unityUpdateEvent", {
        eventType: "AssetEvent.FormatSelected",
        data: JSON.stringify({
          groupId: groupId,
          assetId: assetId,
          formatId: format.id,
        }),
      });
      this.selectedRevision = format.id;
    },
    localTime(date) {
      return date && moment(moment.utc(date).toDate()).format("lll");
    },
    showEllipsisMenu(format, event) {
      const ellipsisMenu = document.getElementById("ellipsis-menu");
      const offsetFromTopOfEllipsis = 30;
      ellipsisMenu.style.top =
        event.srcElement.offsetTop + offsetFromTopOfEllipsis + "px";
      ellipsisMenu.style.left =
        event.srcElement.offsetLeft +
        event.srcElement.clientWidth -
        ellipsisMenu.clientWidth +
        "px";

      this.selectedEllipsisMenu =
        this.selectedEllipsisMenu?.id === format.id ? null : format;
    },
  },
};
</script>

<style scoped>
#metadatacontainer {
  background-color: #2d6b7d;
  box-shadow: 3px 3px 20px black;
  border-radius: 10px;
  color: white;
  display: flex;
  margin: 20px 10px;
  align-items: center;
  flex-direction: column;
}
#metadatacontainer h1 {
  font-weight: bold;
}

ul.md-list.md-theme-default {
  background-color: transparent !important;
}

#formatList {
  background: linear-gradient(to left bottom, #5daec7, #3989a1);
  align-self: stretch;
  border-radius: 5px;
  background-color: transparent;
  padding: 7px 12px;
}

#formatList span {
  color: white;
  font-size: 18px;
}

#formatList i {
  padding: 7px;
  color: white;
}

.formatTile {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  padding: 10px 8px;
}
.disabled {
  opacity: 0.5;
}
.hologram {
  opacity: 0.6;
}
.hologram.selected-hologram {
  background-color: #32a681;
  opacity: 1;
}
.hologram .selected {
  color: white;
  font-weight: bold;
  opacity: 1;
}
.fa {
  cursor: pointer;
}

.tile-date {
  font-size: 16px;
  padding-top: 4px;
  padding-bottom: 4px;
}

.revision-verified-box {
  width: 24px;
  height: 24px;
  background-size: 24px 24px;
  background-image: url("/public/assets/image/checkmark_unchecked.png");
  background-repeat: no-repeat;
}

.select-revision-zone {
  cursor: pointer;
  width: 180px;
}

.revision-verified-box.checked {
  background-image: url("/public/assets/image/checkmark_checked.png");
}

.selected-revision {
  background-color: #2d6b7d;
}

#ellipsis-menu {
  position: absolute;
  width: 176px;
}

.ellipsis-menu {
  border-radius: 5px;
  background-color: #204a57;
  padding: 2px;
}

.ellipsis-menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
  margin-left: 19px;
}

.ellipsis-menu li {
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer;
}
</style>
