<template id="app-dicom-upload">
  <md-dialog
    :md-active="needsPatientInfo"
    :md-click-outside-to-close="false"
    :md-close-on-esc="false"
  >
    <md-dialog-title>Patient Information</md-dialog-title>
    <md-dialog-content id="anonymizer">
      <div id="anonymizerInputAndPreview">
        <div id="anonymizerPreview">
          <div v-if="isDicomUpload">
            <div v-if="patientAsset">
              <div>Study will be added to existing patient</div>
              <div>with name {{ patientAsset.metadata.PatientName }}</div>
            </div>
            <div v-else>
              <div>Patient with this ID does not exist,</div>
              <div>a new patient will be created.</div>
            </div>
          </div>
          <div v-else-if="patientAsset">
            Patient already exists, please change ID.
          </div>

          <div class="previewTileContainer">
            <app-asset-tile
              :asset="patientPreviewInfo"
              class="previewTile"
            ></app-asset-tile>
          </div>
        </div>
        <div id="anonymizerInput">
          <md-checkbox
            v-if="anonymizeSupported"
            id="anonymizeDicom"
            v-model="anonymizeDicom"
            class="dialogCheckbox"
            >Anonymize DICOM</md-checkbox
          >
          <md-field class="labelAndInput">
            <div>Lumi ID</div>
            <md-input
              id="lumiId"
              v-model="PatientID"
              placeholder="Lumi Patient Id"
              spellcheck="false"
              @keyup="validateInput"
            />
            <div class="error">Error</div>
          </md-field>
          <md-field class="labelAndInput">
            <div>First Name</div>
            <md-input
              id="firstName"
              v-model="GivenName"
              autocomplete="off"
              placeholder="First Name"
              spellcheck="false"
              @keyup="validateInput"
            />
            <div class="error"></div>
          </md-field>
          <md-field class="labelAndInput">
            <div>Last Name</div>
            <md-input
              id="lastName"
              v-model="FamilyName"
              autocomplete="off"
              placeholder="Last Name"
              spellcheck="false"
              @keyup="validateInput"
            />
            <div class="error"></div>
          </md-field>
          <md-field class="labelAndInput">
            <div>Gender</div>
            <div class="radioContainer">
              <md-radio v-model="PatientSex" value="M">Male</md-radio>
              <md-radio v-model="PatientSex" value="F">Female</md-radio>
              <md-radio v-model="PatientSex" value="U">Other</md-radio>
            </div>
          </md-field>
          <md-field
            class="labelAndInput"
            :class="{ invalidInput: !isValidBirthDateInput }"
          >
            <div>Birth Date</div>
            <md-datepicker
              ref="birthdatePicker"
              v-model="PatientBirthDate"
              class="noAfter"
              md-immediately
              :md-model-type="String"
              :md-open-on-focus="false"
              ><label>yyyy-mm-dd</label></md-datepicker
            >
            <div class="datePreview">
              {{ formattedBirthDate }}
            </div>
          </md-field>
        </div>
        <div>
          <div v-if="generateLumiId" class="arrow"></div>
          <div v-else class="noArrow"></div>
        </div>
        <div>
          <div id="lumiIdInput">
            <md-checkbox v-model="generateLumiId" class="dialogCheckbox"
              >Generate Lumi ID</md-checkbox
            >
            <div v-if="generateLumiId">
              <md-field class="labelAndInput">
                <div>Patient ID (hospital)</div>
                <md-input
                  id="lumiInputPatientID"
                  v-model="lumiInputPatientID"
                  placeholder="Patient Id"
                  @keyup="validateInput"
                />
                <div class="error"></div>
              </md-field>
              <md-field class="labelAndInput">
                <div>Birth Date</div>
                <md-datepicker
                  ref="lumiInputBirthDatePicker"
                  v-model="lumiInputBirthDate"
                  class="noAfter"
                  :class="{ invalidInput: !isValidBirthDateInput }"
                  md-immediately
                  :md-model-type="String"
                  :md-open-on-focus="false"
                  ><label>yyyy-mm-dd</label></md-datepicker
                >
                <div class="datePreview">
                  {{ formattedBirthDate }}
                </div></md-field
              >
              <md-field class="labelAndInput">
                <div>Lumi Secret</div>
                <md-input
                  id="lumiInputSecret"
                  v-model="lumiInputSecret"
                  placeholder="Secret for protecting id lookup"
                  @keyup="validateInput"
                />
                <div class="error"></div>
              </md-field>
              <div>
                Use these fields to create an encrypted Lumi Id. This Lumi Id
                can be used to find back your patient.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class="spacer"></div>
        <div v-if="anonymizeDicom">
          All identifying patient information will be removed. Replace
          information if desired.
        </div>
        <div v-else>Replace information if desired.</div>
      </div>
      <div v-if="isDicomUpload && fileUploadInfo.hasMixedFileTypes">
        <img
          src="/assets/icon/warning.svg"
          style="width: 24px; margin-right: 12px; margin-top: 12px"
        />Multiple file types found, only uploading DICOM
      </div>
    </md-dialog-content>
    <md-dialog-actions>
      <md-button
        id="uploadButton"
        class="md-primary md-raised"
        :disabled="
          !PatientID ||
          (!isDicomUpload && patientAsset !== null) ||
          isDirty ||
          !isValidBirthDateInput ||
          !isValidLumiInputBirthDateInput ||
          !isValidInput
        "
        @click.native="onPatientInfoOk()"
        >{{ okButtonText }}</md-button
      >
      <md-button class="md-primary" @click="cancelUpload()">Cancel</md-button>
    </md-dialog-actions>
  </md-dialog>
</template>

<script>
import appSettings from "./appSettings";
import { mapState, mapActions } from "vuex";
import appAssetTile from "./appAssetTile";
import moment from "moment";
import FileUploadInfo from "../classes/FileUploadInfo";

export default {
  name: "AppDicomAnonymize",
  components: {
    "app-asset-tile": appAssetTile,
  },
  props: {
    fileUploadInfo: {
      type: Object,
      default() {
        return new FileUploadInfo();
      },
    },
  },
  data: () => ({
    isInitializing: false,
    lumiInputPatientID: null,
    lumiInputBirthDate: null,
    lumiInputSecret: null,
    PatientID: null,
    IssuerOfPatientID: null,
    PatientName: null,
    GivenName: null,
    FamilyName: null,
    PatientSex: null,
    PatientBirthDate: null,

    timeout: null,
    patientAsset: null,
    isDirty: false,
    isUploading: false,
    isValidBirthDateInput: true,
    isValidLumiInputBirthDateInput: true,
    isValidInput: true,

    anonymizeDicom: true,
    generateLumiId: true,
    anonymizeSupported: true,
  }),
  computed: {
    ...mapState(["selectedGroup"]),
    needsPatientInfo() {
      return this.fileUploadInfo.needsPatientInfo;
    },
    okButtonText() {
      return this.fileUploadInfo.isDicom ? "Upload" : "Create";
    },
    isDicomUpload() {
      return this.fileUploadInfo.isDicom;
    },
    patientPreviewInfo() {
      const patientName = this.patientAsset
        ? this.patientAsset.metadata.PatientName
        : this.getPatientName();
      const thumbUrl = `${window.location.origin}/assets/icon/PersonIcon.png`;
      return {
        metadata: {
          _type: "Person",
          thumb: thumbUrl,
          displayName: patientName,
          PatientId: this.PatientID,
          PatientName: patientName,
          BirthDate: this.PatientBirthDate,
        },
      };
    },
    formattedBirthDate() {
      return this.formatTextDate(this.PatientBirthDate);
    },
  },
  watch: {
    async fileUploadInfo(info) {
      this.isInitializing = true;
      try {
        this.updateLocalAnonymizerInfo(info);
        await this.initializePatientFields();
      } finally {
        this.isInitializing = false;
      }
    },
    lumiInputPatientID() {
      if (this.isInitializing) return;
      this.handleLumiIdInputChanged();
    },
    lumiInputBirthDate() {
      if (this.isInitializing) return;
      this.handleLumiIdInputChanged();
    },
    lumiInputSecret() {
      if (this.isInitializing) return;
      this.handleLumiIdInputChanged();
    },
    anonymizeDicom(anonymize) {
      this.generateLumiId = anonymize;
      this.initializePatientFields();
    },
    generateLumiId() {
      this.updatePatientId();
    },
    async PatientID(PatientID) {
      this.patientAsset = null;
      if (!PatientID) return;

      const url = `${appSettings.remoteurl}/api/groups/${
        this.fileUploadInfo.assetGroupId
      }/assets?PatientId=${encodeURIComponent(PatientID)}&_type=Person`;
      const foundPatients = await this.fetchAzure(url);
      this.patientAsset = foundPatients.length > 0 ? foundPatients[0] : null;
      this.isDirty = false;
    },
  },
  created() {
    this.$material.locale.dateFormat = "yyyy-MM-dd";
    this.updateLocalAnonymizerInfo(this.fileUploadInfo);
  },
  methods: {
    ...mapActions(["fetchAzure"]),
    validateInput(event) {
      this.revalidateInput(event.srcElement.id);
    },
    revalidateInput(fieldId, newValue) {
      let regex = null;
      const alphanumericName = appSettings.regex.alphanumericName;
      const nameWithNumbers = appSettings.regex.nameWithNumbers;

      switch (fieldId) {
        case "lumiId":
        case "lumiInputPatientID":
          regex = new RegExp(alphanumericName + "{1,256}", "gm");
          break;
        case "firstName":
        case "lastName":
          regex = new RegExp(nameWithNumbers + "{0,64}", "gm");
          break;
        case "lumiInputSecret":
          regex = new RegExp(nameWithNumbers + "{0,256}", "gm");
          break;
        default:
          return;
      }
      const element = document.getElementById(fieldId);
      if (!element) return;
      if (newValue) {
        element.value = newValue;
      }
      const errorEl = element.parentElement.getElementsByClassName("error");

      let m;
      let valid = false;

      while ((m = regex.exec(element.value)) !== null) {
        if (m.index === regex.lastIndex) {
          regex.lastIndex++;
        }

        m.forEach((match) => {
          if (element.value == match) {
            valid = true;
          }
        });
      }
      if (errorEl.length < 1) return;
      if (!valid) {
        errorEl[0].innerText = "Invalid input";
        errorEl[0].classList.add("show-error");
        this.isValidInput = false;
      } else {
        errorEl[0].innerText = "";
        errorEl[0].classList.remove("show-error");
        this.isValidInput =
          document.getElementsByClassName("show-error").length == 0;
      }
    },
    checkValidDates() {
      if (
        !this.$refs ||
        !this.$refs.birthDatePicker ||
        !this.$refs.lumiInputBirthDatePicker
      )
        return;
      this.isValidBirthDateInput =
        (this.$refs.birthDatePicker.inputDate || "") ===
        (this.PatientBirthDate || "");
      this.isValidLumiInputBirthDateInput =
        (this.$refs.lumiInputBirthDatePicker.inputDate || "") ===
        (this.lumiInputBirthDate || "");
    },
    handleLumiIdInputChanged: function () {
      this.checkValidDates();
      this.isDirty = true;
      if (this.timeout) clearTimeout(this.timeout);

      this.timeout = setTimeout(() => {
        this.updatePatientId();
      }, 1000);
    },
    async updatePatientId() {
      if (!this.fileUploadInfo.PatientID) {
        return;
      }
      if (this.generateLumiId) {
        this.PatientID = await this.getLumiId();
        this.IssuerOfPatientID = "Lumi";
        this.revalidateInput("lumiId", this.PatientID);
        return;
      }
      if (this.anonymizeDicom) {
        this.IssuerOfPatientID = null;
        this.PatientID = null;
      } else {
        this.IssuerOfPatientID = this.fileUploadInfo.IssuerOfPatientID;
        this.PatientID = this.fileUploadInfo.PatientID;
      }
    },
    async getLumiId() {
      let url = `${
        appSettings.anonymizerurl
      }/api/lumiid?PatientID=${encodeURIComponent(this.lumiInputPatientID)}`;
      if (this.lumiInputSecret)
        url += `&lumiSecret=${encodeURIComponent(this.lumiInputSecret)}`;
      if (this.lumiInputBirthDate)
        url += `&PatientBirthDate=${encodeURIComponent(
          this.lumiInputBirthDate
        )}`;
      let info = await this.fetchAzure(url);
      return info.lumiId;
    },
    updateLocalAnonymizerInfo(info) {
      this.lumiInputPatientID = info.PatientID;
      this.lumiInputBirthDate = this.formatDate(info.PatientBirthDate);
      this.lumiInputSecret = null;
      this.anonymizeSupported = info.anonymizeSupported;
      this.anonymizeDicom = this.anonymizeSupported;
      this.isUploading = false;
    },
    async initializePatientFields() {
      this.PatientID = null;
      this.IssuerOfPatientID = null;
      this.FamilyName = null;
      this.GivenName = null;
      this.PatientSex = "U";
      this.PatientBirthDate = null;

      if (this.anonymizeDicom) {
        await this.updatePatientId();
        return;
      }
      this.PatientID = this.fileUploadInfo.PatientID;
      this.FamilyName = this.fileUploadInfo.FamilyName;
      this.GivenName = this.fileUploadInfo.GivenName;

      let PatientName = this.fileUploadInfo.PatientName;
      if (PatientName && !this.FamilyName && !this.GivenName) {
        const patientParts = PatientName.split("^");
        this.FamilyName = patientParts[0];
        this.GivenName = patientParts.length > 1 ? patientParts[1] : "";
      }
      const gender = this.fileUploadInfo.PatientSex;
      this.PatientSex = gender === "M" || gender === "F" ? gender : "U";
      this.PatientBirthDate = this.formatDate(
        this.fileUploadInfo.PatientBirthDate
      );
    },
    formatTextDate(date) {
      return date && moment(new Date(date)).format("MMMM D, YYYY");
    },
    formatDate(date) {
      return date && moment(date).format("yyyy-MM-DD");
    },
    cancelUpload() {
      this.$emit("cancelUpload");
    },
    onPatientInfoOk() {
      this.checkValidDates();
      if (!this.isValidBirthDateInput || this.isUploading) {
        return;
      }
      // prevent double click to trigger upload twice
      this.isUploading = true;

      let info = this.fileUploadInfo;

      info.PatientID = this.PatientID;
      info.PatientBirthDate = this.PatientBirthDate;
      info.IssuerOfPatientID = this.IssuerOfPatientID;
      info.PatientSex = this.PatientSex;
      info.PatientName = this.getPatientName();
      info.FamilyName = this.FamilyName;
      info.GivenName = this.GivenName;

      info.assetType = "DICOMStudy";
      info.anonymizeDicom = this.anonymizeDicom;
      info.patientAsset = this.patientAsset;

      this.$emit("onPatientInfo", info);
    },
    getPatientName() {
      if (!this.FamilyName && !this.GivenName) return "Anonymized Patient";
      return this.GivenName && this.FamilyName
        ? `${this.GivenName} ${this.FamilyName}`
        : this.FamilyName || this.GivenName;
    },
  },
};
</script>
<style>
.md-field.noAfter > .md-icon::after {
  content: none;
}
.md-datepicker input {
  width: 100px;
}
.md-field.noAfter > .md-input-action {
  top: 0px;
}
.radioContainer .md-radio-label {
  top: 0px;
  padding-left: 4px !important;
  padding-right: 6px !important;
  font-size: 15px;
}
</style>
<style scoped>
.radioContainer .md-radio {
  --md-theme-default-primary: white;
  margin-top: 8px;
  margin-right: 0px;
  margin-bottom: 0px;
}
#anonymizer {
  width: 960px;
  height: 600px;
  flex-direction: column;
}
#anonymizerInputAndPreview {
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
}
#lumiIdInput {
  align-items: left;
  display: flex;
  flex-direction: column;
  background: #2d6b7d;
  width: 290px;
  padding: 10px 20px;
  margin-bottom: 10px;
  border-radius: 12px;
}
#anonymizeDicom {
  height: 200px;
}
#anonymizerInput {
  display: flex;
  flex-direction: column;
}
.labelAndInput {
  flex-direction: column;
}
.md-field {
  margin-bottom: 10px;
  padding-top: 0px;
  min-height: 24px;
}
.md-field > div {
  padding-left: 12px;
  text-align: left;
}
.md-field.md-has-placeholder .md-input {
  padding-left: 12px;
  color: silver;
}
.md-field.md-focused label,
.md-field.md-has-value label {
  display: none;
}
.md-field label {
  top: 4px;
  padding-left: 4px;
}
.md-field .md-input {
  background-color: #398ca5;
  margin-top: 4px;
  border-radius: 8px;
}
.md-datepicker {
  background-color: #398ca5;
  border-radius: 8px;
}
.invalidInput {
  border: 2px solid red;
}
.datePreview {
  margin-left: 35px;
  font-style: italic;
}
#anonymizerPreview {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  margin-right: 40px;
}
.previewTileContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 10px;
  margin-left: 10px;
}
.previewTile {
  height: 290px;
  width: 240px;
}
.arrow {
  background-image: url("/public/assets/image/Arrow_Turquoise.png");
  transform: rotate(180deg);
  background-repeat: no-repeat;
  height: 40px;
  width: 24px;
  min-width: 40px;
  background-size: 24px 40px;
  margin-top: 74px;
  margin-right: 14px;
}
.noArrow {
  min-width: 54px;
}
.spacer {
  height: 20px;
}

.error {
  color: red;
  font-style: italic;
  display: none;
}

.error.show-error {
  display: block;
}
</style>
