<template id="app-copy-asset">
  <div class="app-copy-asset-dialog">
    <md-dialog :md-active.sync="showCopyAsset">
      <md-dialog-title>Move DICOM</md-dialog-title>
      <md-dialog-content id="copyDialog">
        <div class="dialogContent">
          <div v-if="copyStage === 'initial'">
            <div>Copy DICOM of {{ displayText }} to group</div>
            <multiselect
              id="groupSelect"
              v-model="targetGroup"
              :allow-empty="false"
              :custom-label="getAssetGroupDisplayName"
              deselect-label=""
              :options="filteredAssetGroups"
              placeholder="Start typing to search..."
              :preselect-first="true"
              :searchable="true"
              select-label=""
              selected-label=""
            ></multiselect>
            <div>
              <md-checkbox v-model="deleteAfterCopy">{{
                deleteMessage
              }}</md-checkbox>
            </div>
          </div>
          <div v-else-if="copyStage === 'warning'">
            <img src="/assets/icon/warning.svg" />
            <div>{{ deleteWarning }}</div>
          </div>
          <div v-if="copyStage === 'copying'">
            <div class="spacer"></div>
            <div>Copying ... {{ studyProgress }}</div>
          </div>
        </div>
      </md-dialog-content>
      <md-dialog-actions>
        <md-button
          class="md-primary md-raised"
          :disabled="!targetGroup || copyStage === 'copying'"
          @click="clickNext"
          >{{ copyStage !== "copying" ? "Next" : "..." }}</md-button
        >
        <md-button
          class="md-primary"
          :disabled="copyStage === 'copying'"
          @click="cancelCopy()"
          >Cancel</md-button
        >
      </md-dialog-actions>
    </md-dialog>
    <md-dialog
      :md-active.sync="noStudies"
      :md-click-outside-to-close="false"
      :md-close-on-esc="false"
    >
      <md-dialog-title>Attention!</md-dialog-title>
      <md-dialog-content>
        <img src="/assets/icon/warning.svg" />
        <span>No DICOM studies found for patient</span>
      </md-dialog-content>
      <md-dialog-actions>
        <md-button class="md-primary" @click="noStudies = false">Ok</md-button>
      </md-dialog-actions>
    </md-dialog>
    <app-dicom-upload
      :file-upload-info="patientInfo"
      @cancelUpload="cancelCopy()"
      @onPatientInfo="copyAsset($event)"
    ></app-dicom-upload>
  </div>
</template>

<script>
import appSettings from "./appSettings";
import { mapState, mapMutations, mapActions } from "vuex";
import appDicomUpload from "./appDicomUpload";
import FileUploadInfo from "../classes/FileUploadInfo.js";
import multiselect from "vue-multiselect";

export default {
  name: "AppCopyAsset",
  components: {
    multiselect,
    "app-dicom-upload": appDicomUpload,
  },
  data: () => ({
    targetGroup: null,
    deleteAfterCopy: false,
    copyStage: "initial", // initial/warning/patientinfo/copying
    studyProgress: "",
    showCopyAsset: false,
    noStudies: false,
    patientInfo: new FileUploadInfo(),
    filteredAssetGroupList: [
      "InProgress",
      "Failed",
      "Skipped",
      "Success",
      "PartialSuccess",
      "All",
    ],
  }),
  computed: {
    ...mapState(["selectedGroup", "assetGroups", "assetToCopy", "user"]),
    filteredAssetGroups() {
      if (!this.selectedGroup) return [];

      return this.assetGroups.filter(
        (x) => x.id != this.selectedGroup.id && x.metadata._permission != "Read"
      );
    },
    isStudy() {
      return (
        this.assetToCopy && this.assetToCopy.metadata._type === "DICOMStudy"
      );
    },
    deleteMessage() {
      if (!this.assetToCopy) return "";

      return `Delete the ${
        this.isStudy ? "DICOM Study" : "Patient"
      } (including associated files) from the current group`;
    },
    deleteWarning() {
      if (!this.assetToCopy) return "";

      if (this.deleteAfterCopy) {
        return "Only DICOM will be moved to the new group. Other files such as holograms or surface models will not be moved but will be permanently deleted.";
      }
      return "Only DICOM will be moved to the new group. Other files such as holograms or surface models will not be moved.";
    },
    displayText() {
      if (!this.assetToCopy) return "";
      const metadata = this.assetToCopy.metadata;
      return (
        metadata.PatientId || metadata.StudyDescription || metadata.displayName
      );
    },
  },
  watch: {
    assetToCopy() {
      this.showCopyAsset = this.shouldShowDialog();
    },
    copyStage() {
      this.showCopyAsset = this.shouldShowDialog();
    },
  },
  methods: {
    ...mapMutations(["setAssetToCopy"]),
    ...mapActions(["fetchAzure", "putAzure", "postAzure", "deleteAzure"]),
    shouldShowDialog() {
      return this.assetToCopy != null && this.copyStage != "patientinfo";
    },
    async clickNext() {
      if (this.copyStage === "initial") {
        this.copyStage = "warning";
        return;
      }
      if (this.copyStage === "warning") {
        await this.goToPatientInfoStage();
      }
    },
    async goToPatientInfoStage() {
      let metadata = this.assetToCopy.metadata;
      if (metadata._parentAsset) {
        let parentAsset = await this.fetchAzure(
          `${appSettings.remoteurl}/api/${metadata._parentAsset}`
        );
        metadata = parentAsset.metadata;
      }
      this.patientInfo = new FileUploadInfo([], {
        isDicom: true,
        needsPatientInfo: true,
        anonymizeSupported: false,
        assetGroupId: this.targetGroup.id,

        PatientID: metadata.PatientId,
        PatientIdIssuer: metadata.PatientIdIssuer,
        PatientName: metadata.PatientName,
        PatientBirthDate: metadata.BirthDate,
        PatientSex: metadata.PatientSex,
        FamilyName: metadata.FamilyName,
        GivenName: metadata.GivenName,
      });
      this.copyStage = "patientinfo";
    },
    cancelCopy() {
      this.setAssetToCopy(null);
      this.copyStage = "initial";
      this.patientInfo = new FileUploadInfo();
    },
    async copyAsset(patientInfo) {
      this.patientInfo = new FileUploadInfo();
      this.copyStage = "copying";
      let patientAsset = patientInfo.patientAsset
        ? patientInfo.patientAsset
        : await this.createPatient(this.createPatientMetadata(patientInfo));
      try {
        if (this.isStudy) {
          await this.copyStudy(patientAsset.id, this.assetToCopy.id);
        } else {
          const condition = `&_parentAsset=/groups/${this.selectedGroup.id}/assets/${this.assetToCopy.id}`;
          let assets = await this.fetchAzure(
            `${appSettings.remoteurl}/api/groups/${this.selectedGroup.id}/assets?${condition}`
          );
          if (assets.length === 0) {
            this.copyStage = "initial";
            this.noStudies = true;
            return;
          }
          let studyCount = 0;
          for (let asset of assets) {
            this.studyProgress = ` ${++studyCount}/${assets.length}`;
            await this.copyStudy(patientAsset.id, asset.id);
          }
        }
        if (this.deleteAfterCopy) {
          await this.deleteAsset(this.assetToCopy.id);
          this.$emit("asset-deleted", this.assetToCopy);
        }
      } finally {
        this.copyStage = "initial";
        this.studyProgress = "";
        this.setAssetToCopy(null);
      }
    },
    async deleteAsset(assetId) {
      const url = `${appSettings.remoteurl}/api/groups/${this.selectedGroup.id}/assets/${assetId}`;
      await this.deleteAzure({ url });
    },
    createPatientMetadata(patientInfo) {
      return {
        _type: "Person",
        _folder: true,
        displayName: patientInfo.PatientName,
        PatientName: patientInfo.PatientName,
        PatientId: patientInfo.PatientID,
        PatientSex: patientInfo.PatientSex,
        BirthDate: patientInfo.PatientBirthDate,
      };
    },
    async createPatient(metadata) {
      const url = `${appSettings.remoteurl}/api/groups/${this.targetGroup.id}/patients`;
      metadata._author = this.user.displayName;
      return await this.postAzure({ url, metadata });
    },
    async copyStudy(patientAssetId, assetId) {
      const url =
        `${appSettings.remoteurl}/api/groups/${this.selectedGroup.id}/assets/${assetId}/copy` +
        `?targetGroupId=${this.targetGroup.id}`;
      await this.putAzure({
        url,
        metadata: {
          _parentAsset: `/groups/${this.targetGroup.id}/assets/${patientAssetId}`,
        },
      });
    },
    getAssetGroupDisplayName(group) {
      return group.metadata.displayName;
    },
  },
};
</script>
<style>
.md-checkbox-container {
  border-color: white !important;
}
.md-checkbox.md-theme-default.md-checked .md-checkbox-container::after {
  border-color: #fff !important;
}
.md-checkbox.md-theme-default.md-checked .md-checkbox-container {
  background-color: #398ca5 !important;
}
.md-dialog-content img {
  width: 48px;
  margin-bottom: 20px;
}
</style>
<style scoped>
#groupSelect {
  padding-left: 20px;
  padding-top: 10px;
  background-color: #398ca5;
}
#copyDialog {
  width: 480px;
  height: 340px;
  padding: 30px;
  background-color: #4c9cb5;
}
.dialogContent {
  display: flex;
  flex-direction: column;
}
.spacer {
  height: 20px;
}
::v-deep .multiselect__tags {
  background-color: #398ca5;
}
::v-deep .md-dialog-container {
  overflow: visible !important;
}
.md-dialog-content {
  overflow: visible !important;
}
</style>
