<template>
  <div
    v-show="show"
    ref="context"
    class="context-menu"
    :style="style"
    tabindex="0"
    @blur="close"
  >
    <slot></slot>
  </div>
</template>
<script>
import Vue from "vue";

export default {
  name: "CmpContextMenu",
  data() {
    return {
      left: 0,
      top: 0,
      show: false,
    };
  },
  computed: {
    style() {
      return {
        top: this.top + "px",
        left: this.left + "px",
      };
    },
  },
  methods: {
    close() {
      this.show = false;
      this.left = 0;
      this.top = 0;
    },
    open(evt) {
      this.left = evt.pageX || evt.clientX;
      this.top = evt.pageY || evt.clientY;
      Vue.nextTick(() => this.$el.focus());
      this.show = true;
    },
  },
};
</script>
<style>
.context-menu {
  position: fixed;
  background: #337789;
  z-index: 999;
  outline: none;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  cursor: pointer;
  padding: 5px 20px;
}
</style>
