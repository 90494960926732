var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('md-dialog',{attrs:{"md-active":_vm.showShareUrl},on:{"update:mdActive":function($event){_vm.showShareUrl=$event},"update:md-active":function($event){_vm.showShareUrl=$event}}},[_c('md-dialog-title',[_vm._v("Share Hologram via")]),(_vm.delegationCredentials)?_c('div',[_c('div',{attrs:{"id":"shareTabs"}},[_c('div',{staticClass:"shareTab",class:{
            activeTab: _vm.activeTabNumber === 0,
          },on:{"click":function($event){_vm.activeTabNumber = 0}}},[_vm._v(" QR ")]),_c('div',{staticClass:"shareTab",class:{
            activeTab: _vm.activeTabNumber === 1,
          },on:{"click":function($event){_vm.activeTabNumber = 1}}},[_vm._v(" Text Message ")]),_c('div',{staticClass:"shareTab",class:{
            activeTab: _vm.activeTabNumber === 2,
          },on:{"click":function($event){_vm.activeTabNumber = 2}}},[_vm._v(" Print ")])]),_c('div',{attrs:{"id":"tabContent"}},[(_vm.activeTabNumber === 0)?_c('div',{attrs:{"id":"scanQrTab"},on:{"contextmenu":function($event){return _vm.openMenu($event)}}},[_c('qr-code',{staticClass:"qrContainer",attrs:{"error-level":"L","size":_vm.qrSize,"text":_vm.sharedResourceUrl}})],1):(_vm.activeTabNumber === 1)?_c('div',{style:({
            height: _vm.qrSize + 'px',
            minwidth: _vm.qrSize + 'px',
          }),attrs:{"id":"smsTab"}},[_c('div',[_vm._v("Send a link of this hologram to a mobile phone")]),_c('div',{attrs:{"id":"sendLine"}},[_c('VueTelInput',_vm._b({model:{value:(_vm.phoneNumber),callback:function ($$v) {_vm.phoneNumber=$$v},expression:"phoneNumber"}},'VueTelInput',_vm.phoneProps,false)),_c('md-button',{staticClass:"md-primary md-raised",attrs:{"disabled":!_vm.phoneNumber || _vm.isSendingSms},on:{"click":function($event){return _vm.sendSms()}}},[_vm._v("Send Text")]),_c('app-status-icon',{attrs:{"status":_vm.smsStatus}})],1),(_vm.sendSmsErrorMessage)?_c('div',{attrs:{"id":"sendErrorMessage"}},[_vm._v(" "+_vm._s(_vm.sendSmsErrorMessage)+" ")]):_vm._e(),_c('div',{staticClass:"small"},[_vm._v(" Phone number will not be stored or used for any other purposes ")])]):_c('div',{attrs:{"id":"printTab"}},[_c('div',{attrs:{"id":"printPage"}},[_c('img',{staticClass:"appLogo",attrs:{"src":"/assets/image/Augmedit.png"}}),_c('qr-code',{attrs:{"error-level":"L","size":280,"text":_vm.sharedResourceUrl}}),_c('div',{attrs:{"id":"scanText"}},[_vm._v(" Scan QR with mobile device to see hologram ")])],1),_c('md-button',{staticClass:"md-primary md-raised nastyButton",on:{"click":function($event){return _vm.printQr()}}},[_vm._v("Print")])],1)])]):_c('div',{attrs:{"id":"generating"}},[_vm._v("Generating sharing information")]),_c('md-dialog-actions',[_c('md-button',{staticClass:"md-primary nastyButton",on:{"click":function($event){_vm.showShareUrl = false;
          _vm.showSharePin = true;}}},[_vm._v("Show PIN")])],1)],1),_c('md-dialog',{attrs:{"md-active":_vm.showSharePin},on:{"update:mdActive":function($event){_vm.showSharePin=$event},"update:md-active":function($event){_vm.showSharePin=$event}}},[_c('md-dialog-title',[_vm._v("Share Hologram PIN")]),_c('div',{style:({
        height: _vm.qrSize + 'px',
      }),attrs:{"id":"pinCodeDialog"}},[(_vm.delegationCredentials)?_c('div',[_c('div',{attrs:{"id":"pinCode"},on:{"contextmenu":function($event){return _vm.openMenu($event)}}},[_vm._l((_vm.firstPinPart),function(char,index){return _c('div',{key:`first-${index}`},[_c('div',{staticClass:"pinDigit"},[_vm._v(_vm._s(char))])])}),_c('div',{staticClass:"separator"},[_vm._v("-")]),_vm._l((_vm.secondPinPart),function(char,index){return _c('div',{key:`sec-${index}`},[_c('div',{staticClass:"pinDigit"},[_vm._v(_vm._s(char))])])})],2),_c('div',[_vm._v("Send PIN by email")]),_c('div',{attrs:{"id":"sendLine"}},[_c('md-field',{attrs:{"id":"inputLine"}},[_c('md-input',{staticStyle:{"width":"120px"},attrs:{"placeholder":"name@domain.com"},model:{value:(_vm.emailAddress),callback:function ($$v) {_vm.emailAddress=$$v},expression:"emailAddress"}})],1),_c('md-button',{staticClass:"md-primary md-raised",attrs:{"disabled":!_vm.emailAddress || !_vm.emailAddress.includes('@') || _vm.isSendingEmail},on:{"click":function($event){return _vm.sendMail()}}},[_vm._v("Send Mail")]),_c('app-status-icon',{attrs:{"status":_vm.mailStatus}})],1),(_vm.sendMailErrorMessage)?_c('div',{attrs:{"id":"sendErrorMessage"}},[_vm._v(" "+_vm._s(_vm.sendMailErrorMessage)+" ")]):_vm._e(),_c('div',{staticClass:"small"},[_vm._v(" Email address will not be stored or used for any other purposes ")])]):_c('div',[_vm._v("Generating sharing information")])]),_c('md-dialog-actions',[_c('md-button',{staticClass:"md-primary",attrs:{"disabled":!_vm.delegationCredentials},on:{"click":function($event){_vm.showSharePin = false;
          _vm.showShareUrl = true;}}},[_vm._v("Show QR")]),_c('md-button',{staticClass:"md-primary",on:{"click":function($event){_vm.showSharePin = false;
          _vm.delegationCredentials = null;}}},[_vm._v("Close")])],1)],1),_c('context-menu',{ref:"menu"},[_c('div',[_c('div',{on:{"click":function($event){return _vm.copy()}}},[_vm._v(_vm._s(_vm.copyText))])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }