<template id="app-settings">
  <div style="height: 0px"></div>
</template>

<script>
let settings = {};
if (
  window &&
  window.appSettings &&
  JSON.stringify(window.appSettings).indexOf("#{remotewsurl}#") < 0
) {
  settings = window.appSettings;
} else {
  settings = {
    remotewsurl: "wss://test.event-service.lumi.cloud",
    // remotewsurl: "ws://localhost:5000",
    remoteurl: "https://test.api.lumi.cloud",
    // remoteurl: "http://localhost:5000",
    anonymizerurl: "https://test.anonymizer.lumi.cloud",
    // anonymizerurl: "http://localhost:5000",
    userguideurl: "https://userguide.augmedit.com/",
    host: "test.lumi.cloud",
    siteversion: "1.0.8888",
    msalConfig: {
      auth: {
        clientId: "137fa99a-db04-4ac2-be87-a18e7ce3129d",
        authority:
          "https://login.microsoftonline.com/fa2119ff-ec91-44ae-90bc-3820a1836505",
        redirectUri: "http://localhost:8080",
      },
      cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: false,
      },
    },
    regex: {
      alphanumericName: "[ a-zA-Z0-9-_,!./:()@$+=]",
      nameWithNumbers:
        "[ 0-9a-zA-ZáàâäãåçéèêëíìîïñóòôöõúùûüýÿæœÁÀÂÄÃÅÇÉÈÊËÍÌÎÏÑÓÒÔÖÕÚÙÛÜÝŸÆŒ_:()/,.!'$=@#%^&~+;-]",
    },
    instrumentationKey: "",
  };
}

export default settings;
</script>
