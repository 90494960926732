<template>
  <div class="formatType">
    <md-field>
      <md-select id="type" v-model="type" class="selectType" name="type">
        <md-option v-for="option in options" :key="option" :value="option">
          &nbsp;{{ option }}
        </md-option>
      </md-select>
    </md-field>
  </div>
</template>
<script>
import { mapActions } from "vuex";

export default {
  name: "AppAssetFormatListItem",
  props: {
    format: {
      type: Object,
      default: Object.default,
    },
  },
  data: () => ({
    type: "unknown",
    options: [
      "DICOM",
      "GLTF",
      "RAW",
      "OBJ",
      "STL",
      "FBX",
      "GLB",
      "VOLUME",
      "NIFTI",
      "SET",
      "POINTCLOUD",
      "OFF",
      "JPG",
      "PNG",
      "MP4",
      "WAV",
      "MP3",
      "JSON",
      "PERSON",
      "XML",
      "CSV",
      "unknown",
    ],
  }),
  watch: {
    type: function () {
      if (this.format && this.format.metadata._type !== this.type) {
        this.updateFormatMetadata({
          format: this.format,
          metadata: {
            _type: this.type,
          },
        });
      }
    },
  },
  mounted() {
    if (this.format.metadata) {
      this.type =
        this.options.indexOf(this.format.metadata._type) > -1
          ? this.format.metadata._type
          : "unknown";
    }
  },
  methods: {
    ...mapActions(["updateFormatMetadata"]),
  },
};
</script>
<style scoped>
.selectType .md-menu-content {
  max-width: 210px !important;
}

.formatType {
  padding-left: 10px;
  padding-top: 3px;
}
</style>
