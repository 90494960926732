<template>
  <div
    id="loginPage"
    :style="{
      display: user && validCountry ? 'none' : 'flex',
    }"
  >
    <!-- logout after 1 hour-->
    <!-- reminder 2 minutes before logout -->
    <Vidle
      :duration="timeOutSeconds"
      :loop="false"
      :reminders="[120]"
      style="display: none"
      @refresh="writeUserActivity"
      @remind="onNearlyIdle"
    />
    <md-dialog :md-active.sync="showNearlyLoggingOutDialog">
      <md-dialog-title>No activity detected</md-dialog-title>
      <md-dialog-content class="inactivity-dialog">
        <div>
          No activity detected, signing out in
          <br />
          <br />
          {{ timer }}
        </div>
      </md-dialog-content>
      <md-dialog-actions>
        <md-button
          class="md-primary md-raised"
          @click="showNearlyLoggingOutDialog = false"
          >Cancel</md-button
        >
      </md-dialog-actions>
    </md-dialog>
    <div v-if="showLoginScreen">
      <div class="loginLogo"></div>
      <div id="loginButton" @click="signIn">
        <img src="/assets/image/ms.svg" />
        <span>Sign in with Microsoft</span>
      </div>
    </div>
    <div
      v-if="
        !showLoginScreen && !tryingSilentLoginAtStartup && !isInCorrectCountry()
      "
    >
      <div class="loginLogo"></div>
      <div class="warning">
        <md-icon class="icon">warning</md-icon>
        <div class="label">
          Due to your account's region setting, you will be automatically
          redirected to
          <a :href="getRedirectUrlForIncorrectRegion()">{{
            getRedirectUrlForIncorrectRegion()
          }}</a>
          <br /><br />
          If redirection does not occur within 5 seconds, click
          <a :href="getRedirectUrlForIncorrectRegion()">here</a>.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import appSettings from "./appSettings";
import { PublicClientApplication } from "@azure/msal-browser";
import CountryVerification from "@/classes/CountryVerification";
import Vidle from "v-idle";

export default {
  name: "AppLoginPage",
  components: {
    Vidle,
  },
  data: () => ({
    msalClient: null,
    tryingSilentLoginAtStartup: true,
    showNearlyLoggingOutDialog: false,
    timer: null,
    timeOutSeconds: 3600,
    lastUserActivityWritten: 0,
  }),
  computed: {
    ...mapState(["user", "validCountry", "tokenExpiry"]),
    showLoginScreen() {
      return !this.user && !this.tryingSilentLoginAtStartup;
    },
    loginRequest() {
      return {
        scopes: [
          appSettings.msalConfig.auth.clientId + "/Delegate.Access",
          "user.read",
        ],
      };
    },
  },
  watch: {
    timer(value) {
      if (value <= 0) {
        this.showNearlyLoggingOutDialog = false;
        this.signOut();
      }
    },
  },
  async created() {
    document.addEventListener("visibilitychange", () => {
      if (document.hidden) {
        this.writeUserActivity();
      } else {
        if (this.tabClosedLongerThanTimeOut()) {
          this.signOut();
        } else {
          this.handleFocusEvent();
        }
      }
    });
    this.msalClient = new PublicClientApplication(appSettings.msalConfig);
    if (this.tabClosedLongerThanTimeOut()) {
      this.tryingSilentLoginAtStartup = false;
    } else {
      this.tryingSilentLoginAtStartup = true;
      await this.trySilentLoginOnStartup();
    }
    const self = this;
    this.intervalid1 = setInterval(() => {
      self.refreshWhenTokenAboutToExpire();
    }, 10000);
  },
  methods: {
    ...mapMutations([
      "setIdToken",
      "setTokenExpiry",
      "setUser",
      "setErrorMessage",
      "setServiceNotice",
      "setCountryCode",
      "setValidCountry",
    ]),
    ...mapActions(["signOut", "fetchAzure"]),
    writeUserActivity() {
      // Only extend active time if user is logged in,
      // otherwise you can open another tab and login anyways
      if (!this.user) return;

      let now = Date.now();
      // Write every 10 seconds
      // (not sure how heavy the action is, but events are passed very often)
      var secondsSinceLastWrite = (now - this.lastUserActivityWritten) / 1000;
      if (secondsSinceLastWrite > 10) {
        window.localStorage["lastActivity"] = now;
        this.lastUserActivityWritten = now;
      }
    },
    tabClosedLongerThanTimeOut() {
      let lastActivity = window.localStorage["lastActivity"];
      if (!lastActivity) return true;

      let secondsAway = (Date.now() - lastActivity) / 1000;
      return secondsAway > this.timeOutSeconds;
    },
    async trySilentLoginOnStartup() {
      try {
        await this.trySilentLogin();
      } catch (e) {
        // nothing on failure, as we were just trying
      } finally {
        this.tryingSilentLoginAtStartup = false;
      }
    },
    countDown() {
      if (!this.showNearlyLoggingOutDialog) return;
      setTimeout(() => {
        this.timer--;
        this.countDown();
      }, 1000);
    },
    async trySilentLogin() {
      const account = this.msalClient.getActiveAccount();
      if (!account) throw "no active account found for silent login";

      await this.msalClient
        .acquireTokenSilent(this.loginRequest)
        .then((loginResponse) => {
          this.handleLoginSuccess(loginResponse);
        });
    },
    handleFocusEvent() {
      // Token might be expired while the user was in a different tab
      this.refreshWhenTokenAboutToExpire();
    },
    onNearlyIdle() {
      if (!this.user) return;

      this.timer = 120;
      this.showNearlyLoggingOutDialog = true;
      this.countDown();
    },
    async signIn() {
      let loginRequest = this.loginRequest;
      loginRequest.loginHint = window.localStorage["lastAccountUserName"];
      loginRequest.prompt = "login";

      this.msalClient
        .loginPopup(loginRequest)
        .then((loginResponse) => this.handleLoginSuccess(loginResponse))
        .catch((e) => this.setErrorMessage(e));
    },
    async refreshWhenTokenAboutToExpire() {
      if (!this.user) return;

      const aMinute = 60;
      const minutesUntilExpiry =
        (this.tokenExpiry - Date.now() / 1000) / aMinute;
      if (minutesUntilExpiry < 5) {
        try {
          await this.trySilentLogin();
        } catch (e) {
          this.setErrorMessage("could not extend login: " + e.errorMessage);
          if (minutesUntilExpiry <= 0) {
            this.signOut();
          }
        }
      }
    },
    isInCorrectCountry() {
      return CountryVerification.isInCorrectCountry(
        this.$store.state.countryCode
      );
    },
    getRedirectUrlForIncorrectRegion() {
      return CountryVerification.getUrlForRegion(this.$store.state.countryCode);
    },
    redirectToCorrectCountry() {
      setTimeout(() => {
        window.location = this.getRedirectUrlForIncorrectRegion();
      }, 4500);
    },
    handleLoginSuccess(loginResponse) {
      const account = loginResponse.account;
      const accessToken = loginResponse.accessToken;
      this.msalClient.setActiveAccount(account);
      window.localStorage["lastAccountUserName"] = account.username;
      window.sessionStorage.accesstoken = accessToken;
      const jwt = this.parseJwt(accessToken);
      this.setCountryCode(jwt.ctry);
      const isInCorrectCountry = this.isInCorrectCountry();
      this.setValidCountry(isInCorrectCountry);
      this.setTokenExpiry(jwt.exp);
      this.setIdToken(accessToken);
      this.setUser({
        userName: account.username,
        displayName: account.name,
        userId: account.localAccountId,
      });
      this.handleServiceNotice();
      if (!isInCorrectCountry) {
        this.redirectToCorrectCountry();
      }
    },
    async handleServiceNotice() {
      let url = `${appSettings.remoteurl}/api/configuration`;
      const response = await this.fetchAzure(url);
      this.setServiceNotice(response["serviceNotice"]);
    },
    parseJwt(token) {
      var base64Url = token.split(".")[1];
      var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      var jsonPayload = decodeURIComponent(
        atob(base64)
          .split("")
          .map(function (c) {
            return `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`;
          })
          .join("")
      );
      return JSON.parse(jsonPayload);
    },
  },
};
</script>

<style scoped>
#loading {
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#message {
  margin-top: 50px;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#loginPage {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loginLogo {
  width: 448px;
  height: 172px;
  background-size: 100% 100%;
  background-image: url("/public/assets/image/logoWhite.svg");
}

#loginButton img {
  margin: 16px;
}
#loginButton span {
  margin-right: 12px;
  color: white;
}

#loginButton {
  border-radius: 20px;
  margin-top: 55px;
  margin-left: 105px;
  height: 42px;
  width: 224px;
  user-select: none;
  display: flex;
  align-items: center;
  font-size: 15px;
  border: 1px #8c8c8c;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  background-color: #497888;
  box-shadow: 2px 6px 10px rgba(0, 0, 0, 0.6);
}

.warning {
  width: 320px;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  margin-left: 65px;
}

.icon {
  width: 20px;
  flex: 1;
}
.label {
  font-size: 15px;
  flex: 5;
}
</style>
