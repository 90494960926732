const uploadStages = {
  INACTIVE: "inactive",
  SCANNING: "scanning", // start reading files
  DICOM_DETECTED: "dicom_detected", // files contain DICOM, show dialog for anonymizer/adjusting DICOM
  INVALID_DICOM_UPLOAD: "invalid_dicom", // attempt to upload DICOM from another place than home
  CONFIRM_ANONYMOUS: "confirm_anonymous", // not DICOM, ensure anonymous data
  UPLOADING: "uploading", // upload, waiting ...
  PATIENT_INFO: "user enters patient info", // waiting for user to enter patient demographics
  CANCELLED: "cancelled", // cancelled, delete format
  SURFACE_DETECTED: "SURFACE_DETECTED", // show dialog for surface upload
};

export default uploadStages;
