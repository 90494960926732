<template id="appAdminCommands">
  <div id="editPage">
    <div id="commands">
      <div class="command">
        <div>Set Template (selected asset is prefilled):</div>
        <md-button class="md-primary md-raised" @click="setAssetInfoTemplate()">
          Asset Info
        </md-button>
        <md-button
          class="md-primary md-raised"
          @click="setFeatureToggleTemplate()"
        >
          My Feature Toggles
        </md-button>
        <md-button
          class="md-primary md-raised"
          @click="setDisiorBatchTemplate()"
        >
          Disior Batch
        </md-button>
        <md-button class="md-primary md-raised" @click="showGenerators()">
          Show Generators
        </md-button>
        <md-button
          class="md-primary md-raised"
          @click="setGenerateUnityTexture()"
        >
          Unity Texture
        </md-button>
        <md-button class="md-primary md-raised" @click="setSimplifyStl()">
          Simplify Stl
        </md-button>
        <md-button class="md-primary md-raised" @click="showJwt()">
          Show Jwt
        </md-button>
      </div>
      <div class="command">
        <md-button
          class="md-primary md-raised"
          :disabled="!urlToExecute"
          @click="executeUrl()"
        >
          Execute HTTP
        </md-button>
        <div class="radioContainer">
          <md-radio v-model="httpVerb" value="GET">Get</md-radio>
          <md-radio v-model="httpVerb" value="POST">Post</md-radio>
          <md-radio v-model="httpVerb" value="DELETE">Delete</md-radio>
        </div>
        <div class="radioContainer">
          <md-radio v-model="responseType" value="json">Json</md-radio>
          <md-radio v-model="responseType" value="text">Text</md-radio>
          <md-radio v-model="responseType" value="response">File</md-radio>
        </div>
        <md-field style="padding: 0px; margin: 0px">
          <md-input v-model="urlToExecute" @keyup.enter="executeUrl()"
        /></md-field>
      </div>
      <textarea
        v-if="httpVerb === 'POST'"
        v-model="metadata"
        class="jsonText"
        cols="80"
        rows="20"
      ></textarea>
      <textarea
        v-model="commandOutput"
        class="jsonText"
        cols="80"
        readonly="true"
        rows="20"
      ></textarea>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions, mapMutations } from "vuex";
import appSettings from "./appSettings";
import { saveAs } from "file-saver";

export default {
  name: "AppAdminCommands",
  data: () => ({
    disiorGroup: null,
    urlToExecute: null,
    commandOutput: null,
    httpVerb: "GET",
    responseType: "json",
    metadata: "{}",
  }),
  computed: {
    ...mapState(["selectedAsset", "selectedGroup", "idToken"]),
  },
  created() {
    this.setAssetInfoTemplate();
  },
  methods: {
    ...mapActions(["getAzure", "postAzure", "deleteAzure"]),
    ...mapMutations(["setErrorMessage"]),
    setAssetInfoTemplate() {
      this.setUrlTemplate(
        `${appSettings.remoteurl}/api/groups/@GroupId/assets/@AssetId?_include=formats`
      );
    },
    setFeatureToggleTemplate() {
      this.setUrlTemplate(`${appSettings.remoteurl}/api/allowedToggles/me`);
    },
    setDisiorBatchTemplate() {
      this.setUrlTemplate(
        `https://segmentationfunctionstest.azurewebsites.net/api/segmentation/batch/disior/group/@GroupId/metadata/tags/any`
      );
    },
    async showGenerators() {
      this.setUrlTemplate(
        `${appSettings.remoteurl}/api/GeneratorRegistrations`
      );
      await this.executeUrl();
    },
    setGenerateUnityTexture() {
      this.setUrlTemplate(
        `${appSettings.remoteurl}/api/groups/@GroupId/assets/@AssetId/requestFormat?formatType=VOLUME&maxSize=original&orientation=original&bufferType=unityTexture`
      );
    },
    setSimplifyStl() {
      this.setUrlTemplate(
        `${appSettings.remoteurl}/api/groups/@GroupId/assets/@AssetId/requestFormat?formatType=STL&maxTriangles=4200`
      );
    },
    showJwt() {
      this.commandOutput = this.idToken;
    },
    setUrlTemplate(url) {
      if (this.selectedGroup) {
        url = url.replace("@GroupId", this.selectedGroup.id);
      }
      if (this.selectedAsset) {
        url = url.replace("@AssetId", this.selectedAsset.id);
      }
      this.urlToExecute = url;
    },
    async executeUrl() {
      let response = await this.executeHttpRequest();
      if (!response) {
        return;
      }
      switch (this.responseType) {
        case "json":
          this.commandOutput = JSON.stringify(response, null, 3);
          break;
        case "text":
          this.commandOutput = response;
          break;
        case "response":
          saveAs(new Blob([await response.blob()]), "download");
      }
    },
    async executeHttpRequest() {
      switch (this.httpVerb) {
        case "GET":
          return await this.getAzure({
            url: this.urlToExecute,
            responseType: this.responseType,
          });
        case "POST":
          return await this.postAzure({
            url: this.urlToExecute,
            metadata: this.getJsonMetadata(),
            responseType: this.responseType,
          });
        case "DELETE":
          return await this.deleteAzure({ url: this.urlToExecute });
      }
    },
    getJsonMetadata() {
      try {
        return JSON.parse(this.metadata);
      } catch (e) {
        this.setErrorMessage(e);
        throw e;
      }
    },
  },
};
</script>

<style scoped>
#commands {
  display: flex;
  flex-direction: column;
}
.command {
  display: flex;
  flex-direction: row;
}
#editPage {
  display: flex;
  flex-direction: column;
}
.jsonText {
  background-color: #3d8094;
  color: white;
}
.md-field .md-input {
  background-color: #3d8094;
  margin-top: 4px;
  border-radius: 8px;
}
.md-field .label {
  margin: 10px;
}
.radioContainer {
  width: 340px;
}
.md-radio {
  margin: 2px;
  margin-top: 15px;
}
</style>
