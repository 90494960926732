<template id="app-asset-tile">
  <div class="wholeTile">
    <div id="tileBack" @contextmenu="openMenu($event)">
      {{ toDisplayName(asset.metadata._type) }}
      <div id="assetTile" @click="onClickCard">
        <div
          id="thumb"
          :style="{ backgroundImage: `url(${thumbnailUrl})` }"
        ></div>
        <div v-if="viewMode === 'clinical' || isPreview">
          <div v-if="isPerson">
            <div class="title">
              {{
                asset.metadata.PatientId ||
                asset.metadata.displayName ||
                "Title"
              }}
            </div>
            <div class="title">{{ asset.metadata.PatientName || "Name" }}</div>
            <small>{{ getBirthDate() }}</small>
          </div>
          <div v-else-if="isDicomStudy">
            <div class="title">
              {{
                asset.metadata.StudyDescription ||
                asset.metadata.displayName ||
                "Unknown"
              }}
            </div>
            <small>{{ getStudyDate() }}</small>
          </div>
          <div v-else-if="isDicomSeries">
            <div class="title">
              {{
                asset.metadata.SeriesDescription ||
                asset.metadata.displayName ||
                "Unknown"
              }}
            </div>
            <small>{{ getStudyDate() }}</small>
          </div>
        </div>
        <div
          v-if="
            (!isPreview && viewMode === 'research') ||
            (!isPerson && !isDicomStudy && !isDicomSeries)
          "
        >
          <div class="title">{{ asset.metadata.displayName || "Title" }}</div>
          <div id="author">
            {{ asset.metadata._author || "Unknown" }}
          </div>
          <small>{{ localTime(asset.metadata._modified) }}</small>
        </div>
        <span v-if="assetProgress > 0" id="hourglass">
          <i
            v-if="assetProgress === 100"
            class="fa fa-check-circle-o fa-lg"
          ></i>
          <i
            v-else-if="assetProgress > 30"
            class="fa fa-hourglass-half fa-lg"
          ></i>
          <i v-else class="fa fa-hourglass-start fa-lg"></i>
        </span>
      </div>
      <div class="tileFooter">
        <app-modalities
          :modalities="asset.metadata.Modality || ''"
        ></app-modalities>

        <div>
          <div>
            {{ asset.metadata.SliceThickness }}
          </div>
          <div>
            {{ numberOf }}
          </div>
        </div>
      </div>
    </div>
    <div class="informationIcon" @click="selectAsset">&nbsp;</div>
    <context-menu ref="menu">
      <div>
        <div v-if="isPerson || isDicomStudy" @click="startCopy()">
          Copy/Move DICOM Studies
        </div>
        <div v-else>No context actions available</div>
      </div>
    </context-menu>
  </div>
</template>

<script>
import moment from "moment";
import { mapState, mapActions, mapMutations } from "vuex";
import appSettings from "./appSettings";
import appModalities from "./appModalities";
import contextMenu from "./contextMenu";

export default {
  name: "AppAssetTile",
  components: {
    "app-modalities": appModalities,
    contextMenu,
  },
  props: {
    asset: {
      type: Object,
      default: Object.default,
    },
  },
  data: () => ({
    assetProgress: 0,
  }),
  computed: {
    isPreview() {
      return !this.asset.id;
    },
    isFolder() {
      return this.asset.metadata._folder;
    },
    isPerson() {
      return this.asset.metadata._type === "Person";
    },
    isDicomStudy() {
      return this.asset.metadata._type === "DICOMStudy";
    },
    isDicomSeries() {
      return this.asset.metadata._type === "Volume";
    },
    numberOf() {
      let numberOf =
        this.asset.metadata.NumberOfVolumes ||
        this.asset.metadata.NumberOfInstances;
      return numberOf ? `#${numberOf}` : "";
    },
    thumbnailUrl() {
      let thumbUrl = this.asset ? this.asset.metadata.thumb : null;

      if (!thumbUrl || thumbUrl.startsWith("http")) return thumbUrl;
      return `${appSettings.remoteurl}/api${thumbUrl}?access_token=${this.idToken}`;
    },
    ...mapState([
      "idToken",
      "viewMode",
      "workflowProgress",
      "workflowProgressModified",
      "selectedGroup",
    ]),
  },
  watch: {
    workflowProgressModified() {
      this.assetProgress = this.workflowProgress[this.asset.id];
    },
  },
  async created() {
    this.assetProgress = this.workflowProgress[this.asset.id];
  },
  methods: {
    ...mapMutations(["pushBreadcrumb", "setSelectedAsset", "setAssetToCopy"]),
    ...mapActions(["loadAsset"]),
    openMenu(e) {
      this.$refs.menu.open(e);
      e.preventDefault();
    },
    startCopy() {
      if (
        this.asset.metadata._type === "DICOMStudy" ||
        this.asset.metadata._type === "Person"
      ) {
        this.setAssetToCopy(this.asset);
      }
    },
    onClickCard() {
      if (this.isFolder) {
        this.selectFolder();
      } else {
        this.selectAsset();
      }
    },
    toDisplayName(type) {
      let typeDisplayNames = {
        DICOMStudy: "DICOM Study",
        Set: "Hologram",
      };
      let displayName = typeDisplayNames[type];
      if (displayName) {
        return displayName;
      }
      return type || "Unknown";
    },
    getBirthDate() {
      return this.getDate(this.asset.metadata.BirthDate, "Unknown Birthdate");
    },
    getStudyDate() {
      return this.getDate(
        this.asset.metadata.StudyDateTime,
        "Unknown Studydate"
      );
    },
    getDate(date, valueIfEmpty) {
      if (date) {
        return date.replace(/T.*/, "");
      }
      return valueIfEmpty;
    },
    async selectAsset() {
      if (this.isPreview) return;
      this.setSelectedAsset(this.asset);
    },
    async selectFolder() {
      if (this.isPreview) return;

      let detailedAsset = await this.loadAsset({
        groupId: this.selectedGroup.id,
        assetId: this.asset.id,
        includeDetails: true,
      });
      this.pushBreadcrumb(detailedAsset);
    },
    localTime(date) {
      return date && moment(moment.utc(date).toDate()).format("lll");
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  max-width: 180px;
  font-size: 16px;
  word-wrap: break-word;
}
#hourglass {
  border-radius: 6px;
  right: 24px;
  bottom: 32px;
  padding: 4px;
  background: #337789;
  position: absolute;
}
.fa {
  margin-top: 4px;
}
#tileBack {
  background-color: #2d6b7d;
  box-shadow: 3px 3px 20px black;
  width: 196px;
  height: 263px;
  margin: 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: white;
  opacity: 0.8;
  padding-top: 4px;
}
#assetTile {
  border-radius: 10px;
  height: 208px;
  width: 100%;
  display: flex;
  margin-top: 2px;
  background: linear-gradient(to left bottom, #5daec7, #3989a1);
  flex-direction: column;
  align-items: center;
}
#thumb {
  cursor: pointer;
  background-color: black;
  border-radius: 10px;
  width: 105px;
  height: 98px;
  margin-top: 10px;
  margin-bottom: 10px;
  background-position: center;
  background-origin: content-box;
  transition: background-size 0.2s ease-in-out;
  background-size: 100% 100%;
}
#thumb:hover {
  background-size: 112% 112%;
}
#date {
  margin-bottom: 10px;
}
#author {
  margin-bottom: 10px;
  font-size: 12px;
}
.tileFooter {
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 2px;
  padding: 2px;
  padding-right: 14px;
  height: 24px;
}
.wholeTile {
  position: relative;
}
.informationIcon {
  position: absolute;
  bottom: -10px;
  right: 10px;
  height: 30px;
  width: 30px;
  display: inline-block;
  align-self: end;
  background-image: url("/public/assets/image/info.png");
  background-size: 20px 20px;
  border-radius: 50%;
  background-repeat: no-repeat;
  box-shadow: 0px 0px 3px black;
  cursor: pointer;
  background-color: #4c7f90;
  background-position: 5px;
}
</style>
